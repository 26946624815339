import User from '../../Entity/User/User';
import BillingAddress from '../../Entity/User/BillingAddress';
import StripePaymentElementComponent from './StripePaymentElement';
import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe, PaymentIntent, PaymentIntentResult, PaymentMethodCreateParams, Stripe, StripeElements, StripeElementsOptions, StripeError, StripePaymentElement, StripePaymentElementChangeEvent, StripePaymentElementOptions} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PRE_SHARED_PUBLIC_API_KEY!);

interface StripePaymentProps {
    readonly user: User;
    readonly billingAddress: BillingAddress;
    readonly clientSecret: string;
    readonly onPaymentMethodChange?: (paymentMethod: string) => void;
    readonly onPaymentFormValidationError?: (errorMessage: string) => void;
    readonly onPaymentError?: (errorMessage: string) => void;
    readonly onPaymentSuccess?: () => void;
    readonly onPaymentCancelled?: () => void;
    readonly onPaymentRequiresPaymentMethod?: () => void;
}

interface ImperativeHandle {
    readonly submit: () => void;
}

const genericErrorMessage: string = 'Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.';

const StripePayment = forwardRef((props: StripePaymentProps, ref: React.ForwardedRef<any>): React.JSX.Element => {
    const [stripeElementsOptions, setStripeElementsOptions] = useState<StripeElementsOptions>();

    const [stripePaymentElementOptions, setStripePaymentElementOptions] = useState<StripePaymentElementOptions>();

    const [elements, setElements] = useState<StripeElements>();

    const [stripe, setStripe] = useState<Stripe>();

    useImperativeHandle(ref, (): ImperativeHandle => ({
        submit(): void {
            handleSubmit();
        }
    }));

    useEffect((): void => {
        setStripeElementsOptions({
            clientSecret: props.clientSecret,
            appearance: {
                theme: 'stripe'
            }
        });

        setStripePaymentElementOptions({
            layout: 'tabs',
            fields: {
                billingDetails: {
                    email: 'never',
                    address: 'never'
                }
            }
        });
    }, []);

    useEffect((): void => {
        const stripePaymentElementOptions: StripePaymentElementOptions = {
            layout: 'tabs',
            fields: {
                billingDetails: {
                    email: 'never',
                    address: 'never'
                }
            }
        };

        setStripePaymentElementOptions(stripePaymentElementOptions);
    }, []);

    useEffect((): void => {
        if (elements === undefined) {
            return;
        }

        const stripePaymentElement: StripePaymentElement | null = elements.getElement('payment');

        if (stripePaymentElement === null) {
            return;
        }

        stripePaymentElement.on('change', paymentMethodChangeHandler);
    }, [elements]);

    const paymentMethodChangeHandler = (event: StripePaymentElementChangeEvent): void => {
        if (props.onPaymentMethodChange === undefined) {
            return;
        }

        props.onPaymentMethodChange(event.value.type);
    };

    const buildBillingAddress = (): PaymentMethodCreateParams.BillingDetails.Address => {
        if (props.user.naturalPerson === true) {
            return {
                city: props.billingAddress.placeName,
                country: props.user.country.isoAlphaTwoCode,
                line1: props.billingAddress.streetName + ' ' + props.billingAddress.houseNumber,
                line2: '',
                postal_code: props.billingAddress.postalCode,
                state: ''
            }
        } else {
            return {
                city: props.billingAddress.placeName,
                country: props.user.country.isoAlphaTwoCode,
                line1: props.billingAddress.companyName!,
                line2: props.billingAddress.streetName + ' ' + props.billingAddress.houseNumber,
                postal_code: props.billingAddress.postalCode,
                state: ''
            }
        }
    };

    const handleSubmit = async (): Promise<void> => {
        if (stripe === null || stripe === undefined || elements === null || elements === undefined) {
            return;
        }

        const paymentIntentResult: PaymentIntentResult = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
            confirmParams: {
                payment_method_data: {
                    billing_details: {
                        email: props.billingAddress.email,
                        address: buildBillingAddress()
                    }
                }
            }
        });

        if (paymentIntentResult.error !== undefined && paymentIntentResult.paymentIntent === undefined) {
            const stripeError: StripeError = paymentIntentResult.error;

            if (stripeError.type === 'validation_error') {
                if (props.onPaymentFormValidationError !== undefined) {
                    props.onPaymentFormValidationError('Du hast nicht alle Felder korrekt ausgefüllt. Bitte kontrolliere die rot markierten Felder.');
                }
            }

            if (stripeError.type === 'card_error') {
                if (props.onPaymentError !== undefined) {
                    props.onPaymentError('Deine Kreditkarte wurde abgelehnt. Bitte überprüfe deine Eingaben.');
                }
            }

            if (stripeError.type === 'invalid_request_error') {
                if (props.onPaymentError !== undefined) {
                    props.onPaymentError(genericErrorMessage);
                }
            }

            if (stripeError.type === 'api_connection_error') {
                if (props.onPaymentError !== undefined) {
                    props.onPaymentError(genericErrorMessage);
                }
            }

            if (stripeError.type === 'api_error') {
                if (props.onPaymentError !== undefined) {
                    props.onPaymentError(genericErrorMessage);
                }
            }

            if (stripeError.type === 'authentication_error') {
                if (props.onPaymentError !== undefined) {
                    props.onPaymentError(genericErrorMessage);
                }
            }

            if (stripeError.type === 'idempotency_error') {
                if (props.onPaymentError !== undefined) {
                    props.onPaymentError(genericErrorMessage);
                }
            }

            if (stripeError.type === 'rate_limit_error') {
                if (props.onPaymentError !== undefined) {
                    props.onPaymentError(genericErrorMessage);
                }
            }
        }

        if (paymentIntentResult.error === undefined && paymentIntentResult.paymentIntent !== undefined) {
            const paymentIntent: PaymentIntent = paymentIntentResult.paymentIntent;

            switch (paymentIntent.status) {
                case 'succeeded':
                case 'processing':
                    if (props.onPaymentSuccess !== undefined) {
                        props.onPaymentSuccess();
                    }

                    break;
                case 'canceled':
                    if (props.onPaymentCancelled !== undefined) {
                        props.onPaymentCancelled();
                    }

                    break;
                case 'requires_payment_method':
                    if (props.onPaymentRequiresPaymentMethod !== undefined) {
                        props.onPaymentRequiresPaymentMethod();
                    }

                    break;
                case 'requires_action':
                case 'requires_capture':
                case 'requires_confirmation':
                    break;
                default:
                    throw new Error('Unhandled status');
            }
        }
    };

    if (stripeElementsOptions === undefined || stripePaymentElementOptions === undefined) {
        return <></>;
    }

    return (
        <Elements options={stripeElementsOptions} stripe={stripePromise}>
            <StripePaymentElementComponent
                stripePaymentElementOptions={stripePaymentElementOptions}
                setElements={setElements}
                setStripe={setStripe}
            />
        </Elements>
    );
});

export default StripePayment;
export type {ImperativeHandle};
