import LocalStorageService from '../Storage/LocalStorageService';
import UserService from '../User/UserService';
import JsonWebTokenParser from '../JsonWebToken/JsonWebTokenParser';
import Login from '../Entity/Authentication/Login';
import Authentication from '../Entity/Authentication/Authentication';
import AuthenticatedUser from '../Entity/Authentication/AuthenticatedUser';
import User from '../Entity/User/User';
import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import LoginRequest from '../../../api/Llasm/Authentication/LoginRequest';
import LoginResponse from '../../../api/Llasm/Authentication/LoginResponse';
import JsonWebTokenPayload from '../../../api/Llasm/User/JsonWebTokenPayload';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

class AuthenticationService {

    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly localStorageService: LocalStorageService;

    private readonly userService: UserService;

    constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
        this.localStorageService = new LocalStorageService();
        this.userService = new UserService(process.env.REACT_APP_LLASM_API_URL!);
    }

    public async doLogin(login: Login): Promise<Authentication> {
        const loginRequest: LoginRequest = {
            identifier: login.identifier,
            password: login.password
        };

        const config: AxiosRequestConfig<LoginRequest> = {headers: {'Content-Type': 'application/json'}};

        try {
            const response: AxiosResponse<LoginResponse> = await this.llasmApiV1Provider.post('/login', loginRequest, config);

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    public async authenticate(login: Login): Promise<void> {
        const authentication: Authentication = await this.doLogin(login);

        this.localStorageService.saveToStorage<Authentication>('authentication', {token: authentication!.token}, 6);
    }

    public logout(): void {
        this.localStorageService.removeFromStorage('authentication');
    }

    // TODO - WiP
    public fetchAuthenticatedUser(): AuthenticatedUser | null {
        const authentication: Authentication | null = this.localStorageService.getFromStorage<Authentication>('authentication');

        if (authentication === null) {
            return null;
        }

        const jsonWebTokenParser: JsonWebTokenParser<JsonWebTokenPayload> = new JsonWebTokenParser<JsonWebTokenPayload>(authentication.token);

        const jsonWebTokenPayload: JsonWebTokenPayload = jsonWebTokenParser.parse();

        const user: User = new User();

        user.id = jsonWebTokenPayload.id;
        user.identifier = jsonWebTokenPayload.identifier;
        user.roles = jsonWebTokenPayload.roles;
        user.firstName = jsonWebTokenPayload.firstName;

        // TODO - WiP
        user.email = user.identifier;

        // TODO - WiP
        user.introComplete = true;

        // TODO - WiP
        user.numberOfMatchPoints = 0;

        return {token: authentication.token, user: user};
    }

    public async fetchUser(authenticatedUser: AuthenticatedUser): Promise<User | null> {
        const authentication: Authentication | null = this.localStorageService.getFromStorage<Authentication>('authentication');

        if (authentication === null) {
            return null;
        }

        return await this.userService.fetchUserFromApiById(authenticatedUser.user.id!);
    }

    public refreshTimeToLife(hoursTimeToLife: number): void {
        this.localStorageService.refreshTimeToLife<Authentication>('authentication', hoursTimeToLife);
    }
}

export default AuthenticationService;
