import LocationService from '../Location/LocationService';
import Property from '../Entity/Property/Property';
import LocationDetail from '../Entity/Property/LocationDetail';
import Detail from '../Entity/Property/Detail';
import OfferDetail from '../Entity/Property/OfferDetail/OfferDetail';
import AdditionalDetail from '../Entity/Property/AdditionalDetail';
import EnergyCertificate from '../Entity/Property/EnergyCertificate/EnergyCertificate';
import FormData from '../Entity/Form/FormData';
import SelectOption from '../Entity/Form/SelectOption';
import AccessibilityType, {getAccessibilityTypeLabel} from '../Entity/AccessibilityType';
import MarketingStatus, {getMarketingStatusLabel} from '../Entity/MarketingStatus';
import PropertyAcquisitionType from '../Entity/PropertyAcquisitionType';
import Place from '../Entity/Location/Place';
import LocationDetailForm from './LocationDetailForm';
import AdditionalDetailForm from './AdditionalDetailForm';
import EnergyCertificateForm from './EnergyCertificateForm';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import NumericValidationDefinition from '../FormValidationHandler/NumericValidationDefinition';
import MinMaxLengthValidationDefinition from '../FormValidationHandler/MinMaxLengthValidationDefinition';
import PropertyAcquisitionTypeSelectField from '../PropertyAcquisitionTypeSelectField';
import SelectOrCreateContact from '../Contact/SelectOrCreateContact';
import SwitchField from '../Component/Form/Field/SwitchField';
import TextAreaField from '../Component/Form/Field/TextAreaField';
import InputField from '../Component/Form/Field/InputField';
import SelectField from '../Component/Form/Field/SelectField';
import MultiSelectField from '../Component/Form/Field/MultiSelectField';
import Card from '../Component/Card/Card';
import CollapseCard from '../Component/CollapseCard/CollapseCard';
import React, {useEffect, useState} from 'react';

interface PropertyFormProps {
    readonly formData: FormData<Property>;
    readonly setFormData: (formData: FormData<Property>) => void;
    readonly countryPlaces: Place[];
    readonly defaultCountryPlace?: Place;
    readonly formValidationHandler?: FormValidationHandler<Property>;
    readonly setLocationDetailFormData?: (formData: FormData<LocationDetail>) => void;
    readonly setDetailFormData?: (formData: FormData<Detail>) => void;
    readonly setOfferDetailFormData?: (formData: FormData<OfferDetail>) => void;
    readonly setAdditionalDetailFormData?: (formData: FormData<AdditionalDetail>) => void;
    readonly setEnergyCertificateFormData?: (formData: FormData<EnergyCertificate | null>) => void;
}

const marketingStatusSelectOptions: SelectOption<MarketingStatus>[] = [
    {label: getMarketingStatusLabel(MarketingStatus.Available), value: MarketingStatus.Available},
    {label: getMarketingStatusLabel(MarketingStatus.Reserved), value: MarketingStatus.Reserved},
    {label: getMarketingStatusLabel(MarketingStatus.Allocated), value: MarketingStatus.Allocated},
];

{/*ToDo: Enum...? */}
const floorsSelectOptions: SelectOption<string>[] = [
    {label: '2. Untergeschoss', value: '2. UG'},
    {label: '1. Untergeschoss', value: '1. UG'},
    {label: 'Erdgeschoss', value: 'EG'},
    {label: '1. Obergeschoss', value: '1. OG'},
    {label: '2. Obergeschoss', value: '2. OG'},
    {label: '3. Obergeschoss', value: '3. OG'},
    {label: 'weitere Etagen', value: 'weitere'},
];

const accessibilityTypeSelectOptions: SelectOption<AccessibilityType>[] = [
    {label: getAccessibilityTypeLabel(AccessibilityType.Available), value: AccessibilityType.Available},
    {label: getAccessibilityTypeLabel(AccessibilityType.CanBeGuaranteed), value: AccessibilityType.CanBeGuaranteed},
    {label: getAccessibilityTypeLabel(AccessibilityType.CurrentlyNotAvailable), value: AccessibilityType.CurrentlyNotAvailable},
    {label: getAccessibilityTypeLabel(AccessibilityType.NotPossible), value: AccessibilityType.NotPossible},
];

const locationService: LocationService = new LocationService(process.env.REACT_APP_LLASM_API_URL!);

const locationDetailFieldValidationDefinitions: FieldValidationDefinition<LocationDetail>[] = [
    new RequiredValidationDefinition<LocationDetail>('city', 'Ein Ort muss gewählt sein.'),
    new RequiredValidationDefinition<LocationDetail>('postalCode', 'PLZ muss ausgefüllt sein.'),
    new NumericValidationDefinition<LocationDetail>('postalCode', 'PLZ darf nur aus Zahlen bestehen.'),
    new RequiredValidationDefinition<LocationDetail>('locationType', 'Standort-Typ muss ausgefüllt sein.'),
    new RequiredValidationDefinition<LocationDetail>('locationCategory', 'Lagekategorie muss ausgefüllt sein.'),
    new RequiredValidationDefinition<LocationDetail>('locationFactors', 'Standort Faktoren müssen ausgewählt sein.'),
    new MinMaxLengthValidationDefinition<LocationDetail>('locationFactors', 2, 5, 'Standort Faktoren müssen ausgewählt sein.'),
];

const additionalDetailFieldValidationDefinitions: FieldValidationDefinition<AdditionalDetail>[] = [
    new RequiredValidationDefinition<AdditionalDetail>('generalFurnishing', 'Ausstattung (allgemein) muss ausgefüllt sein.'),
];

const energyCertificateFieldValidationDefinitions: FieldValidationDefinition<EnergyCertificate>[] = [];

const PropertyForm = (props: PropertyFormProps): React.JSX.Element => {
    const property: Property = props.formData.data;

    const [locationDetailFormData, setLocationDetailFormData] = useState<FormData<LocationDetail>>({data: props.formData.data.locationDetail});

    const [detailFormData, setDetailFormData] = useState<FormData<Detail>>({data: props.formData.data.detail});

    const [offerDetailFormData, setOfferDetailFormData] = useState<FormData<OfferDetail>>({data: props.formData.data.offerDetail});

    const [additionalDetailFormData, setAdditionalDetailFormData] = useState<FormData<AdditionalDetail>>({data: props.formData.data.additionalDetail});

    const [energyCertificateFormData, setEnergyCertificateFormData] = useState<FormData<EnergyCertificate> | null>(props.formData.data.energyCertificate !== null ? {data: props.formData.data.energyCertificate} : null);

    useEffect((): void => {
        property.locationDetail = locationDetailFormData.data;

        if (props.setLocationDetailFormData !== undefined ) {
            props.setLocationDetailFormData(locationDetailFormData);
        }

        updateFormData();
    }, [locationDetailFormData]);

    useEffect((): void => {
        property.detail = detailFormData.data;

        updateFormData();
    }, [detailFormData]);

    useEffect((): void => {
        property.offerDetail = offerDetailFormData.data;

        updateFormData();
    }, [offerDetailFormData]);

    useEffect((): void => {
        property.additionalDetail = additionalDetailFormData.data;

        if (props.setAdditionalDetailFormData !== undefined ) {
            props.setAdditionalDetailFormData(additionalDetailFormData);
        }

        updateFormData();
    }, [additionalDetailFormData]);

    useEffect((): void => {
        if (energyCertificateFormData === null) {
            return;
        }

        property.energyCertificate = energyCertificateFormData.data;

        if (props.setEnergyCertificateFormData !== undefined ) {
            props.setEnergyCertificateFormData(energyCertificateFormData);
        }

        updateFormData();
    }, [energyCertificateFormData]);

    const fetchMarketingStatusSelectedOption = (): SelectOption<MarketingStatus> | undefined => {
        if (property.marketingStatus === undefined) {
            return undefined;
        }

        return marketingStatusSelectOptions.find((selectOption: SelectOption<MarketingStatus>): boolean => {
            return property.marketingStatus === selectOption.value;
        });
    };

    const fetchAccessibilityTypeSelectedOption = (): SelectOption<AccessibilityType> | undefined => {
        if (property.detail.accessibilityType === undefined) {
            return undefined;
        }

        return accessibilityTypeSelectOptions.find((selectOption: SelectOption<AccessibilityType>): boolean => {
            return property.detail.accessibilityType === selectOption.value;
        });
    };

    const fetchFloorsSelectedOptions = (): SelectOption<string>[] => {
        if (property.detail.floors.length === 0) {
            return [];
        }

        return floorsSelectOptions.filter((selectOption: SelectOption<string>): boolean => {
            return property.detail.floors.includes(selectOption.value);
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
        switch (event.target.name) {
            case 'purchasePrice':
                if (event.target.value === '') {
                    // TODO - deprecated - z.Z. noch relevant für die validation
                    property.purchasePrice = null;

                    property.offerDetail.purchaseDetail.purchasePrice = null;
                } else {
                    // TODO - deprecated - z.Z. noch relevant für die validation
                    property.purchasePrice = Number(event.target.value);

                    property.offerDetail.purchaseDetail.purchasePrice = Number(event.target.value);
                }

                break;
            case 'netRentalPrice':
                if (event.target.value === '') {
                    // TODO - deprecated - z.Z. noch relevant für die validation
                    property.netRentalPrice = null;

                    property.offerDetail.rentalDetail.netRentalPrice = null;
                } else {
                    // TODO - deprecated - z.Z. noch relevant für die validation
                    property.netRentalPrice = Number(event.target.value);

                    property.offerDetail.rentalDetail.netRentalPrice = Number(event.target.value);
                }

                break;
            case 'areaSize':
                // TODO - deprecated - z.Z. noch relevant für die validation
                property.areaSize = Number(event.target.value);

                property.detail.areaSize = Number(event.target.value);

                break;
            case 'secondaryAreaSize':
                if (event.target.value === '') {
                    // TODO - deprecated - z.Z. noch relevant für die validation
                    property.secondaryAreaSize = null;

                    property.detail.secondaryAreaSize = null;
                } else {
                    // TODO - deprecated - z.Z. noch relevant für die validation
                    property.secondaryAreaSize = Number(event.target.value);

                    property.detail.secondaryAreaSize = Number(event.target.value);
                }

                break;
            case 'outdoorSalesAreaSize':
                if (event.target.value === '') {
                    // TODO - deprecated - z.Z. noch relevant für die validation
                    property.outdoorSalesAreaSize = null;

                    property.detail.outdoorSalesAreaSize = null;
                } else {
                    // TODO - deprecated - z.Z. noch relevant für die validation
                    property.outdoorSalesAreaSize = Number(event.target.value);

                    property.detail.outdoorSalesAreaSize = Number(event.target.value);
                }

                break;
            case 'storeWidth':
                if (event.target.value === '') {
                    // TODO - deprecated - z.Z. noch relevant für die validation
                    property.storeWidth = null;

                    property.detail.storeWidth = null;
                } else {
                    // TODO - deprecated - z.Z. noch relevant für die validation
                    property.storeWidth = Number(event.target.value);

                    property.detail.storeWidth = Number(event.target.value);
                }

                break;
            case 'shopWindowFrontLength':
                if (event.target.value === '') {
                    // TODO - deprecated - z.Z. noch relevant für die validation
                    property.shopWindowFrontLength = null;

                    property.detail.shopWindowFrontLength = null;
                } else {
                    // TODO - deprecated - z.Z. noch relevant für die validation
                    property.shopWindowFrontLength = Number(event.target.value);

                    property.detail.shopWindowFrontLength = Number(event.target.value);
                }

                break;
            default:
                if (event.target.name === 'usageDescription') {
                    property.detail.objectDescription = event.target.value;
                }

                if (event.target.name === 'deposit') {
                    property.offerDetail.rentalDetail.deposit = event.target.value;
                }

                if (event.target.name === 'commissionAmount') {
                    property.offerDetail.commissionAmount = event.target.value;
                }

                if (event.target.name === 'commissionNote') {
                    property.offerDetail.commissionNote = event.target.value;
                }

                // TODO - deprecated - z.Z. noch relevant für die validation
                (property as any)[event.target.name] = event.target.value;

                break;
        }

        updateFormData();
        validateField(event.target.name);
    };

    const handleMarketingStatusChange = (selectedValue: SelectOption<MarketingStatus> | null): void => {
        if (selectedValue === null) {
            return;
        }

        property.marketingStatus = selectedValue.value;

        updateFormData();
        validateField('marketingStatus');
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === 'groundLevelSalesArea') {
            property.detail.groundLevelSalesArea =  event.target.checked;
        }

        if (event.target.name === 'parkingLotAvailable') {
            property.detail.parkingLotAvailable =  event.target.checked;
        }

        // TODO - deprecated - z.Z. noch relevant für die validation
        (property as any)[event.target.name] = event.target.checked;

        updateFormData();
    };

    const handlePropertyAcquisitionTypesChange = (selectedValues: readonly SelectOption<PropertyAcquisitionType>[]): void => {
        // TODO - deprecated - z.Z. noch relevant für die validation
        property.propertyAcquisitionTypes = selectedValues.map((selectOption: SelectOption<PropertyAcquisitionType>): PropertyAcquisitionType => {
            return selectOption.value;
        });

        property.offerDetail.propertyAcquisitionTypes = selectedValues.map((selectOption: SelectOption<PropertyAcquisitionType>): PropertyAcquisitionType => {
            return selectOption.value;
        });

        updateFormData();
    };

    const handleAccessibilityTypeChange = (selectedValue: SelectOption<AccessibilityType> | null): void => {
        if (selectedValue === null) {
            return;
        }

        // TODO - deprecated - z.Z. noch relevant für die validation
        property.accessibilityType = selectedValue.value;

        property.detail.accessibilityType = selectedValue.value;

        updateFormData();
        validateField('accessibilityType');
    };

    const handleFloorsChange = (selectedValues: readonly SelectOption<string>[]): void => {
        // TODO - deprecated - z.Z. noch relevant für die validation
        property.floors = selectedValues.map((selectOption: SelectOption<string>): string => {
            return selectOption.value;
        });

        property.detail.floors = selectedValues.map((selectOption: SelectOption<string>): string => {
            return selectOption.value;
        });

        updateFormData();
        validateField('floors');
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: property});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <>
            <Card cardType="shadow" className="mb-3 bg-light">
                <div className="p-3 p-lg-4 p-xl-5">
                    <h2 className="fs-5 fw-bold mb-3">Status</h2>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <SwitchField
                                label={property.active === false ? 'Die Fläche ist nach dem Speichern inaktiv' : 'Die Fläche ist nach dem Speichern aktiv'}
                                description="Hier kannst Du Dein Flächenangebot jederzeit aktiv bzw. inaktiv stellen. Nur für aktive Flächenangebote kann ein Matching gestartet werden."
                                selected={property.active}
                                name="active"
                                onChange={handleSwitchChange}
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <SelectField
                                name="marketingStatus"
                                label="Vermarktungsstatus"
                                required={true}
                                options={marketingStatusSelectOptions}
                                value={fetchMarketingStatusSelectedOption()}
                                placeholder="Bitte wählen"
                                onChange={handleMarketingStatusChange}
                                description="Hier gibst Du den aktuellen Vermarktungsstatus für Dein Objekt an. Suchende Unternehmen, denen Du Dein Objekt angeboten hast, sehen diesen Vermarktungsstatus als Feedback in ihrem Bereich."
                                formErrors={FormValidationHandler.getFieldErrors(props.formData, 'marketingStatus')}
                            />
                        </div>
                    </div>
                </div>
            </Card>
            <CollapseCard cardType="shadow" title="Bezeichnung    und Lage" className="mb-3 pb-3">
                <div className="row mb-3">
                    <div className="col-md-6">
                        <InputField
                            name="title"
                            label="Bezeichnung der Fläche"
                            description="Trage hier eine Bezeichnung/einen Titel für die Fläche ein, um diese von Deinen anderen Flächenangeboten zu unterscheiden. Diese Bezeichnung erscheint auch im Matching."
                            type="text"
                            required={true}
                            value={property.title}
                            onChange={handleChange}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'title')}
                        />
                    </div>
                </div>

                <LocationDetailForm
                    locationService={locationService}
                    formData={locationDetailFormData}
                    setFormData={setLocationDetailFormData}
                    validationDefinitions={locationDetailFieldValidationDefinitions}
                    countryPlaces={props.countryPlaces}
                    defaultCountryPlace={props.defaultCountryPlace}
                />
            </CollapseCard>
            <CollapseCard cardType="shadow" title="Objektinformationen" className="mb-3">
                <div className="row mb-3">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-lg-6 mb-3">
                                <PropertyAcquisitionTypeSelectField
                                    name="propertyAcquisitionTypes"
                                    label="Angebotsart"
                                    description="Bietest Du Deine Fläche zum Kauf, zur Miete oder beides an?"
                                    placeholder="Bitte wählen"
                                    required={true}
                                    isClearable={true}
                                    closeMenuOnSelect={false}
                                    value={property.offerDetail.propertyAcquisitionTypes}
                                    onChange={handlePropertyAcquisitionTypesChange}
                                />
                            </div>
                            {property.offerDetail.propertyAcquisitionTypes.includes(PropertyAcquisitionType.Buy) === true &&
                                <div className="col-lg-6 mb-3">
                                    <InputField
                                        name="purchasePrice"
                                        label="Kaufpreis"
                                        type="number"
                                        suffix="€"
                                        min={0}
                                        required={false}
                                        value={property.offerDetail.purchaseDetail.purchasePrice ?? undefined}
                                        onChange={handleChange}
                                    />
                                </div>
                            }
                            {property.offerDetail.propertyAcquisitionTypes.includes(PropertyAcquisitionType.Rent) === true &&
                                <>
                                    <div className="col-lg-6 mb-3">
                                        <InputField
                                            name="netRentalPrice"
                                            label="Miete (netto) / Monat"
                                            type="number"
                                            suffix="€"
                                            min={0}
                                            required={false}
                                            value={property.offerDetail.rentalDetail.netRentalPrice ?? undefined}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <InputField
                                            name="deposit"
                                            label="Kaution"
                                            type="text"
                                            required={false}
                                            value={property.offerDetail.rentalDetail.deposit ?? undefined}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-3">
                                <div className="mb-3">
                                    <InputField
                                        name="commissionAmount"
                                        label="Provision"
                                        type="text"
                                        required={false}
                                        value={property.offerDetail.commissionAmount ?? undefined}
                                        description="Sofern eine Provision für den Erwerber/Mieter fällig ist, kann diese hier angegeben werden. Bei provisionsfreien Angeboten kann das Feld leer bleiben."
                                        onChange={handleChange}
                                    />
                                </div>
                                <TextAreaField
                                    name="commissionNote"
                                    label="Provision (Erläuterung)"
                                    rows={6}
                                    required={false}
                                    value={property.offerDetail.commissionNote ?? undefined}
                                    id="commissionNote"
                                    description="Hier kannst Du weitere Informationen (z.B. Art der Provision, Fälligkeit der Provision, Verweis auf die AGB, rechtliche Hinweise...) zur geltenden Provisionsvereinbarung geben."
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="mb-3">
                                    <InputField
                                        name="areaSize"
                                        label="Gesamtfläche"
                                        description="Wie groß ist die Gesamtfläche – abzüglich der Nebenflächen?"
                                        type="number"
                                        suffix="m²"
                                        min={0}
                                        required={true}
                                        value={property.detail.areaSize}
                                        onChange={handleChange}
                                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'areaSize')}
                                    />
                                </div>
                                <div className="mb-3">
                                    <InputField
                                        name="secondaryAreaSize"
                                        label="Nebenfläche"
                                        description="Wie groß sind die Nebenflächen - z.B. Toiletten, Lagerräume, Kühlhäuser etc.?"
                                        type="number"
                                        suffix="m²"
                                        min={0}
                                        required={false}
                                        value={property.detail.secondaryAreaSize ?? undefined}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-3">
                                    <InputField
                                        name="outdoorSalesAreaSize"
                                        label="Außenverkaufsfläche"
                                        description="Deine Fläche bietet die Möglichkeit, Außenbereiche für Werbemaßnahmen oder zum Verkauf zu nutzen? Hier kannst Du eintragen, wie groß dieser Bereich ist."
                                        type="number"
                                        suffix="m²"
                                        min={0}
                                        required={false}
                                        value={property.detail.outdoorSalesAreaSize ?? undefined}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-3">
                                    <InputField
                                        name="storeWidth"
                                        label="Ladenbreite"
                                        type="number"
                                        min={0}
                                        value={property.detail.storeWidth ?? undefined}
                                        id="storeWidth"
                                        suffix="m"
                                        description="Wie breit ist die Frontseite Deiner Ladenfläche?"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-3">
                                    <InputField
                                        name="shopWindowFrontLength"
                                        label="Schaufensterfront"
                                        type="number"
                                        min={0}
                                        value={property.detail.shopWindowFrontLength ?? undefined}
                                        id="shopWindowFrontLength"
                                        suffix="m"
                                        description="Wie breit ist die Schaufensterfront Deiner Fläche?"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <SelectField
                                    name="accessibilityType"
                                    label="Barrierefreier Zugang"
                                    required={true}
                                    options={accessibilityTypeSelectOptions}
                                    value={fetchAccessibilityTypeSelectedOption()}
                                    placeholder="Bitte wählen"
                                    onChange={handleAccessibilityTypeChange}
                                    description="Ist Deine Fläche barrierefrei? Ein Ladenlokal ist barrierefrei, wenn es ohne Stufe oder sonstigen Erhöhungen zugänglich ist. Eine nachträgliche Barrierefreiheit kann unter Umständen durch z.B. den Einbau einer Rampe gewährleistet werden."
                                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'accessibilityType')}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <SwitchField
                                    label="Ebenerdige Verkaufsfläche vorhanden"
                                    description="Ist Deine Verkaufsfläche ebenerdig? Gibt es auf der Verkaufsfläche keinerlei Höhenunterschiede (Rampen, Treppen, Stufe,…), gilt sie als ebenerdig. Ist die Verkaufsfläche über mehrere Etagen aufgeteilt und über einen Aufzug verbunden, gilt sie dennoch als ebenerdig."
                                    selected={property.detail.groundLevelSalesArea}
                                    name="groundLevelSalesArea"
                                    onChange={handleSwitchChange}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <SwitchField
                                    label="Parkplätze am Objekt"
                                    description="Sind direkt an Deiner Fläche Parkplätze vorhanden?"
                                    selected={property.detail.parkingLotAvailable}
                                    name="parkingLotAvailable"
                                    onChange={handleSwitchChange}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <MultiSelectField
                                    label="Etage(n)"
                                    name="floors"
                                    options={floorsSelectOptions}
                                    required={true}
                                    closeMenuOnSelect={false}
                                    value={fetchFloorsSelectedOptions()}
                                    placeholder="Bitte wählen"
                                    onChange={handleFloorsChange}
                                    description="Über welche Etage(n) erstreckt sich Deine Fläche? Mehrfachauswahl möglich."
                                    formErrors={FormValidationHandler.getFieldErrors(props.formData, 'floors')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextAreaField
                            style={{gridRowEnd: 'span 5', alignSelf: 'baseline'}}
                            name="usageDescription"
                            label="Objektbeschreibung"
                            rows={9}
                            required={true}
                            value={property.detail.objectDescription ?? undefined}
                            description="Beschreibe Deine Fläche. Was macht Deine Fläche aus? Was gibt es für Besonderheiten? Wie wurde sie zuletzt genutzt?"
                            onChange={handleChange}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'usageDescription')}
                        />
                    </div>
                </div>
            </CollapseCard>

            {process.env.NODE_ENV! === 'development' &&
                <CollapseCard cardType="shadow" title="Energieausweis" className="mb-3">
                    {energyCertificateFormData !== null &&
                        <EnergyCertificateForm
                            formData={energyCertificateFormData}
                            setFormData={setEnergyCertificateFormData}
                            validationDefinitions={energyCertificateFieldValidationDefinitions}
                        />
                    }
                </CollapseCard>
            }

            <CollapseCard cardType="shadow" title="Zusätzliche Informationen" className="mb-3">
                <AdditionalDetailForm
                    formData={additionalDetailFormData}
                    setFormData={setAdditionalDetailFormData}
                    validationDefinitions={additionalDetailFieldValidationDefinitions}
                />
            </CollapseCard>
            <CollapseCard cardType="shadow" title="Ansprechpartner auswählen" className="mb-3">
                <SelectOrCreateContact
                    formData={props.formData}
                    setFormData={props.setFormData}
                    validateField={validateField}
                />
            </CollapseCard>
        </>
    );
};

export default PropertyForm;
