import DocumentService from './DocumentService';
import DocumentEntity from '../Entity/Document/Document';
import FormData from '../Entity/Form/FormData';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import FontAwesomeIcon from '../Component/Icon/FontAwesomeIcon';
import BootstrapIcon from '../Component/Icon/BootstrapIcon';
import InputField from '../Component/Form/Field/InputField';
import React, {useEffect, useState} from 'react';

interface DocumentProps {
    readonly document: DocumentEntity;
    readonly setIsLoading?: (isLoading: boolean) => void;
    readonly buildDocumentGetPath?: (document: DocumentEntity) => string;
    readonly buildDocumentPatchPath?: (document: DocumentEntity) => string;
    readonly buildDocumentDeletePath?: (document: DocumentEntity) => string;
    readonly onDeleted?: () => Promise<void>;
}

interface Data {
    title: string;
}

const documentService: DocumentService = new DocumentService(process.env.REACT_APP_LLASM_API_URL!);

const fieldValidationDefinitions: FieldValidationDefinition<Data>[] = [
    new RequiredValidationDefinition<Data>('title', 'Bezeichnung des Dokuments muss ausgefüllt sein.'),
];

const Document = (props: DocumentProps): React.JSX.Element => {
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const [formData, setFormData] = useState<FormData<Data>>();

    useEffect((): void => {
        if (formData !== undefined) {
            return;
        }

        setFormData(createFormData);
    }, []);

    const createFormData = (): FormData<Data> => {
        return {
            data: {
                title: props.document.title
            },
            formValidationHandler: new FormValidationHandler<Data>(fieldValidationDefinitions)
        };
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        if (formData === undefined) {
            return;
        }

        formData.data.title = event.target.value;

        setFormData(formData);

        validateField(event.target.name);
    };

    const validateField = (fieldName: string): void => {
        if (formData === undefined) {
            return;
        }

        if (formData.formValidationHandler === undefined) {
            return;
        }

        formData.formValidationHandler.validateField(fieldName, formData);

        setFormData({...formData, errors: formData.errors});
    };

    const updateDocument = async (): Promise<void> => {
        if (props.buildDocumentPatchPath === undefined) {
            return;
        }

        if (props.document.id === undefined) {
            return;
        }

        if (formData === undefined) {
            return;
        }

        if (formData.formValidationHandler !== undefined) {
            formData.formValidationHandler.validate(formData);

            if (formData.formValidationHandler.hasErrors(formData) === true) {
                return;
            }
        }

        props.document.title = formData.data.title;

        if (props.setIsLoading !== undefined) {
            props.setIsLoading(true);
        }

        try {
            const patchPath: string = props.buildDocumentPatchPath(props.document);

            await documentService.updateDocument(patchPath, props.document);

            setIsEditing(false);
        } catch (error) {
            throw error;
        } finally {
            if (props.setIsLoading !== undefined) {
                props.setIsLoading(false);
            }
        }
    };

    const deleteDocument = async (): Promise<void> => {
        if (props.buildDocumentDeletePath === undefined) {
            return;
        }

        if (props.document.id === undefined) {
            return;
        }

        if (props.setIsLoading !== undefined){
            props.setIsLoading(true);
        }

        const deletePath: string = props.buildDocumentDeletePath(props.document);

        await documentService.deleteDocument(deletePath);

        if (props.onDeleted !== undefined) {
            await props.onDeleted();
        }

        if (props.setIsLoading !== undefined){
            props.setIsLoading(false);
        }
    };

    const downloadDocument = async (document: DocumentEntity): Promise<void> => {
        if (props.buildDocumentGetPath === undefined) {
            return;
        }

        if (props.setIsLoading !== undefined) {
            props.setIsLoading(true);
        }

        try {
            const getPath: string = props.buildDocumentGetPath(document);

            await documentService.downloadDocument(getPath);
        } catch (error) {
            throw error;
        } finally {
            if (props.setIsLoading !== undefined) {
                props.setIsLoading(false);
            }
        }
    };

    const cancelEdit = (): void => {
        if (formData === undefined) {
            return;
        }

        formData.data.title = props.document.title;

        setFormData(formData);

        validateField('title');

        setIsEditing(false);
    };

    if (formData === undefined) {
        return <></>;
    }

    return (
        <div className="mb-3">
            {isEditing === false &&
                <div className="d-flex align-items-center fs-4">
                    {props.document.id !== undefined &&
                        <span className="cursor-pointer" onClick={() => downloadDocument(props.document)}>
                            <FontAwesomeIcon iconName="file-pdf" iconType="regular" className="me-2 fs-1"/>
                        </span>
                    }
                    <span className="me-4 text-truncate">
                        {props.document.title}
                    </span>
                    <button className="btn btn-secondary pt-1 pb-1 ps-2 pe-2 me-2" onClick={() => setIsEditing(true)}>
                        <BootstrapIcon iconName="pencil-fill"/>
                    </button>
                    <button type="button" className="btn btn-danger pt-1 pb-1 ps-2 pe-2" onClick={deleteDocument}>
                        <BootstrapIcon iconName="trash-fill" />
                    </button>
                </div>
            }
            {isEditing === true &&
                <>
                    <div className="d-flex">
                        {props.document.id !== undefined &&
                            <div className="cursor-pointer" onClick={() => downloadDocument(props.document)}>
                                <FontAwesomeIcon iconName="file-pdf" iconType="regular" className="me-2 fs-1"/>
                            </div>
                        }
                        <div className="flex-fill me-3">
                            <div className="mb-3">
                                <InputField
                                    name="title"
                                    type="text"
                                    value={formData.data.title}
                                    onChange={handleChange}
                                    formErrors={formData.errors}
                                />
                            </div>
                            <div>
                                <button className="btn btn-primary me-2" onClick={updateDocument}>
                                    Speichern
                                </button>
                                <button className="btn btn-secondary" onClick={cancelEdit}>
                                    Abbrechen
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default Document;
