import PropertyAcquisitionType from '../../PropertyAcquisitionType';
import RentalDetail from './RentalDetail';
import PurchaseDetail from './PurchaseDetail';
import OfferDetailResponse from '../../../../../api/Llasm/Property/OfferDetailResponse';

class OfferDetail {
    private _propertyAcquisitionTypes: PropertyAcquisitionType[] = [];

    private _storeSpaceAvailableFrom: Date | null = null;

    private _rentalDetail: RentalDetail;

    private _purchaseDetail: PurchaseDetail;

    private _commissionAmount: string | null = null;

    private _commissionNote: string | null = null;

    public static createFromOfferDetailResponse(offerDetailResponse: OfferDetailResponse): OfferDetail {
        const offerDetail: OfferDetail = new this();

        offerDetail._propertyAcquisitionTypes = offerDetailResponse.propertyAcquisitionTypes;

        if (offerDetailResponse.storeSpaceAvailableFrom !== null) {
            offerDetail._storeSpaceAvailableFrom = new Date(offerDetailResponse.storeSpaceAvailableFrom);
        }

        offerDetail._rentalDetail = RentalDetail.createFromRentalDetailResponse(offerDetailResponse.rentalDetail);
        offerDetail._purchaseDetail = PurchaseDetail.createFromPurchaseDetailResponse(offerDetailResponse.purchaseDetail);
        offerDetail._commissionAmount = offerDetailResponse.commissionAmount;
        offerDetail._commissionNote = offerDetailResponse.commissionNote;

        return offerDetail;
    }

    get propertyAcquisitionTypes(): PropertyAcquisitionType[] {
        return this._propertyAcquisitionTypes;
    }

    set propertyAcquisitionTypes(value: PropertyAcquisitionType[]) {
        this._propertyAcquisitionTypes = value;
    }

    get storeSpaceAvailableFrom(): Date | null {
        return this._storeSpaceAvailableFrom;
    }

    set storeSpaceAvailableFrom(value: Date | null) {
        this._storeSpaceAvailableFrom = value;
    }

    get rentalDetail(): RentalDetail {
        return this._rentalDetail;
    }

    set rentalDetail(value: RentalDetail) {
        this._rentalDetail = value;
    }

    get purchaseDetail(): PurchaseDetail {
        return this._purchaseDetail;
    }

    set purchaseDetail(value: PurchaseDetail) {
        this._purchaseDetail = value;
    }

    get commissionAmount(): string | null {
        return this._commissionAmount;
    }

    set commissionAmount(value: string | null) {
        this._commissionAmount = value;
    }

    get commissionNote(): string | null {
        return this._commissionNote;
    }

    set commissionNote(value: string | null) {
        this._commissionNote = value;
    }
}

export default OfferDetail;
