import AuthenticationState from '../../shared/Entity/Authentication/AuthenticationState';
import Login from '../../shared/Entity/Authentication/Login';
import FormData from '../../shared/Entity/Form/FormData';
import Alert from '../../shared/Entity/Alert/Alert';
import AlertType from '../../shared/Entity/Alert/AlertType';
import FieldValidationDefinition from '../../shared/FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../../shared/FormValidationHandler/RequiredValidationDefinition';
import FormValidationHandler from '../../shared/FormValidationHandler/FormValidationHandler';
import EmailValidationDefinition from '../../shared/FormValidationHandler/EmailValidationDefinition';
import LoginForm from '../../shared/Login/LoginForm';
import WelcomeInfoBox from '../Public/WelcomeInfoBox';
import Spinner from '../../../components/Spinner';
import AlertBox from '../../../components/AlertBox';
import { authenticate } from '../../../features/authentication/authenticationSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import React, { useEffect, useState } from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

const login: Login = new Login();

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Bitte fülle alle rot markierten Pflichtfelder aus.');

const invalidCredentialsErrorAlert: Alert = new Alert(AlertType.Error, 'E-Mail-Adresse und/oder Passwort sind nicht korrekt.');

const requestErrorAlert: Alert = new Alert(AlertType.Error, 'Es ist ein Problem bei Deiner Anmeldung aufgetreten. Bitte versuche es noch einmal.');

const LoginPage = (): React.JSX.Element => {
    const {authenticatedUser, isLoading}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const [formData, setFormData] = useState<FormData<Login>>({data: login});

    const fieldValidationDefinitions: FieldValidationDefinition<Login>[] = [
        new RequiredValidationDefinition<Login>('identifier', 'Es muss eine E-Mail-Adresse angegeben werden.'),
        new EmailValidationDefinition<Login>('identifier', 'Das Format der E-Mail-Adresse ist ungültig.'),
        new RequiredValidationDefinition<Login>('password', 'Es muss ein Passwort angegeben werden.'),
    ];

    const formValidationHandler: FormValidationHandler<Login> = new FormValidationHandler<Login>(fieldValidationDefinitions);

    const [alert, setAlert] = useState<Alert>();

    const navigate: NavigateFunction = useNavigate();

    const dispatch: ThunkDispatch<{ auth: any }, undefined, AnyAction> & Dispatch<AnyAction> = useAppDispatch();

    useEffect((): void => {
        if (authenticatedUser !== null) {
            navigate('/');
        }
    }, [authenticatedUser, navigate]);

    const submitForm = async (): Promise<void> => {
        formValidationHandler.validate(formData);

        if (formValidationHandler.hasErrors(formData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        const loginAction = await dispatch(authenticate(login));

        if (loginAction.type === 'auth/login/rejected') {
            if ((loginAction.payload as AxiosError).response!.status === 401) {
                setAlert(invalidCredentialsErrorAlert);
            } else {
                setAlert(requestErrorAlert);
            }
        }
    };

    if (isLoading === true) {
        return <Spinner />;
    }

    return (
        <div className="row g-3 mt-1 mb-3">
            <div className="col-12 pt-1 px-md-3">
                <h1 className="fw-bold text-secondary fs-3 mb-4 mb-lg-5">Ladenfläche trifft Nachmieter:<br /><span className='fs-2'>Match mit!</span></h1>
                <div className="dropdown d-lg-none mb-4">
                    <button type="button" className="btn btn-outline-primary p-3 me-2 text-black-50 position-relative" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-question-circle"></i> Was wir Dir bieten
                    </button>
                    <ul className="dropdown-menu dropdown-menu-start mw-200px">
                        <li className="p-3">
                            <WelcomeInfoBox />
                        </li>
                    </ul>
                </div>
                <p className="text-muted fs-5 mb-4">Log Dich ein oder registriere Dich kostenlos!</p>
            </div>
            {alert !== undefined &&
                <div className="col-12 pt-1 px-md-3">
                    <AlertBox alert={alert} autoDismiss={false} />
                </div>
            }
            <div className="col-12 pt-1 text-start px-md-3">
                <LoginForm formData={formData} setFormData={setFormData} formValidationHandler={formValidationHandler} />
                <div className="d-flex gap-4 mt-3">
                    <button className="btn btn-primary w-100" onClick={submitForm}>LOGIN</button>
                    <Link to="/registrierung" className="btn btn-secondary w-100" type="button">REGISTRIEREN</Link>
                </div>
                <div className="mt-3 mb-3">
                    <Link to="/passwort-vergessen" type="button">
                        <small>Passwort vergessen?</small>
                    </Link>
                </div>
            </div>
            <div className="col-12 pt-0 pt-lg-3 px-md-3 text-center">
                <div className="fs-5">Mehr Informationen erhalten</div>
                <Link to="https://www.llasm.de/#1986651303" target="_blank" className="btn btn-lg mx-auto mb-0 mb-lg-3">
                    <i className="bi bi-download fs-3"></i>
                </Link>
            </div>
        </div>
    );
};

export default LoginPage;
