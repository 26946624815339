import MatchingService from '../../Matching/MatchingService';
import SettlementConceptActivationContingent from '../../Entity/SettlementConcept/SettlementConceptActivationContingent';
import MatchingResultGroup from '../../Entity/Matching/MatchingResultGroup';
import UnlockMatchingResultGroupsOverlay from './UnlockMatchingResultGroupsOverlay';
import React, {useState} from 'react';

interface MatchPointButtonProps {
    readonly matchingResultGroups: MatchingResultGroup[];
    readonly settlementConceptActivationContingents: SettlementConceptActivationContingent[];
    readonly onUnlockMatchingResultsOverlayClose?: () => void;
}

const matchingService: MatchingService = new MatchingService(process.env.REACT_APP_LLASM_API_URL!);

const MatchPointButton = (props: MatchPointButtonProps): React.JSX.Element => {
    const [showUnlockMatchingResultsOverlay, setShowUnlockMatchingResultsOverlay] = useState<boolean>(false);

    const [amount, setAmount] = useState<number>();

    const [price, setPrice] = useState<number>();

    const [settlementConceptActivationContingent, setSettlementConceptActivationContingent] = useState<SettlementConceptActivationContingent>();

    const numberOfLockedMatchingResults: number = matchingService.fetchNumberOfLockedMatchingResultGroups(props.matchingResultGroups);

    let lastNumberOfUnlocks: number = 1;

    let currentNumberOfSettlementConceptActivationContingents: number = 0;

    const handleButtonClick = (amount: number, price: number, settlementConceptActivationContingent: SettlementConceptActivationContingent): void => {
        setAmount(amount);
        setPrice(price);
        setSettlementConceptActivationContingent(settlementConceptActivationContingent);
        setShowUnlockMatchingResultsOverlay(true);
    };

    const createSettlementConceptsUnlockButton = (settlementConceptActivationContingent: SettlementConceptActivationContingent): React.JSX.Element | null => {
        if (lastNumberOfUnlocks > numberOfLockedMatchingResults) {
            return null;
        }

        if (settlementConceptActivationContingent.numberOfActivations === 1) {
            return null;
        }

        const numberOfResults: number = numberOfLockedMatchingResults >= settlementConceptActivationContingent.numberOfActivations
            ? settlementConceptActivationContingent.numberOfActivations
            : numberOfLockedMatchingResults;

        const matchPointCost: number = numberOfLockedMatchingResults >= settlementConceptActivationContingent.numberOfActivations
            ? settlementConceptActivationContingent.matchPointCost
            : numberOfLockedMatchingResults * (settlementConceptActivationContingent.matchPointCost! / settlementConceptActivationContingent.numberOfActivations);

        if (currentNumberOfSettlementConceptActivationContingents !== 0 && numberOfLockedMatchingResults <= currentNumberOfSettlementConceptActivationContingents) {
            return null;
        }

        currentNumberOfSettlementConceptActivationContingents = settlementConceptActivationContingent.numberOfActivations;

        return (
            <div className="me-2" >
                <button className="btn btn-secondary mb-3" onClick={() => handleButtonClick(numberOfResults, matchPointCost, settlementConceptActivationContingent)}>
                    <strong>
                        {numberOfResults}
                        {numberOfResults === 1 ?
                            <> TOP-Ergebnis</>
                            :
                            <> TOP-Ergebnisse</>
                        }
                    </strong><br/>
                    für {matchPointCost} MatchPoints freischalten
                </button>
            </div>
        );
    };

    return (
        <div className="d-md-flex">
            {props.settlementConceptActivationContingents.map(settlementConceptActivationContingent=> (
                createSettlementConceptsUnlockButton(settlementConceptActivationContingent)
            ))}

            {amount !== undefined && price !== undefined && settlementConceptActivationContingent !== undefined &&
                <UnlockMatchingResultGroupsOverlay
                    matchingResultGroups={props.matchingResultGroups}
                    show={showUnlockMatchingResultsOverlay}
                    setShow={setShowUnlockMatchingResultsOverlay}
                    amount={amount}
                    price={price}
                    settlementConceptActivationContingent={settlementConceptActivationContingent}
                    onUnlockMatchingResultsOverlayClose={props.onUnlockMatchingResultsOverlayClose}
                />
            }
        </div>
    );
};

export default MatchPointButton;
