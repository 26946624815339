import routerUser from './router_user';
import routerAdmin from './router_admin';
import {Router} from '@remix-run/router';
import {createBrowserRouter} from 'react-router-dom';

let router: Router = createBrowserRouter([{}]);

if (process.env.REACT_APP_FRONTEND! === 'user') {
    router = routerUser;
}

if (process.env.REACT_APP_FRONTEND! === 'admin') {
    router = routerAdmin;
}

export default router;
