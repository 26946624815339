import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import Property from '../Entity/Property/Property';
import LocationDetail from '../Entity/Property/LocationDetail';
import AdditionalDetail from '../Entity/Property/AdditionalDetail';
import Contact from '../Entity/Contact/Contact';
import Image from '../Entity/Image/Image';
import Document from '../Entity/Document/Document';
import PropertyResponse from '../../../api/Llasm/Property/PropertyResponse';
import ImageResponse from '../../../api/Llasm/Image/ImageResponse';
import DocumentResponse from '../../../api/Llasm/Document/DocumentResponse';
import PropertyPatchRequest from '../../../api/Llasm/Property/PropertyPatchRequest';
import PropertyPostRequest from '../../../api/Llasm/Property/PropertyPostRequest';
import LocationDetailPostRequest from '../../../api/Llasm/Property/LocationDetailPostRequest';
import LocationDetailPatchRequest from '../../../api/Llasm/Property/LocationDetailPatchRequest';
import DetailPostRequest from '../../../api/Llasm/Property/DetailPostRequest';
import DetailPatchRequest from '../../../api/Llasm/Property/DetailPatchRequest';
import OfferDetailPostRequest from '../../../api/Llasm/Property/OfferDetailPostRequest';
import OfferDetailPatchRequest from '../../../api/Llasm/Property/OfferDetailPatchRequest';
import RentalDetailPostRequest from '../../../api/Llasm/Property/OfferDetail/RentalDetailPostRequest';
import RentalDetailPatchRequest from '../../../api/Llasm/Property/OfferDetail/RentalDetailPatchRequest';
import PurchaseDetailPostRequest from '../../../api/Llasm/Property/OfferDetail/PurchaseDetailPostRequest';
import PurchaseDetailPatchRequest from '../../../api/Llasm/Property/OfferDetail/PurchaseDetailPatchRequest';
import AdditionalDetailPostRequest from '../../../api/Llasm/Property/AdditionalDetailPostRequest';
import AdditionalDetailPatchRequest from '../../../api/Llasm/Property/AdditionalDetailPatchRequest';
import {AxiosResponse} from 'axios';

class PropertyService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly resourcePath: string;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
        this.resourcePath = '/properties';
    }

    public async fetchPropertiesFromApi(): Promise<Property[]> {
        const apiResponse: AxiosResponse<PropertyResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath);

        return apiResponse.data.map((propertyResponse: PropertyResponse): Property => {
            return Property.createFromPropertyResponse(propertyResponse);
        });
    }

    public async fetchPropertyFromApiById(id: number): Promise<Property> {
        const apiResponse: AxiosResponse<PropertyResponse> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + id);

        return Property.createFromPropertyResponse(apiResponse.data);
    }

    public async fetchImagesFromProperty(property: Property): Promise<Image[]> {
        const apiResponse: AxiosResponse<ImageResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + property.id + '/images');

        return apiResponse.data.map((imageResponse: ImageResponse): Image => {
            return Image.createFromImageResponse(imageResponse);
        })
    }

    public async fetchDocumentsFromProperty(property: Property): Promise<Document[]> {
        const apiResponse: AxiosResponse<DocumentResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + property.id + '/documents');

        return apiResponse.data.map((documentResponse: DocumentResponse): Document => {
            return Document.createFromDocumentResponse(documentResponse);
        })
    }

    public async deleteImageFromProperty(property: Property, image: Image): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.delete('/properties/' + property.id + '/images/' + image.id);
    }

    public async persistProperty(property: Property): Promise<Property> {
        const response: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, PropertyPostRequest>(
            this.resourcePath,
            PropertyService.mapFromPropertyToPropertyPostRequest(property)
        );

        const apiResponse: AxiosResponse<PropertyResponse> = await this.llasmApiV1Provider.getFromLocation(response.headers.location);

        return Property.createFromPropertyResponse(apiResponse.data);
    }

    public async updateProperty(property: Property): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.patch<null, AxiosResponse<null>, PropertyPatchRequest>(
            this.resourcePath  + '/' + property.id,
            PropertyService.mapFromPropertyToPropertyPatchRequest(property)
        );
    }

    public static buildImageApiPath(property: Property, image: Image): string {
        return '/properties/' + property.id + '/images/' + image.id;
    }

    public static buildThumbnailImageApiPath(property: Property, image: Image): string {
        return '/properties/' + property.id + '/thumbnail-images/' + image.id;
    }

    public static buildDocumentApiPath(property: Property, document: Document): string {
        return '/properties/' + property.id + '/documents/' + document.id;
    }

    private static mapFromPropertyToPropertyPostRequest(property: Property): PropertyPostRequest {
        return PropertyService.mapPropertyToApiRequest(property) as PropertyPostRequest;
    }

    private static mapFromPropertyToPropertyPatchRequest(property: Property): PropertyPatchRequest {
        return PropertyService.mapPropertyToApiRequest(property) as PropertyPatchRequest;
    }

    private static mapPropertyToApiRequest(property: Property): PropertyPostRequest | PropertyPatchRequest {
        return {
            active: property.active,
            marketingStatus: property.marketingStatus,
            title: property.title,
            locationDetail: PropertyService.mapLocationDetailToApiRequestFromProperty(property.locationDetail),
            detail: PropertyService.mapDetailToApiRequestFromProperty(property),
            offerDetail: PropertyService.mapOfferDetailToApiRequestFromProperty(property),
            additionalDetail: PropertyService.mapAdditionalDetailToApiRequest(property.additionalDetail),
            contactIds: property.contacts.map((contact: Contact): number => contact.id!)
        };
    }

    private static mapLocationDetailToApiRequestFromProperty(locationDetail: LocationDetail): LocationDetailPostRequest | LocationDetailPatchRequest {
        return {
            cityId: locationDetail.city!.id!,
            postalCode: locationDetail.postalCode,
            streetName: locationDetail.streetName,
            houseNumber: locationDetail.houseNumber,
            addressAffix: locationDetail.addressAffix,
            locationDescription: locationDetail.locationDescription,
            locationType: locationDetail.locationType,
            locationCategory: locationDetail.locationCategory,
            locationFactors: locationDetail.locationFactors
        };
    }

    private static mapDetailToApiRequestFromProperty(property: Property): DetailPostRequest | DetailPatchRequest {
        return {
            floors: property.detail.floors,
            areaSize: property.detail.areaSize,
            secondaryAreaSize: property.detail.secondaryAreaSize,
            outdoorSalesAreaSize: property.detail.outdoorSalesAreaSize,
            storeWidth: property.detail.storeWidth,
            shopWindowFrontLength: property.detail.shopWindowFrontLength,
            accessibilityType: property.detail.accessibilityType,
            groundLevelSalesArea: property.detail.groundLevelSalesArea,
            parkingLotAvailable: property.detail.parkingLotAvailable,
            usageDescription: property.detail.objectDescription ?? '',  // TODO - deprecated
            objectDescription: property.detail.objectDescription ?? ''  // TODO - WiP
        };
    }

    private static mapOfferDetailToApiRequestFromProperty(property: Property): OfferDetailPostRequest | OfferDetailPatchRequest {
        return {
            propertyAcquisitionTypes: property.offerDetail.propertyAcquisitionTypes,
            storeSpaceAvailableFrom: property.offerDetail.storeSpaceAvailableFrom !== null ? property.offerDetail.storeSpaceAvailableFrom.toString() : null,
            rentalDetail: PropertyService.mapRentalDetailToApiRequestFromProperty(property),
            purchaseDetail: PropertyService.mapPurchaseDetailToApiRequestFromProperty(property),
            commissionAmount: property.offerDetail.commissionAmount,
            commissionNote: property.offerDetail.commissionNote
        };
    }

    private static mapRentalDetailToApiRequestFromProperty(property: Property): RentalDetailPostRequest | RentalDetailPatchRequest {
        return {
            netRentalPrice: property.offerDetail.rentalDetail.netRentalPrice,
            commissionAmount: null, // TODO
            commissionNote: null, // TODO
            deposit: property.offerDetail.rentalDetail.deposit
        };
    }

    private static mapPurchaseDetailToApiRequestFromProperty(property: Property): PurchaseDetailPostRequest | PurchaseDetailPatchRequest {
        return {
            purchasePrice: property.offerDetail.purchaseDetail.purchasePrice,
            commissionAmount: null, // TODO
            commissionNote: null // TODO
        };
    }

    private static mapAdditionalDetailToApiRequest(additionalDetail: AdditionalDetail): AdditionalDetailPostRequest | AdditionalDetailPatchRequest {
        return {
            buildingCondition: additionalDetail.buildingCondition,
            monumentProtectionType: additionalDetail.monumentProtectionType,
            internetConnectionTypes: additionalDetail.internetConnectionTypes,
            ceilingHeight: additionalDetail.ceilingHeight,
            floorLoadCapacity: additionalDetail.floorLoadCapacity,
            numberOfEntrances: additionalDetail.numberOfEntrances,
            liftTypes: additionalDetail.liftTypes,
            features: additionalDetail.features,
            generalFurnishing: additionalDetail.generalFurnishing,
            storeSpaceInterestPopupConcept: additionalDetail.storeSpaceInterestPopupConcept,
            storeSpaceInterestMixedUseConcept: additionalDetail.storeSpaceInterestMixedUseConcept
        };
    }
}

export default PropertyService;
