import PropertyService from './PropertyService';
import Property from '../Entity/Property/Property';
import Contact from '../Entity/Contact/Contact';
import Image from '../Entity/Image/Image';
import Document from '../Entity/Document/Document';
import PropertyAcquisitionType, {getPropertyAcquisitionTypeLabel} from '../Entity/PropertyAcquisitionType';
import LocationFactor, {getLocationFactorLabel} from '../Entity/LocationFactor';
import {getCentralPlaceTypeLabel} from '../Entity/CentralPlaceType';
import {getLocationTypeLabel} from '../Entity/LocationType';
import {getLocationCategoryLabel} from '../Entity/LocationCategory';
import {getAccessibilityTypeLabel} from '../Entity/AccessibilityType';
import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import MarketingStatusBadge from './MarketingStatusBadge';
import PropertyInterestButton from '../PropertyInterest/PropertyInterestButton';
import ImageComponent from '../Image/Image';
import Disclaimer from '../Disclaimer/Disclaimer';
import ContactDetailInformation from '../Contact/ContactDetailInformation';
import DocumentCollapseCard from '../Document/DocumentCollapseCard';
import CollapseCard from '../Component/CollapseCard/CollapseCard';
import Card from '../Component/Card/Card';
import Divider from '../Component/Divider/Divider';
import LabelValuePair from '../../../components/LabelValuePair';
import LabelValuesPair from '../../../components/LabelValuesPair';
import React from 'react';

interface PropertyDetailInformationProps {
    readonly property: Property;
    readonly settlementConcept?: SettlementConcept;
}

const PropertyDetailInformation = (props: PropertyDetailInformationProps): React.JSX.Element => {
    const propertyAcquisitionTypeValues: string[] = props.property.offerDetail.propertyAcquisitionTypes.map((propertyAcquisitionType: PropertyAcquisitionType): string => {
        return getPropertyAcquisitionTypeLabel(propertyAcquisitionType);
    });

    const locationFactorValues: string[] = props.property.locationDetail.locationFactors.map((locationFactor: LocationFactor): string => {
        return getLocationFactorLabel(locationFactor);
    });

    const buildMarketingStatusBadge = (): React.JSX.Element => {
        return (
            <div className="d-flex justify-content-end">
                <MarketingStatusBadge marketingStatus={props.property.marketingStatus} />
            </div>
        );
    };

    const buildImageGetPath = (image: Image): string => {
        return PropertyService.buildImageApiPath(props.property, image);
    };

    const buildDocumentGetPath = (document: Document): string => {
        if (props.property === undefined) {
            throw new Error();
        }

        return PropertyService.buildDocumentApiPath(props.property, document);
    };

    return (
        <>
            <CollapseCard cardType="shadow" title="Grundinformationen" className="mb-3" headerElement={buildMarketingStatusBadge()}>
                <div className="row">
                    {props.property.locationDetail.streetName !== null &&
                        <div className="col-12 col-md-6 mb-3">
                            <LabelValuePair label="Straße" value={props.property.locationDetail.streetName} />
                        </div>
                    }
                    {props.property.locationDetail.houseNumber !== null &&
                        <div className="col-12 col-md-6 mb-3">
                            <LabelValuePair label="Hausnummer" value={props.property.locationDetail.houseNumber} />
                        </div>
                    }
                    <div className="col-12 col-md-6 mb-3">
                        <LabelValuePair label="PLZ" value={props.property.locationDetail.postalCode} />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <LabelValuePair label="Ort" value={props.property.locationDetail.city!.placeName} />
                    </div>
                    {props.property.detail.floors.length > 0 &&
                        <div className="col-12 col-md-6 mb-3">
                            <LabelValuesPair label="Etage(n)" values={props.property.detail.floors} delimiter=" · " />
                        </div>
                    }
                </div>
                {props.property.detail.objectDescription !== null &&
                    <div className="mb-3">
                        <LabelValuePair label="Objektbeschreibung/ehemalige Nutzung" value={props.property.detail.objectDescription} />
                    </div>
                }
            </CollapseCard>
            {props.property.images.length > 0 &&
                <CollapseCard cardType="shadow" title="Bilder" className="mb-3">
                    <div className="row">
                        {props.property.images.map((image: Image): React.JSX.Element => (
                            <div key={'image-' + image.id} className="col-12 col-md-6 mb-3">
                                <ImageComponent buildImageGetPath={buildImageGetPath} image={image} className="img-fluid" />
                            </div>
                        ))}
                    </div>
                </CollapseCard>
            }
            {props.property.documents.length > 0 &&
                <DocumentCollapseCard documents={props.property.documents} buildDocumentGetPath={buildDocumentGetPath} />
            }
            <CollapseCard cardType="shadow" title="Standortinformationen" className="mb-3">
                <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                        <LabelValuePair label="Standort-Typ" value={getLocationTypeLabel(props.property.locationDetail.locationType)} />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <LabelValuePair label="Lagekategorie" value={getLocationCategoryLabel(props.property.locationDetail.locationCategory)} />
                    </div>
                </div>
                {(props.property.locationDetail.city !== undefined && props.property.locationDetail.city.advancedPlaceInformation !== null) &&
                    <div className="row">
                        {props.property.locationDetail.city.advancedPlaceInformation.population > 0 &&
                            <div className="col-12 col-md-6 mb-3">
                                <LabelValuePair label="Einwohner am Standort" value={new Intl.NumberFormat('de-DE').format(props.property.locationDetail.city.advancedPlaceInformation.population)} />
                            </div>
                        }
                        {props.property.locationDetail.city.advancedPlaceInformation.centralPlaceType !== null &&
                            <div className="col-12 col-md-6 mb-3">
                                <LabelValuePair label="Ortsklassifizierung" value={getCentralPlaceTypeLabel(props.property.locationDetail.city.advancedPlaceInformation.centralPlaceType)} />
                            </div>
                        }
                        {(props.property.locationDetail.city.advancedPlaceInformation.purchasingPowerPerYear !== null && props.property.locationDetail.city.advancedPlaceInformation.purchasingPowerPerYear > 0) &&
                            <div className="col-12 col-md-6 mb-3">
                                <LabelValuePair label="Kaufkraft/Jahr" value={new Intl.NumberFormat('de-DE').format(props.property.locationDetail.city.advancedPlaceInformation.purchasingPowerPerYear) + ' €'} />
                            </div>
                        }
                        {(props.property.locationDetail.city.advancedPlaceInformation.purchasingPower !== null && props.property.locationDetail.city.advancedPlaceInformation.purchasingPower > 0) &&
                            <div className="col-12 col-md-6 mb-3">
                                <LabelValuePair label="KKP-Index" value={new Intl.NumberFormat('de-DE').format(props.property.locationDetail.city.advancedPlaceInformation.purchasingPower)} />
                            </div>
                        }
                    </div>
                }
                {props.property.locationDetail.locationFactors.length > 0 &&
                    <div className="row">
                        <div className="col-12 col-md-6 mb-3">
                            <LabelValuesPair label="Standortfaktoren" values={locationFactorValues} delimiter=" · " />
                        </div>
                    </div>
                }
            </CollapseCard>
            <CollapseCard cardType="shadow" title="Objektinformationen" className="mb-3">
                <div className="row">
                    {props.property.offerDetail.propertyAcquisitionTypes.length > 0 &&
                        <div className="col-12 col-md-4 mb-3">
                            <LabelValuesPair label="Angebotsart(en)" values={propertyAcquisitionTypeValues} delimiter=", " />
                        </div>
                    }
                    {(
                        props.property.offerDetail.purchaseDetail.purchasePrice !== null
                        && props.property.offerDetail.purchaseDetail.purchasePrice > 0
                        && props.property.offerDetail.propertyAcquisitionTypes.includes(PropertyAcquisitionType.Buy)
                    ) &&
                        <div className="col-12 col-md-4 mb-3">
                            <LabelValuePair label="Kaufpreis" value={new Intl.NumberFormat('de-DE').format(props.property.offerDetail.purchaseDetail.purchasePrice) + ' €'} />
                        </div>
                    }
                    {props.property.offerDetail.commissionAmount !== null &&
                        <div className="col-12 col-md-4 mb-3">
                            <LabelValuePair label="Provision" value={props.property.offerDetail.commissionAmount} />
                        </div>
                    }
                    {props.property.offerDetail.commissionNote !== null &&
                        <div className="col-12 mb-3">
                            <LabelValuePair label="Provision (Erläuterung)" value={props.property.offerDetail.commissionNote} />
                        </div>
                    }
                    {props.property.offerDetail.propertyAcquisitionTypes.includes(PropertyAcquisitionType.Rent) === true &&
                        <>
                            {(props.property.offerDetail.rentalDetail.netRentalPrice !== null && props.property.offerDetail.rentalDetail.netRentalPrice > 0) &&
                                <div className="col-12 col-md-4 mb-3">
                                    <LabelValuePair label="Miete (netto) / Monat" value={new Intl.NumberFormat('de-DE').format(props.property.offerDetail.rentalDetail.netRentalPrice) + ' €'} />
                                </div>
                            }
                            {props.property.offerDetail.rentalDetail.deposit !== null &&
                                <div className="col-12 col-md-4 mb-3">
                                    <LabelValuePair label="Kaution" value={props.property.offerDetail.rentalDetail.deposit} />
                                </div>
                            }
                        </>
                    }
                    {props.property.detail.areaSize > 0 &&
                        <div className="col-12 col-md-4 mb-3">
                            <LabelValuePair label="Gesamtfläche" value={new Intl.NumberFormat('de-DE').format(props.property.detail.areaSize) + ' m²'} />
                        </div>
                    }
                    {(props.property.detail.secondaryAreaSize !== null && props.property.detail.secondaryAreaSize > 0) &&
                        <div className="col-12 col-md-4 mb-3">
                            <LabelValuePair label="Nebenfläche" value={new Intl.NumberFormat('de-DE').format(props.property.detail.secondaryAreaSize) + ' m²'} />
                        </div>
                    }
                    {(props.property.detail.outdoorSalesAreaSize !== null && props.property.detail.outdoorSalesAreaSize > 0) &&
                        <div className="col-12 col-md-4 mb-3">
                            <LabelValuePair label="Außenverkaufsfläche" value={new Intl.NumberFormat('de-DE').format(props.property.detail.outdoorSalesAreaSize) + ' m²'} />
                        </div>
                    }
                    {(props.property.detail.storeWidth !== null && props.property.detail.storeWidth > 0) &&
                        <div className="col-12 col-md-4 mb-3">
                            <LabelValuePair label="Ladenbreite" value={new Intl.NumberFormat('de-DE').format(props.property.detail.storeWidth) + ' m'} />
                        </div>
                    }
                    {(props.property.detail.shopWindowFrontLength !== null && props.property.detail.shopWindowFrontLength > 0) &&
                        <div className="col-12 col-md-4 mb-3">
                            <LabelValuePair label="Schaufensterfront" value={new Intl.NumberFormat('de-DE').format(props.property.detail.shopWindowFrontLength) + ' m'} />
                        </div>
                    }
                    {props.property.detail.groundLevelSalesArea === true &&
                        <div className="col-12 col-md-4 mb-3">
                            <LabelValuePair label="Ebenerdige Verkaufsfläche" value="ist vorhanden" />
                        </div>
                    }
                    <div className="col-12 col-md-4 mb-3">
                        <LabelValuePair label="Barrierefreier Zugang" value={getAccessibilityTypeLabel(props.property.detail.accessibilityType)} />
                    </div>
                    {props.property.detail.parkingLotAvailable === true &&
                        <div className="col-12 col-md-4 mb-3">
                            <LabelValuePair label="Parkplätze am Objekt" value="sind vorhanden" />
                        </div>
                    }
                </div>
                <div className="row">
                    {props.property.additionalDetail.storeSpaceInterestPopupConcept === true &&
                        <div className="col-12 col-md-4 mb-3">
                            <LabelValuePair label="Für PopUp-Konzepte geeignet" value="Ja" />
                        </div>
                    }
                    {props.property.additionalDetail.storeSpaceInterestMixedUseConcept === true &&
                        <div className="col-12 col-md-4 mb-3">
                            <LabelValuePair label="Für Mixed-User-Konzepte geeignet" value="Ja" />
                        </div>
                    }
                </div>
            </CollapseCard>
            {props.settlementConcept !== undefined && props.settlementConcept.owner === true &&
                <Card cardType="shadow" className="mb-3 p-3 p-lg-4 p-xl-5">
                    <PropertyInterestButton settlementConcept={props.settlementConcept} property={props.property} />
                    <div className="mt-3">
                        Sende hier Dein Feedback an den/die Flächenanbieter:in und zeige damit, ob Euer gemeinsames Match für Dich grundsätzlich relevant ist. Bei Interesse solltest Du im Chat mit dem/der Flächenanbieter:in schreiben, um weitere Details zu klären. Hast Du kein Interesse, kannst Du das selbstverständlich im Chat auch begründen, ggf. hat der/die Anbieter:in ja auch noch weitere Angebote oder kann sich einen Umbau der Fläche vorstellen.
                    </div>
                </Card>
            }
            <CollapseCard cardType="shadow" title="Ansprechpartner" className="mb-3">
                {props.property.contacts.map((contact: Contact, index: number): React.JSX.Element => (
                    <div key={'contact-' + contact.id}>
                        {index > 0 &&
                            <Divider color="lightgrey" />
                        }
                        <ContactDetailInformation contact={contact} />
                    </div>
                ))}
            </CollapseCard>
            <Disclaimer title="Rechtliche Hinweise und Haftungsausschluss" className="mt-4 pb-5" />
        </>
    );
};

export default PropertyDetailInformation;
