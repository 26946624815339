import MatchingService from '../../Matching/MatchingService';
import PropertyService from '../PropertyService';
import IndustryClassificationService from '../../IndustryClassification/IndustryClassificationService';
import Property from '../../Entity/Property/Property';
import MatchingParameter from '../../Entity/Matching/MatchingParameter';
import IndustryClassification from '../../Entity/IndustryClassification/IndustryClassification';
import FormData from '../../Entity/Form/FormData';
import Alert from '../../Entity/Alert/Alert';
import AlertType from '../../Entity/Alert/AlertType';
import {TaskStatus} from '../../Entity/Task/TaskStatus';
import AgeStructure from '../../Entity/AgeStructure';
import TargetGroup from '../../Entity/TargetGroup';
import PriceSegment from '../../Entity/PriceSegment';
import BranchingDegree from '../../Entity/BranchingDegree';
import AuthenticationState from '../../Entity/Authentication/AuthenticationState';
import FieldValidationDefinition from '../../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../../FormValidationHandler/RequiredValidationDefinition';
import FormValidationHandler from '../../FormValidationHandler/FormValidationHandler';
import MatchingParameterForm from './MatchingParameterForm';
import PropertyDetailInformation from '../PropertyDetailInformation';
import MatchingResultOverview from './MatchingResultOverview';
import InfoButtonOverlay from '../../Component/InfoButtonOverlay/InfoButtonOverlay';
import Overlay from '../../Component/Overlay/Overlay';
import InfoBox from '../../Component/InfoBox/InfoBox';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import AlertBox from '../../../../components/AlertBox';
import Spinner from '../../../../components/Spinner';
import {fetchUser} from '../../../../features/user/userSlice';
import {useAppDispatch, useAppSelector} from '../../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Du hast nicht alle Pflichtfelder gefüllt. Bitte kontrolliere die mit einem roten Ausrufezeichen markierten Felder.');

const fieldValidationDefinitions: FieldValidationDefinition<MatchingParameter>[] = [
    new RequiredValidationDefinition<MatchingParameter>('ageStructures', 'Gib bitte mindestens eine Altersgruppe an.'),
    new RequiredValidationDefinition<MatchingParameter>('targetGroups', 'Gib bitte mindestens eine Zielgruppe an.'),
    new RequiredValidationDefinition<MatchingParameter>('priceSegments', 'Gib bitte mindestens ein Preissegment an.'),
    new RequiredValidationDefinition<MatchingParameter>('branchingDegrees', 'Gib bitte mindestens einen Filialisierungsgrad an.'),
];

const formValidationHandler: FormValidationHandler<MatchingParameter> = new FormValidationHandler<MatchingParameter>(fieldValidationDefinitions);

const propertyService: PropertyService = new PropertyService(process.env.REACT_APP_LLASM_API_URL!);

const matchingService: MatchingService = new MatchingService(process.env.REACT_APP_LLASM_API_URL!);

const industryClassificationService: IndustryClassificationService = new IndustryClassificationService(process.env.REACT_APP_LLASM_API_URL!);

const MatchingOverviewPage = (): React.JSX.Element => {
    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const {propertyId} = useParams<string>();

    const [property, setProperty] = useState<Property>();

    const [formData, setFormData] = useState<FormData<MatchingParameter>>();

    const [alert, setAlert] = useState<Alert>();

    const [industryClassifications, setIndustryClassifications] = useState<IndustryClassification[]>();

    const [isStartingMatching, setIsStartingMatching] = useState<boolean>();

    const [showPropertyDetailsOverlay, setShowPropertyDetailsOverlay] = useState<boolean>(false);

    const [scrollPosition, setScrollPosition] = useState<number | null>(null);

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Meine Ladenlokale', link: '/flaechen'},
                {name: 'Matching'},
            ]
        });
    }, [dispatch]);

    useEffect((): void => {
        fetchIndustryClassifications();
    }, []);

    useEffect((): void => {
        if (industryClassifications === undefined) {
            return;
        }

        if (property === undefined) {
            fetchProperty();
        }
    }, [industryClassifications]);

    useEffect((): void => {
        if (property === undefined) {
            return;
        }

        if (property.matchingTask !== null && (property.matchingTask.taskStatus === TaskStatus.Created || property.matchingTask.taskStatus === TaskStatus.InProgress)) {
            setTimeout(
                async (): Promise<void> => {
                    await fetchProperty();
                },
                10000
            );
        }

        if (property.matchingTask === null) {
            startMatching();
        }
    }, [property]);

    const fetchIndustryClassifications = async (): Promise<void> => {
        const industryClassifications: IndustryClassification[] = await industryClassificationService.fetchIndustryClassificationsFromApi();

        setIndustryClassifications(industryClassifications);
    };

    const fetchProperty = async (): Promise<void> => {
        if (industryClassifications === undefined) {
            return;
        }

        let property: Property;

        property = await propertyService.fetchPropertyFromApiById(Number(propertyId!));

        setProperty(property);

        if (property.matchingParameter !== null) {
            if (property.matchingParameter.industryClassifications.length === 0) {
                property.matchingParameter.industryClassifications.push(new IndustryClassification());
            }
        } else {
            property.matchingParameter = new MatchingParameter();

            property.matchingParameter.industryClassifications = industryClassifications.filter((industryClassification: IndustryClassification): boolean => {
                return industryClassification.levelTwo === null && industryClassification.levelThree === null;
            });

            property.matchingParameter.ageStructures.push(AgeStructure.YOUNG_PEOPLE, AgeStructure.OLDER_PEOPLE, AgeStructure.CHILDREN, AgeStructure.FAMILIES);
            property.matchingParameter.targetGroups.push(TargetGroup.FEMALE, TargetGroup.MALE, TargetGroup.DIVERSE);
            property.matchingParameter.priceSegments.push(PriceSegment.Low, PriceSegment.LowMid, PriceSegment.Mid, PriceSegment.MidHigh, PriceSegment.High);
            property.matchingParameter.branchingDegrees.push(BranchingDegree.LOCAL_HERO, BranchingDegree.REGIONAL, BranchingDegree.NATIONAL, BranchingDegree.DACH, BranchingDegree.EUROPE, BranchingDegree.GLOBAL);
        }

        setFormData({data: property.matchingParameter});
    };

    const startMatching = async (): Promise<void> => {
        if (property === undefined) {
            return;
        }

        if (formData === undefined) {
            return;
        }

        formValidationHandler.validate(formData);

        if (formValidationHandler.hasErrors(formData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        setIsStartingMatching(true);

        try {
            await matchingService.startMatchingByProperty(property);
        } catch (error) {
        }

        await fetchProperty();

        setIsStartingMatching(false);
    };

    const onUnlockMatchingResultsOverlayClose = (): void => {
        if (authenticatedUser !== undefined  && authenticatedUser !== null) {
            dispatch(fetchUser(authenticatedUser));
        }

        setProperty(undefined);

        fetchProperty();
    };

    if (property === undefined || industryClassifications === undefined || isStartingMatching === true) {
        return <Spinner />;
    }

    return (
        <div className="container-fluid">
            <div className="row align-items-center mb-4">
                <div className="col-12 d-flex align-items-center">
                    <Link to="/flaechen"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                    <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1 text-overflow-ellipsis fs-3">Matching für »{property.title}«</h1>
                    <div className="ms-auto d-flex">
                        <InfoButtonOverlay title={'Matching für »' + property.title + '«'} className="fs-5 mt-1">
                            <div className="mb-3">
                                Bitte beachte, dass Deine gewählten Präferenzen bei Übereinstimmung mit Flächengesuchen für einen höheren Matching-Score sorgen und keine Filterkriterien sind. Der Branchenfilter hingegen sorgt dafür, dass Du Angebote aus der gewählten Kategorie angezeigt bekommst. Sofern kein Flächengesuch aus der gewählten, priorisierten Kategorie angezeigt wird, gibt es aktuell kein passendes Flächengesuch. Du erhältst aber Vorschläge aus anderen Kategorien.
                                Bei Fragen zum Matching wende Dich gerne an uns: help@llasm.de oder 02171-4018846.
                            </div>
                        </InfoButtonOverlay>
                        <div className="dropdown">
                            <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="bi bi-three-dots-vertical"></i>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <Link to={'/flaechen/' + property.id} className="dropdown-item">Objekt bearbeiten</Link>
                                </li>
                                <li>
                                    <button className="dropdown-item" onClick={() => setShowPropertyDetailsOverlay(true)}>Objektdetails</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {alert !== undefined &&
                <AlertBox alert={alert} autoDismiss={false} />
            }
            <div className="row">
                <div className="col-12 col-md-12 col-lg-4 mb-3">
                    <MatchingParameterForm formData={formData!} setFormData={setFormData} industryClassifications={industryClassifications} />
                    {(property.matchingTask === null || (property.matchingTask.taskStatus === TaskStatus.Done || property.matchingTask.taskStatus === TaskStatus.Failed)) &&
                        <div className="d-flex justify-content-center">
                            <button onClick={startMatching} className="btn btn-primary w-100 ms-4 me-4">
                                <i className="bi bi-arrow-repeat me-2"></i>
                                Let's Match
                            </button>
                        </div>
                    }
                </div>
                <div className="col-12 col-md-12 col-lg-8">
                    {property.matchingTask === null &&
                        <InfoBox cardType="outline" className="p-3 mt-3 mb-5">
                            <div className="mb-0 fs-6">
                                Du hast bislang kein Matching ausgeführt. Bitte starte das Matching über den
                                Button »Let's Match«.
                                Bitte wähle vorher Deine Matching-Parameter aus.
                            </div>
                        </InfoBox>
                    }
                    {(property.matchingTask !== null && (property.matchingTask.taskStatus === TaskStatus.Created || property.matchingTask.taskStatus === TaskStatus.InProgress)) &&
                        <div className="alert alert-information mt-3 mb-5">
                            <div className="mb-3 fs-6 text-center">
                                Wir suchen in unserer Datenbank nach passenden Nachnutzern für Deine Fläche/Dein Ladenlokal.
                                Bei Nachnutzungskonzepten aus 15 Ländern – von klassisch bis außergewöhnlich – sowie über 100 bunten Nutzungsarten dauert das einen Moment.
                                Bitte habe daher noch ein wenig Geduld – die Seite aktualisiert sich in Kürze automatisch.
                            </div>
                            <div className="p-3">
                                <div className="d-flex justify-content-center align-items-center">
                                    <LoadingIndicator />
                                </div>
                            </div>
                        </div>
                    }

                    {(property.matchingTask !== null && property.matchingTask.taskStatus === TaskStatus.Done) &&
                        <MatchingResultOverview matchingService={matchingService} property={property} onUnlockMatchingResultsOverlayClose={onUnlockMatchingResultsOverlayClose} scrollPosition={scrollPosition} setScrollPosition={setScrollPosition} />
                    }
                </div>
            </div>
            <Overlay show={showPropertyDetailsOverlay} setShow={setShowPropertyDetailsOverlay} title={property.title}>
                <div className="container">
                    <PropertyDetailInformation property={property} />
                </div>
            </Overlay>
        </div>
    );
};

export default MatchingOverviewPage;
