import PropertyCriteria from '../Entity/SettlementConcept/PropertyCriteria';
import FormData from '../Entity/Form/FormData';
import SelectOption from '../Entity/Form/SelectOption';
import LocationFactor from '../Entity/LocationFactor';
import PropertyAcquisitionType from '../Entity/PropertyAcquisitionType';
import ParkingLotRequirementType from '../Entity/ParkingLotRequirementType';
import LocationTypeAndLocationCategory from './LocationTypeAndLocationCategory';
import LocationTypeAndLocationCategorySelect from '../LocationTypeAndLocationCategorySelect';
import LocationFactorSelectField, { FieldType } from '../LocationFactorSelectField';
import PropertyAcquisitionTypeSelectField from '../PropertyAcquisitionTypeSelectField';
import ParkingLotRequirementTypeSelectField from '../ParkingLotRequirementTypeSelectField';
import InputField from '../Component/Form/Field/InputField';
import TextAreaField from '../Component/Form/Field/TextAreaField';
import SwitchField from '../Component/Form/Field/SwitchField';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import React, { ChangeEvent } from 'react';

interface PropertyCriteriaFormProps {
    readonly formData: FormData<PropertyCriteria>;
    readonly setFormData: (formData: FormData<PropertyCriteria>) => void;
    readonly setHasLocationCategoryErrors: (hasLocationCategoryErrors: boolean) => void;
    readonly formValidationHandler?: FormValidationHandler<PropertyCriteria>;
}

const PropertyCriteriaForm = (props: PropertyCriteriaFormProps): React.JSX.Element => {
    const propertyCriteria: PropertyCriteria = props.formData.data;

    const handleChange = (event: ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
        switch (event.target.name) {
            case 'areaSizeMinimum':
                propertyCriteria.areaSizeMinimum = Number(event.target.value);

                break;
            case 'areaSizeMaximum':
                propertyCriteria.areaSizeMaximum = Number(event.target.value);

                break;
            case 'secondaryAreaSize':
                if (event.target.value === '') {
                    propertyCriteria.secondaryAreaSize = null;
                } else {
                    propertyCriteria.secondaryAreaSize = Number(event.target.value);
                }

                break;
            case 'outdoorSalesAreaSize':
                if (event.target.value === '') {
                    propertyCriteria.outdoorSalesAreaSize = null;
                } else {
                    propertyCriteria.outdoorSalesAreaSize = Number(event.target.value);
                }

                break;
            case 'storeWidth':
                if (event.target.value === '') {
                    propertyCriteria.storeWidth = null;
                } else {
                    propertyCriteria.storeWidth = Number(event.target.value);
                }

                break;
            case 'shopWindowFrontLength':
                if (event.target.value === '') {
                    propertyCriteria.shopWindowFrontLength = null;
                } else {
                    propertyCriteria.shopWindowFrontLength = Number(event.target.value);
                }

                break;
            default:
                (propertyCriteria as any)[event.target.name] = event.target.value;

                break;
        }

        validateField(event.target.name);
        updateFormData();
    };

    const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>): void => {
        (propertyCriteria as any)[event.target.name] = event.target.checked;

        updateFormData();
    };

    const handleLocationFactorsChange = (locationFactors: LocationFactor[]): void => {
        propertyCriteria.locationFactors = locationFactors;

        validateField('locationFactors');
        updateFormData();
    };

    const handlePropertyAcquisitionTypesChange = (selectedValues: readonly SelectOption<PropertyAcquisitionType>[]): void => {
        propertyCriteria.propertyAcquisitionTypes = selectedValues.map((selectOption: SelectOption<PropertyAcquisitionType>): PropertyAcquisitionType => {
            return selectOption.value;
        });

        validateField('propertyAcquisitionTypes');
        updateFormData();
    };

    const handleParkingLotRequirementTypeChange = (parkingLotRequirementType: ParkingLotRequirementType): void => {
        propertyCriteria.parkingLotRequirementType = parkingLotRequirementType;

        validateField('parkingLotRequirementType');
        updateFormData();
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: propertyCriteria});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <>
            {process.env.NODE_ENV === 'development' &&
                <div className="mb-5">
                    <LocationTypeAndLocationCategory
                        formData={props.formData}
                        setFormData={props.setFormData}
                        setHasLocationCategoryErrors={props.setHasLocationCategoryErrors}
                        formValidationHandler={props.formValidationHandler}
                    />
                </div>
            }
            <div className="row mb-3">
                <div className="col-md-6 mb-3">
                    {process.env.NODE_ENV === 'production' &&
                        <div className="row mb-3">
                            <div className="col-12">
                                <LocationTypeAndLocationCategorySelect
                                    formData={props.formData}
                                    setFormData={props.setFormData}
                                    formValidationHandler={props.formValidationHandler}
                                    locationTypeIsMulti={true}
                                    locationCategoryIsMulti={true}
                                    locationTypeLabel="Standort-Typ"
                                    locationCategoryLabel="Lagekategorien"
                                    locationTypeDescription="Welche Standorttypen sind für Dein Gesuch relevant? Suchst Du in der Innenstadt, in Stadtteilzentren oder weiteren Lagen?"
                                    locationCategoryDescription="Welche Lagekategorien sind für Dein Gesuch geeignet?"
                                />
                            </div>
                        </div>
                    }
                    <div className="row mb-3">
                        <div className="col-lg-6 mb-3">
                            <InputField
                                label="Gesamtfläche von"
                                name="areaSizeMinimum"
                                required={true}
                                type="number"
                                min={0}
                                suffix="m²"
                                value={propertyCriteria.areaSizeMinimum}
                                description="Wie groß muss Deine gesuchte Fläche mindestens sein?"
                                formErrors={FormValidationHandler.getFieldErrors(props.formData, 'areaSizeMinimum')}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-lg-6 mb-3">
                            <InputField
                                label="Gesamtfläche bis"
                                name="areaSizeMaximum"
                                required={true}
                                type="number"
                                min={0}
                                suffix="m²"
                                value={propertyCriteria.areaSizeMaximum}
                                description="Wie groß darf Deine gesuchte Fläche maximal sein?"
                                formErrors={FormValidationHandler.getFieldErrors(props.formData, 'areaSizeMaximum')}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-lg-6 mb-3">
                            <InputField
                                label="Nebenfläche"
                                name="secondaryAreaSize"
                                required={false}
                                type="number"
                                min={0}
                                suffix="m²"
                                value={propertyCriteria.secondaryAreaSize ?? undefined}
                                description="Wie viel Nebenfläche (z.B. Toiletten, Lagerräume, Kühlhäuser etc) benötigst Du?"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-lg-6 mb-3">
                            <PropertyAcquisitionTypeSelectField
                                name="propertyAcquisitionTypes"
                                label="Angebotsart"
                                description="Gib hier an, ob Du Flächen zur Miete, zum Kauf oder beides suchst?"
                                placeholder="Bitte wählen"
                                required={true}
                                isClearable={true}
                                closeMenuOnSelect={false}
                                value={propertyCriteria.propertyAcquisitionTypes}
                                onChange={handlePropertyAcquisitionTypesChange}
                                formErrors={FormValidationHandler.getFieldErrors(props.formData, 'propertyAcquisitionTypes')}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-6 mb-3">
                            <SwitchField
                                label="Außenverkaufsfläche benötigt"
                                description="Benötigst Du Außenbereiche für Verkauf oder Werbemaßnahmen?"
                                selected={propertyCriteria.outdoorSalesAreaRequired}
                                name="outdoorSalesAreaRequired"
                                onChange={handleSwitchChange}
                            />
                        </div>
                        <div className="col-lg-6 mb-3">
                            {propertyCriteria.outdoorSalesAreaRequired === true && (
                                <InputField
                                    label="Außenverkaufsfläche"
                                    name="outdoorSalesAreaSize"
                                    required={false}
                                    type="number"
                                    min={0}
                                    suffix="m²"
                                    value={propertyCriteria.outdoorSalesAreaSize ?? undefined}
                                    description="Hier kannst Du eingeben, wie groß die Außenbereiche sein sollten."
                                    onChange={handleChange}
                                />
                            )}
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-6 mb-3">
                            <InputField
                                label="Ladenbreite"
                                name="storeWidth"
                                required={false}
                                type="number"
                                min={0}
                                suffix="m"
                                value={propertyCriteria.storeWidth ?? undefined}
                                description="Wie breit sollte die Ladenfront von potenziellen Flächen für Dich/Dein Geschäft sein?"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-lg-6 mb-3">
                            <InputField
                                label="Schaufensterbreite"
                                name="shopWindowFrontLength"
                                required={false}
                                type="number"
                                min={0}
                                suffix="m"
                                value={propertyCriteria.shopWindowFrontLength ?? undefined}
                                description="Wie breit sollte die Schaufensterfront von potenziellen Flächen sein? [in Meter]."
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-xl-6 mb-3">
                            <SwitchField
                                label="Ebenerdige Verkaufsfläche benötigt"
                                description="Wie dringend benötigst Du eine ebenerdige Verkaufsfläche? Gibt es auf der Verkaufsfläche keinerlei Höhenunterschiede (Rampen, Treppen, Stufe,…), gilt sie als ebenerdig. Ist die Verkaufsfläche über mehrere Etagen aufgeteilt und über einen Aufzug verbunden, gilt sie dennoch als ebenerdig."
                                selected={propertyCriteria.groundLevelSalesAreaRequired}
                                name="groundLevelSalesAreaRequired"
                                onChange={handleSwitchChange}
                            />
                        </div>
                        <div className="col-xl-6 mb-3">
                            <SwitchField
                                label="Barrierefreier Zugang benötigt"
                                description="Wie wichtig ist ein barrierefreier Zugang für Dein Gesuch? Eine Ladenfläche ist barrierefrei, wenn sie ohne Stufe oder sonstigen Erhöhungen zugänglich ist. Eine nachträgliche Barrierefreiheit kann unter Umständen durch z.B. den Einbau einer Rampe gewährleistet werden."
                                selected={propertyCriteria.barrierFreeAccessRequired}
                                name="barrierFreeAccessRequired"
                                onChange={handleSwitchChange}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 mb-3">
                            <ParkingLotRequirementTypeSelectField
                                label="Parkplätze am Objekt"
                                name="parkingLotRequirementType"
                                title="Wie wichtig sind Dir Parkplätze direkt an Deiner potenziellen Fläche?"
                                required={true}
                                hasErrors={FormValidationHandler.hasFieldError(props.formData, 'parkingLotRequirementType')}
                                defaultValue={propertyCriteria.parkingLotRequirementType}
                                onChange={handleParkingLotRequirementTypeChange}
                            />
                        </div>
                        <div className="col-12 mb-3">
                            <TextAreaField
                                name="otherRequirements"
                                label="sonstige Anforderungen"
                                rows={3}
                                value={propertyCriteria.otherRequirements ?? undefined}
                                id="otherRequirements"
                                description="Welche Anforderungen sollte die Fläche noch erfüllen (z.B. Deckenhöhe, besondere Traglast, Internetanschluss, Starkstromanschluss, Lüftungsanlage, etc.)."
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mb-3">
                    <div className="mb-3">
                        <LocationFactorSelectField
                            fieldType={FieldType.CHECKBOX}
                            label="Mikrolage"
                            title="Welche der Faktoren - die Mikrolage betreffend - sollten am neuen Standort vorherrschen."
                            name="locationFactors"
                            isMulti={true}
                            isClearable={true}
                            required={false}
                            defaultValue={propertyCriteria.locationFactors}
                            onChange={handleLocationFactorsChange}
                            hasErrors={FormValidationHandler.hasFieldError(props.formData, 'locationFactors')}
                        />
                    </div>
                    <InputField
                        label="Gewünschtes Handelsumfeld"
                        name="desiredRetailEnvironment"
                        required={false}
                        type="text"
                        value={propertyCriteria.desiredRetailEnvironment ?? undefined}
                        description="Gibt es Anforderungen an das Umfeld, in welchem Du ansiedeln möchtest? Bspw. andere Geschäfte, Parks, Büros oder ähnliches."
                        onChange={handleChange}
                    />
                </div>
            </div>
        </>
    );
};

export default PropertyCriteriaForm;
