import ContactService from './ContactService';
import Alert from '../Entity/Alert/Alert';
import AlertType from '../Entity/Alert/AlertType';
import Contact from '../Entity/Contact/Contact';
import FormData from '../Entity/Form/FormData';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import EmailValidationDefinition from '../FormValidationHandler/EmailValidationDefinition';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import ContactForm from './ContactForm';
import Spinner from '../../../components/Spinner';
import AlertBox from '../../../components/AlertBox';
import React, {useEffect, useState} from 'react';

interface EditContactProps {
    readonly contactId: number;
    readonly onContactFetched?: (contact: Contact) => void;
    readonly onContactUpdated?: (contact: Contact) => void;
    readonly onCloseButtonClick?: () => void;
}

const successAlert: Alert = new Alert(AlertType.Success, 'Der Ansprechpartner wurde erfolgreich aktualisiert.');

const errorAlert: Alert = new Alert(AlertType.Error, 'Fehler beim Aktualisieren des Ansprechpartners.');

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Du hast nicht alle Pflichtfelder gefüllt. Bitte kontrolliere die mit einem roten Ausrufezeichen markierten Felder.');

const fieldValidationDefinitions: FieldValidationDefinition<Contact>[] = [
    new RequiredValidationDefinition<Contact>('name', 'Name muss ausgefüllt sein.'),
    new RequiredValidationDefinition<Contact>('firstName', 'Vorname muss ausgefüllt sein.'),
    new RequiredValidationDefinition<Contact>('email', 'E-Mail muss ausgefüllt sein.'),
    new EmailValidationDefinition<Contact>('email', 'E-Mail-Format ist nicht korrekt.'),
];

const formValidationHandler: FormValidationHandler<Contact> = new FormValidationHandler<Contact>(fieldValidationDefinitions);

const contactService: ContactService = new ContactService(process.env.REACT_APP_LLASM_API_URL!);

const ContactEdit = (props: EditContactProps): React.JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [contact, setContact] = useState<Contact>();

    const [formData, setFormData] = useState<FormData<Contact>>({data: contact!});

    const [alert, setAlert] = useState<Alert>();

    useEffect((): void => {
        fetchContact();
    }, []);

    const fetchContact = async (): Promise<void> => {
        try {
            setIsLoading(true);

            const contact: Contact = await contactService.fetchContactFromApiById(props.contactId);

            setContact(contact);
            setFormData({...formData.data, data: contact});

            setIsLoading(false);

            if (props.onContactFetched !== undefined) {
                props.onContactFetched(contact);
            }
        } catch (error) {
            setAlert(errorAlert);

            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    const updateContact = async (): Promise<void> => {
        if (contact === undefined) {
            return;
        }

        formValidationHandler.validate(formData);

        if (formValidationHandler.hasErrors(formData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        setAlert(undefined);

        setIsLoading(true);

        try {
            setAlert(undefined);

            await contactService.updateContact(formData.data);

            setAlert(successAlert);

            const contact: Contact = await contactService.fetchContactFromApiById(formData.data.id!);

            setContact(contact);
            setFormData({...formData.data, data: contact});

            if (props.onContactUpdated !== undefined) {
                props.onContactUpdated(contact);
            }
        } catch (error) {
            setAlert(errorAlert);

            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading === true) {
        return <Spinner />;
    }

    return (
        <>
            {alert !== undefined &&
                <AlertBox alert={alert} autoDismiss={false} />
            }

            <ContactForm formData={formData} setFormData={setFormData} formValidationHandler={formValidationHandler} />

            <button type="submit" className="btn btn-primary d-flex align-items-center mt-3" onClick={updateContact}>Speichern</button>

            {props.onCloseButtonClick !== undefined &&
                <button className="btn align-items-center mt-3" onClick={props.onCloseButtonClick}>
                    SCHLIESSEN
                </button>
            }
        </>
    );
};

export default ContactEdit;
