import RentalDetailResponse from '../../../../../api/Llasm/Property/OfferDetail/RentalDetailResponse';

class RentalDetail {
    private _netRentalPrice: number | null = null;

    private _commissionAmount: string | null = null;

    private _commissionNote: string | null = null;

    private _deposit: string | null = null;

    public static createFromRentalDetailResponse(rentalDetailResponse: RentalDetailResponse): RentalDetail {
        const rentalDetail: RentalDetail = new this();

        rentalDetail._netRentalPrice = rentalDetailResponse.netRentalPrice;
        rentalDetail._commissionAmount = rentalDetailResponse.commissionAmount;
        rentalDetail._commissionNote = rentalDetailResponse.commissionNote;
        rentalDetail._deposit = rentalDetailResponse.deposit;

        return rentalDetail;
    }

    get netRentalPrice(): number | null {
        return this._netRentalPrice;
    }

    set netRentalPrice(value: number | null) {
        this._netRentalPrice = value;
    }

    get commissionAmount(): string | null {
        return this._commissionAmount;
    }

    set commissionAmount(value: string | null) {
        this._commissionAmount = value;
    }

    get commissionNote(): string | null {
        return this._commissionNote;
    }

    set commissionNote(value: string | null) {
        this._commissionNote = value;
    }

    get deposit(): string | null {
        return this._deposit;
    }

    set deposit(value: string | null) {
        this._deposit = value;
    }
}

export default RentalDetail;
