import LocationType, {getLocationTypeLabel} from '../Entity/LocationType';
import LocationCategory, {getLocationCategoryLabel} from '../Entity/LocationCategory';
import FormData from '../Entity/Form/FormData';
import SelectOption from '../Entity/Form/SelectOption';
import CheckboxWithRelatedSelectField from '../Component/Form/Field/CheckboxWithRelatedSelectField';
import CheckboxField from '../Component/Form/Field/CheckboxField';
import Tooltip from '../Component/Tooltip/Tooltip';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import React, {useEffect, useState} from 'react';


interface LocationTypeToLocationCategoriesMapping {
    locationType: LocationType;
    locationCategories: LocationCategory[];
}

interface LocationTypeAndLocationCategoryProps {
    readonly formData: FormData<any>;
    readonly setFormData: (formData: FormData<any>) => void;
    readonly setHasLocationCategoryErrors: (hasLocationCategoryErrors: boolean) => void;
    readonly formValidationHandler?: FormValidationHandler<any>;
}

const locationTypeToLocationCategoriesMappings: LocationTypeToLocationCategoriesMapping[] = [
    {
        locationType: LocationType.DowntownLocation,
        locationCategories: [
            LocationCategory.OneALocation,
            LocationCategory.OneBLocation,
            LocationCategory.OneCLocation,
        ]
    },
    {
        locationType: LocationType.CityDistrictLocation,
        locationCategories: [
            LocationCategory.TwoALocation,
            LocationCategory.TwoBLocation,
            LocationCategory.TwoCLocation,
        ]
    },
    {
        locationType: LocationType.HighFrequencySpecialLocation,
        locationCategories: [
            LocationCategory.TrainStation,
            LocationCategory.ShoppingCenter,
            LocationCategory.RetailPark,
            LocationCategory.Airport,
            LocationCategory.OutletCenter,
            LocationCategory.HighwayRestStop,
        ]
    },
    {
        locationType: LocationType.PeripheralLocation,
        locationCategories: [LocationCategory.Other]
    },
    {
        locationType: LocationType.ScatteredAndSolitaryLayers,
        locationCategories: [LocationCategory.Other]
    },
];

const locationTypeSelectOptions: SelectOption<LocationType>[] = [
    {label: getLocationTypeLabel(LocationType.DowntownLocation), value: LocationType.DowntownLocation},
    {label: getLocationTypeLabel(LocationType.CityDistrictLocation), value: LocationType.CityDistrictLocation},
    {label: getLocationTypeLabel(LocationType.HighFrequencySpecialLocation), value: LocationType.HighFrequencySpecialLocation},
    {label: getLocationTypeLabel(LocationType.PeripheralLocation), value: LocationType.PeripheralLocation},
    {label: getLocationTypeLabel(LocationType.ScatteredAndSolitaryLayers), value: LocationType.ScatteredAndSolitaryLayers},
];

const LocationTypeAndLocationCategory = (props: LocationTypeAndLocationCategoryProps): React.JSX.Element => {
    const [locationTypeSelectedValues, setLocationTypeSelectedValues] = useState<LocationType[]>();

    const [locationCategorySelectedValues, setLocationCategorySelectedValues] = useState<LocationCategory[]>();

    useEffect((): void => {
        setLocationTypeSelectedValues(props.formData.data.locationTypes);
        setLocationCategorySelectedValues(props.formData.data.locationCategories);
    }, []);

    useEffect((): void => {
        if (locationTypeSelectedValues === undefined) {
            return;
        }

        if (locationCategorySelectedValues === undefined) {
            return;
        }

        validateLocationCategories();
    }, [locationTypeSelectedValues, locationCategorySelectedValues]);

    const fetchLocationCategoriesFromLocationType = (locationType: LocationType): LocationCategory[] => {
        const locationTypeToLocationCategoriesMapping: LocationTypeToLocationCategoriesMapping | undefined = locationTypeToLocationCategoriesMappings.find((locationTypeToLocationCategoriesMapping: LocationTypeToLocationCategoriesMapping): boolean => {
            return locationTypeToLocationCategoriesMapping.locationType === locationType;
        });

        if (locationTypeToLocationCategoriesMapping === undefined) {
            throw new Error('locationTypeToLocationCategoriesMapping is undefined.');
        }

        return locationTypeToLocationCategoriesMapping.locationCategories;
    };

    const fetchLocationCategorySelectedOptionsFromLocationType = (locationType: LocationType): SelectOption<LocationCategory>[] => {
        if (locationCategorySelectedValues === undefined) {
            throw new Error('locationCategorySelectedValues is undefined.');
        }

        const locationCategories: LocationCategory[] = fetchLocationCategoriesFromLocationType(locationType);

        const selectedLocationCategories: LocationCategory[] = locationCategorySelectedValues.filter((selectedLocationCategory: LocationCategory): boolean => {
            return locationCategories.includes(selectedLocationCategory) === true;
        });

        return selectedLocationCategories.map((locationCategory: LocationCategory): SelectOption<LocationCategory> => {
            return {label: getLocationCategoryLabel(locationCategory), value: locationCategory};
        });
    };

    const fetchLocationCategorySelectOptionsFromLocationType = (locationType: LocationType): SelectOption<LocationCategory>[] => {
        const locationCategories: LocationCategory[] = fetchLocationCategoriesFromLocationType(locationType);

        return locationCategories.map((locationCategory: LocationCategory): SelectOption<LocationCategory> => {
            return {label: getLocationCategoryLabel(locationCategory), value: locationCategory};
        });
    };

    const locationTypeIsChecked = (locationType: LocationType): boolean => {
        if (locationTypeSelectedValues === undefined) {
            throw new Error('locationTypeSelectedValues is undefined');
        }

        return locationTypeSelectedValues.includes(locationType) === true;
    };

    const onLocationTypeChange = (locationType: LocationType): void => {
        if (locationTypeSelectedValues === undefined) {
            return;
        }

        if (locationCategorySelectedValues === undefined) {
            return;
        }

        if (locationTypeIsChecked(locationType) === true) {
            locationTypeSelectedValues.splice(locationTypeSelectedValues.indexOf(locationType), 1);

            if (hasMultipleLocationCategories(locationType) === false) {
                const locationCategory: LocationCategory = fetchLocationCategoriesFromLocationType(locationType)[0];

                const locationTypeSelectedValuesWithSameLocationCategory: LocationType | undefined = locationTypeSelectedValues.find((locationType: LocationType): boolean => {
                    return fetchLocationCategoriesFromLocationType(locationType).includes(locationCategory);
                });

                if (locationTypeSelectedValuesWithSameLocationCategory === undefined) {
                    locationCategorySelectedValues.splice(locationCategorySelectedValues.indexOf(locationCategory), 1);
                }
            } else {
                fetchLocationCategorySelectOptionsFromLocationType(locationType).forEach((selectOption: SelectOption<LocationCategory>): void => {
                    if (locationCategorySelectedValues.includes(selectOption.value) === true) {
                        locationCategorySelectedValues.splice(locationCategorySelectedValues.indexOf(selectOption.value), 1);
                    }
                });
            }
        } else {
            locationTypeSelectedValues.push(locationType);

            if (
                hasMultipleLocationCategories(locationType) === false
                && locationCategorySelectedValues.includes(fetchLocationCategoriesFromLocationType(locationType)[0]) === false
            ) {
                locationCategorySelectedValues.push(fetchLocationCategoriesFromLocationType(locationType)[0]);
            }
        }

        props.formData.data.locationTypes = locationTypeSelectedValues;
        updateFormData();
        validateField('locationTypes');
        validateLocationCategories();
    };

    const onLocationCategoryChange = (newValues: LocationCategory[] | LocationCategory | null, selectOptions: SelectOption<LocationCategory>[]): void => {
        if (locationCategorySelectedValues === undefined) {
            return;
        }

        selectOptions.forEach((selectOption: SelectOption<LocationCategory>): void => {
            if (locationCategorySelectedValues.includes(selectOption.value) === true) {
                locationCategorySelectedValues.splice(locationCategorySelectedValues.indexOf(selectOption.value), 1);
            }
        });

        if (newValues !== null) {
            if (Array.isArray(newValues)) {
                newValues.forEach((locationCategory: LocationCategory): void => {
                    locationCategorySelectedValues.push(locationCategory);
                });
            } else {
                locationCategorySelectedValues.push(newValues);
            }
        }

        props.formData.data.locationCategories = locationCategorySelectedValues;
        updateFormData();
        validateField('locationCategories');
        validateLocationCategories();
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: props.formData.data});
    };

    const hasMultipleLocationCategories = (locationType: LocationType): boolean => {
        const locationTypeToLocationCategoriesMapping: LocationTypeToLocationCategoriesMapping | undefined = locationTypeToLocationCategoriesMappings.find((locationTypeToLocationCategoriesMapping: LocationTypeToLocationCategoriesMapping): boolean => {
            return (
                locationTypeToLocationCategoriesMapping.locationType === locationType
                && locationTypeToLocationCategoriesMapping.locationCategories.length > 1
            );
        });

        return locationTypeToLocationCategoriesMapping !== undefined;
    };

    const hasLocationCategoryError = (locationType: LocationType): boolean => {
        if (locationCategorySelectedValues === undefined) {
            throw new Error('locationCategorySelectedValues is undefined.');
        }

        const selectedLocationCategory: LocationCategory | undefined = fetchLocationCategoriesFromLocationType(locationType).find((locationCategory: LocationCategory): boolean => {
            return locationCategorySelectedValues.includes(locationCategory) === true;
        });

        return selectedLocationCategory === undefined;
    };

    const validateLocationCategories = (): void => {
        if (locationTypeSelectedValues === undefined) {
            throw new Error('locationTypeSelectedValues is undefined');
        }

        let hasErrors: boolean = false;

        locationTypeSelectedValues.forEach((locationType: LocationType): void => {
            if (hasLocationCategoryError(locationType) === true) {
                hasErrors = true;
            }
        });

        props.setHasLocationCategoryErrors(hasErrors);
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    if (locationTypeSelectedValues === undefined || locationCategorySelectedValues === undefined) {
        return <></>;
    }

    return (
        <>
            <div className={`${FormValidationHandler.hasFieldError(props.formData, 'locationTypes') === true ? ' form-control is-invalid' : ''}`}>
                <div className="mb-2">
                    Standort-Typ und Lagekategorie<sup>*</sup>
                    <Tooltip
                        title="An welchen Standorttypen bzw. in welchen Lagekategorien suchst Du eine Fläche? Bitte spezifiziere je nach gewähltem Standorttyp ggf. die zugehörigen Lagekategorien. Eine Mehrfachauswahl ist möglich."
                        icon={<i className="bi bi-info-circle"></i>}
                        className="text-info ms-1"
                    />
                </div>
                <div className="text-muted mb-3">Bitte wähle mindestens einen Standort-Typ.</div>
            </div>
            <div className="row">
                {locationTypeSelectOptions.map((locationTypeSelectOption: SelectOption<LocationType>): React.JSX.Element => (
                    <div className="col-4 mb-3" key={locationTypeSelectOption.value}>
                        {hasMultipleLocationCategories(locationTypeSelectOption.value) === true ? (
                            <>
                                <CheckboxWithRelatedSelectField
                                    checked={locationTypeIsChecked(locationTypeSelectOption.value)}
                                    checkboxName={'locationType' + LocationType[locationTypeSelectOption.value]}
                                    checkboxLabel={locationTypeSelectOption.label}
                                    onCheckboxClick={(): void => {onLocationTypeChange(locationTypeSelectOption.value)}}
                                    selectFieldName={'locationCategories' + locationTypeSelectOption.value}
                                    selectFieldSelectedOptions={fetchLocationCategorySelectedOptionsFromLocationType(locationTypeSelectOption.value)}
                                    selectFieldSelectOptions={fetchLocationCategorySelectOptionsFromLocationType(locationTypeSelectOption.value)}
                                    isSelectFieldClearable={true}
                                    isSelectFieldMulti={true}
                                    hasErrors={hasLocationCategoryError(locationTypeSelectOption.value)}
                                    onSelectFieldChange={(newValues: LocationCategory[] | LocationCategory | null): void => {
                                        onLocationCategoryChange(newValues, fetchLocationCategorySelectOptionsFromLocationType(locationTypeSelectOption.value))
                                    }}
                                />
                                {locationTypeIsChecked(locationTypeSelectOption.value) === true && hasLocationCategoryError(locationTypeSelectOption.value) === true &&
                                    <div key={'input_error_locationCategories' + locationTypeSelectOption.value}
                                         className="invalid-feedback d-block">
                                        Bitte wählen Sie mindestens eine Lagekategorie aus.
                                    </div>
                                }
                            </>
                        ) : (
                            <CheckboxField checked={locationTypeIsChecked(locationTypeSelectOption.value)}
                                           name={'locationType' + LocationType[locationTypeSelectOption.value]}
                                           label={locationTypeSelectOption.label} onChange={(): void => {onLocationTypeChange(locationTypeSelectOption.value)}}
                            />
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default LocationTypeAndLocationCategory;
