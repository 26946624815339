import SelectOption from './Entity/Form/SelectOption';
import LocationCategory, {getLocationCategoryLabel} from './Entity/LocationCategory';
import FieldLabel from './Component/Form/Field/FieldLabel';
import React, {useEffect, useState} from 'react';
import Select, {OnChangeValue, PropsValue} from 'react-select';

interface LocationCategorySelectFieldProps {
    readonly label: string;
    readonly title: string;
    readonly name: string;
    readonly isMulti: boolean;
    readonly isClearable: boolean
    readonly required: boolean;
    readonly selectOptions?: SelectOption<LocationCategory>[];
    readonly classNames?: string;
    readonly value?: LocationCategory | LocationCategory[] | null;
    readonly defaultValue?: LocationCategory | LocationCategory[] | null;
    readonly onChange?: (newValue: LocationCategory | LocationCategory[] | null) => void;
    readonly hasErrors?: boolean;
}

const defaultSelectOptions: SelectOption<LocationCategory>[] = [
    {label: getLocationCategoryLabel(LocationCategory.OneALocation), value: LocationCategory.OneALocation},
    {label: getLocationCategoryLabel(LocationCategory.OneBLocation), value: LocationCategory.OneBLocation},
    {label: getLocationCategoryLabel(LocationCategory.OneCLocation), value: LocationCategory.OneCLocation},
    {label: getLocationCategoryLabel(LocationCategory.TwoALocation), value: LocationCategory.TwoALocation},
    {label: getLocationCategoryLabel(LocationCategory.TwoBLocation), value: LocationCategory.TwoBLocation},
    {label: getLocationCategoryLabel(LocationCategory.TwoCLocation), value: LocationCategory.TwoCLocation},
    {label: getLocationCategoryLabel(LocationCategory.Other), value: LocationCategory.Other},
];

const LocationCategorySelectField = (props: LocationCategorySelectFieldProps): React.JSX.Element => {
    const [selectOptions, setSelectOptions] = useState<SelectOption<LocationCategory>[]>(defaultSelectOptions);

    useEffect((): void => {
        if (props.selectOptions === undefined) {
            return;
        }

        setSelectOptions(props.selectOptions);
    }, [props.selectOptions]);

    const mapValueToSelectOption = (locationCategoryValue: LocationCategory | LocationCategory[] | null | undefined): PropsValue<SelectOption<LocationCategory>> | null | undefined => {
        if (locationCategoryValue === undefined) {
            return undefined;
        }

        if (locationCategoryValue === null) {
            return null;
        }

        const selectedOptions: SelectOption<LocationCategory>[] = selectOptions.filter((selectOption: SelectOption<LocationCategory>): boolean => {
            if (Array.isArray(locationCategoryValue) === true) {
                return (locationCategoryValue as LocationCategory[]).includes(selectOption.value);
            } else {
                return selectOption.value === locationCategoryValue;
            }
        });

        if (props.isMulti === true) {
            return selectedOptions;
        } else {
            if (selectedOptions.length > 0) {
                return selectedOptions[0];
            }
        }

        return undefined;
    };

    return (
        <>
            {/* TODO - umbau auf SelectField / MultiSelectField */}
            <FieldLabel label={props.label} htmlFor={props.name} required={props.required} description={props.title} className="pt-0 mb-2 ps-2" />
            {/* TODO - closeMenuOnSelect={!props.isMulti} ist ein side-effect - das muss gefixt werden */}
            <Select
                name={props.name}
                options={selectOptions}
                value={mapValueToSelectOption(props.value)}
                defaultValue={mapValueToSelectOption(props.defaultValue)}
                isClearable={props.isClearable}
                isMulti={props.isMulti}
                closeMenuOnSelect={!props.isMulti}
                className={`inset-shadow ${props.classNames !== undefined ? props.classNames : '' + props.hasErrors !== undefined && props.hasErrors === true ? ' is-invalid form-control' : ''}`}
                placeholder="Bitte wählen"
                onChange={(newValue: OnChangeValue<SelectOption<LocationCategory>, boolean>): void => {
                    if (props.onChange !== undefined) {
                        if (Array.isArray(newValue) === true) {
                            props.onChange((newValue as Array<SelectOption<LocationCategory>>).map((selectOption: SelectOption<LocationCategory>): LocationCategory => selectOption.value));
                        } else {
                            props.onChange((newValue as SelectOption<LocationCategory>).value);
                        }
                    }
                }}
            />
        </>
    );
};

export default LocationCategorySelectField;
