import React, {useEffect, useRef} from 'react';
import {Tooltip as BootstrapTooltip} from 'bootstrap';

interface TooltipProps extends React.PropsWithChildren {
    readonly title: string;
    readonly icon?: React.JSX.Element;
    readonly className?: string;
}

const Tooltip = (props: TooltipProps): React.JSX.Element => {
    const tooltipRef: React.MutableRefObject<HTMLAnchorElement | null> = useRef<HTMLAnchorElement | null>(null);

    useEffect((): void => {
        new BootstrapTooltip(tooltipRef.current!);
    }, []);

    return (
        <span
            ref={tooltipRef}
            style={{cursor: 'pointer'}}
            data-toggle="tooltip"
            data-placement="top"
            title={props.title}
            tabIndex={0}
            className={props.className}
        >
            {props.children !== undefined &&
                <>{props.children}</>
            }
            {(props.children === undefined && props.icon !== undefined) &&
                <span className="text-primary">{props.icon}</span>
            }
        </span>
    );
};

export default Tooltip;
