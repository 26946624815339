import PropertyInterestService from '../../PropertyInterest/PropertyInterestService';
import ProviderSeekerConversation from '../../Entity/Messaging/ProviderSeekerConversation';
import PropertyInterest from '../../Entity/PropertyInterest/PropertyInterest';
import PropertyInterestFeedback from '../../Entity/PropertyInterest/PropertyInterestFeedback';
import BootstrapIcon from '../../Component/Icon/BootstrapIcon';
import propertyInterestFeedbackButtonStyle from './PropertyInterestFeedbackButton.module.scss';
import React from 'react';

interface PropertyInterestFeedbackButtonProps {
    readonly providerSeekerConversation: ProviderSeekerConversation;
    readonly propertyInterest: PropertyInterest | null;
    readonly setPropertyInterest: (propertyInterest: PropertyInterest | null) => void;
    readonly onPropertyInterestCreated: (propertyInterest: PropertyInterest) => void;
}

const propertyInterestService: PropertyInterestService = new PropertyInterestService(process.env.REACT_APP_LLASM_API_URL!);

const PropertyInterestFeedbackButton = (props: PropertyInterestFeedbackButtonProps): React.JSX.Element => {
    const persistPropertyInterest = async (propertyInterestFeedback: PropertyInterestFeedback): Promise<void> => {
        try {
            const propertyInterest: PropertyInterest = new PropertyInterest();

            propertyInterest.propertyId = props.providerSeekerConversation.property.id!;
            propertyInterest.settlementConceptId = props.providerSeekerConversation.settlementConcept.id!;
            propertyInterest.propertyInterestFeedback = propertyInterestFeedback;
            propertyInterest.createdAt = new Date();

            props.setPropertyInterest(propertyInterest);

            props.onPropertyInterestCreated(propertyInterest);

            await propertyInterestService.persistPropertyInterest(propertyInterest);
        } catch (error) {
            throw error;
        }
    };

    return(
        <>
            {props.propertyInterest === null &&
                <>
                    <button className={[propertyInterestFeedbackButtonStyle.propertyInterestFeedbackButton, 'text-nowrap', 'me-2'].join(' ')} onClick={() => persistPropertyInterest(PropertyInterestFeedback.Interested)}>
                        <BootstrapIcon iconName="emoji-laughing-fill" color="primary" /> Ich habe Interesse!
                    </button>
                    <button className={[propertyInterestFeedbackButtonStyle.propertyInterestFeedbackButton, 'text-nowrap'].join(' ')} onClick={() => persistPropertyInterest(PropertyInterestFeedback.NotInterested)}>
                        <BootstrapIcon iconName="emoji-frown-fill" color="grey" /> Ich habe kein Interesse!
                    </button>
                </>
            }
            {props.propertyInterest !== null && props.propertyInterest.propertyInterestFeedback === PropertyInterestFeedback.Interested &&
                <button className={[propertyInterestFeedbackButtonStyle.propertyInterestFeedbackButton, 'text-nowrap'].join(' ')} onClick={() => persistPropertyInterest(PropertyInterestFeedback.NotInterested)}>
                    <BootstrapIcon iconName="emoji-frown-fill" color="grey" /> Ich habe doch kein Interesse!
                </button>
            }
            {props.propertyInterest !== null && props.propertyInterest.propertyInterestFeedback === PropertyInterestFeedback.NotInterested &&
                <button className={[propertyInterestFeedbackButtonStyle.propertyInterestFeedbackButton, 'text-nowrap'].join(' ')} onClick={() => persistPropertyInterest(PropertyInterestFeedback.Interested)}>
                    <BootstrapIcon iconName="emoji-laughing-fill" color="primary" /> Ich habe doch Interesse!
                </button>
            }
        </>
    );
};

export default PropertyInterestFeedbackButton;
