import SelectOption from './Entity/Form/SelectOption';
import LocationType, {getLocationTypeLabel} from './Entity/LocationType';
import FieldLabel from './Component/Form/Field/FieldLabel';
import React from 'react';
import Select, {OnChangeValue, PropsValue} from 'react-select';

interface LocationTypeSelectFieldProps {
    readonly label: string;
    readonly title: string;
    readonly name: string;
    readonly isMulti: boolean;
    readonly isClearable: boolean;
    readonly required: boolean;
    readonly classNames?: string;
    readonly value?: LocationType | LocationType[] | null;
    readonly defaultValue?: LocationType | LocationType[] | null;
    readonly onChange?: (newValue: LocationType | LocationType[] | null) => void;
    readonly hasErrors?: boolean;
}

const selectOptions: SelectOption<LocationType>[] = [
    {label: getLocationTypeLabel(LocationType.DowntownLocation), value: LocationType.DowntownLocation},
    {label: getLocationTypeLabel(LocationType.CityDistrictLocation), value: LocationType.CityDistrictLocation},
    {label: getLocationTypeLabel(LocationType.PeripheralLocation), value: LocationType.PeripheralLocation},
    {label: getLocationTypeLabel(LocationType.HighFrequencySpecialLocation), value: LocationType.HighFrequencySpecialLocation},
    {label: getLocationTypeLabel(LocationType.ScatteredAndSolitaryLayers), value: LocationType.ScatteredAndSolitaryLayers},
];

const LocationTypeSelectField = (props: LocationTypeSelectFieldProps): React.JSX.Element => {
    const mapValueToSelectOption = (locationTypeValue: LocationType | LocationType[] | null | undefined): PropsValue<SelectOption<LocationType>> | null | undefined => {
        if (locationTypeValue === undefined) {
            return undefined;
        }

        if (locationTypeValue === null) {
            return null;
        }

        const selectedOptions: SelectOption<LocationType>[] = selectOptions.filter((selectOption: SelectOption<LocationType>): boolean => {
            if (Array.isArray(locationTypeValue) === true) {
                return (locationTypeValue as LocationType[]).includes(selectOption.value);
            } else {
                return selectOption.value === locationTypeValue;
            }
        });

        if (props.isMulti === true) {
            return selectedOptions;
        } else {
            if (selectedOptions.length > 0) {
                return selectedOptions[0];
            }
        }

       return undefined;
    };

    return (
        <>
            <FieldLabel label={props.label} htmlFor={props.name} required={props.required} description={props.title} className="pt-0 mb-2 ps-2" />
            {/* TODO - closeMenuOnSelect={!props.isMulti} ist ein side-effect - das muss gefixt werden */}
            {/* TODO - umbau auf SelectField / MultiSelectField */}
            <Select
                name={props.name}
                options={selectOptions}
                value={mapValueToSelectOption(props.value)}
                defaultValue={mapValueToSelectOption(props.defaultValue)}
                isClearable={props.isClearable}
                isMulti={props.isMulti}
                closeMenuOnSelect={!props.isMulti}
                className={`inset-shadow ${props.classNames !== undefined ? props.classNames : '' + props.hasErrors !== undefined && props.hasErrors === true ? ' is-invalid form-control' : ''}`}
                placeholder="Bitte wählen"
                onChange={(newValue: OnChangeValue<SelectOption<LocationType>, boolean>): void => {
                    if (props.onChange !== undefined) {
                        if (Array.isArray(newValue) === true) {
                            props.onChange((newValue as Array<SelectOption<LocationType>>).map((selectOption: SelectOption<LocationType>): LocationType => selectOption.value));
                        } else {
                            props.onChange((newValue as SelectOption<LocationType>)?.value);
                        }
                    }
                }}
            />
        </>
    );
};

export default LocationTypeSelectField;
