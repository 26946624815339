import MarketingStatus from '../MarketingStatus';
import LocationDetail from './LocationDetail';
import Detail from './Detail';
import OfferDetail from './OfferDetail/OfferDetail';
import AdditionalDetail from './AdditionalDetail';
import EnergyCertificate from './EnergyCertificate/EnergyCertificate';
import PropertyAcquisitionType from '../PropertyAcquisitionType';
import AccessibilityType from '../AccessibilityType';
import MatchingParameter from '../Matching/MatchingParameter';
import MatchingTask from '../Matching/MatchingTask';
import ProviderSeekerConversation from '../Messaging/ProviderSeekerConversation';
import PropertyInterest from '../PropertyInterest/PropertyInterest';
import Contact from '../Contact/Contact';
import Image from '../Image/Image';
import Document from '../Document/Document';
import PropertyResponse from '../../../../api/Llasm/Property/PropertyResponse';
import ContactResponse from '../../../../api/Llasm/Contact/ContactResponse';
import ImageResponse from '../../../../api/Llasm/Image/ImageResponse';
import DocumentResponse from '../../../../api/Llasm/Document/DocumentResponse';

class Property {
    private _id?: number;

    private _active: boolean;

    private _marketingStatus: MarketingStatus;

    private _title: string;

    private _locationDetail: LocationDetail;

    private _detail: Detail;

    private _offerDetail: OfferDetail;

    private _additionalDetail: AdditionalDetail;

    private _energyCertificate: EnergyCertificate | null = null;

    // @deprecated
    private _floors: string[] = [];

    // @deprecated
    private _areaSize: number;

    // @deprecated
    private _secondaryAreaSize: number | null = null;

    // @deprecated
    private _outdoorSalesAreaSize: number | null = null;

    // @deprecated
    private _storeWidth: number | null = null;

    // @deprecated
    private _shopWindowFrontLength: number | null = null;

    // @deprecated
    private _accessibilityType: AccessibilityType;

    // @deprecated
    private _groundLevelSalesArea: boolean;

    // @deprecated
    private _parkingLotAvailable: boolean;

    // @deprecated
    private _usageDescription: string | null = null;

    // @deprecated
    private _propertyAcquisitionTypes: PropertyAcquisitionType[] = [];

    // @deprecated
    private _storeSpaceAvailableFrom: Date | null = null;

    // @deprecated
    private _purchasePrice: number | null = null;

    // @deprecated
    private _netRentalPrice: number | null = null;

    // @deprecated
    private _deposit: string | null = null;

    // @deprecated
    private _commissionAmount: string | null = null;

    // @deprecated
    private _commissionNote: string | null = null;

    private _matchingParameter: MatchingParameter | null = null;

    private _matchingTask: MatchingTask | null = null;

    private _providerSeekerConversation: ProviderSeekerConversation | null = null;

    private _propertyInterest: PropertyInterest | null = null;

    private _hasContactIntentions: boolean;

    private _contacts: Contact[] = [];

    private _images: Image[] = [];

    private _documents: Document[] = [];

    public static createFromPropertyResponse(propertyResponse: PropertyResponse): Property {
        const property: Property = new this();

        property._id = propertyResponse.id;
        property._active = propertyResponse.active;
        property._marketingStatus = propertyResponse.marketingStatus;
        property._title = propertyResponse.title;


        property._locationDetail = LocationDetail.createFromLocationDetailResponse(propertyResponse.locationDetail);
        property._detail = Detail.createFromDetailResponse(propertyResponse.detail);
        property._offerDetail = OfferDetail.createFromOfferDetailResponse(propertyResponse.offerDetail);
        property._additionalDetail = AdditionalDetail.createFromAdditionalDetailResponse(propertyResponse.additionalDetail);


        property._floors = propertyResponse.detail.floors;
        property._areaSize = propertyResponse.detail.areaSize;
        property._secondaryAreaSize = propertyResponse.detail.secondaryAreaSize;
        property._outdoorSalesAreaSize = propertyResponse.detail.outdoorSalesAreaSize;
        property._storeWidth = propertyResponse.detail.storeWidth;
        property._shopWindowFrontLength = propertyResponse.detail.shopWindowFrontLength;
        property._accessibilityType = propertyResponse.detail.accessibilityType;
        property._groundLevelSalesArea = propertyResponse.detail.groundLevelSalesArea;
        property._parkingLotAvailable = propertyResponse.detail.parkingLotAvailable;
        property._usageDescription = propertyResponse.detail.usageDescription;
        property._propertyAcquisitionTypes = propertyResponse.offerDetail.propertyAcquisitionTypes;

        if (propertyResponse.offerDetail.storeSpaceAvailableFrom !== null) {
            property._storeSpaceAvailableFrom = new Date(propertyResponse.offerDetail.storeSpaceAvailableFrom);
        }

        property._commissionAmount = propertyResponse.offerDetail.commissionAmount;
        property._commissionNote = propertyResponse.offerDetail.commissionNote;
        property._purchasePrice = propertyResponse.offerDetail.purchaseDetail.purchasePrice;
        property._netRentalPrice = propertyResponse.offerDetail.rentalDetail.netRentalPrice;
        property._deposit = propertyResponse.offerDetail.rentalDetail.deposit;

        if (propertyResponse.matchingParameter !== null) {
            property._matchingParameter = MatchingParameter.createFromMatchingParameterResponse(propertyResponse.matchingParameter);
        }

        if (propertyResponse.matchingTask !== null) {
            property._matchingTask = MatchingTask.createFromMatchingTaskResponse(propertyResponse.matchingTask);
        }

        if (propertyResponse.providerSeekerConversation !== null) {
            property._providerSeekerConversation = ProviderSeekerConversation.createFromProviderSeekerConversationResponse(propertyResponse.providerSeekerConversation);
        }

        if (propertyResponse.propertyInterest !== null) {
            property._propertyInterest = PropertyInterest.createFromPropertyInterestResponse(propertyResponse.propertyInterest);
        }

        property._hasContactIntentions = propertyResponse.hasContactIntentions;

        property._contacts = propertyResponse.contacts.map((contactResponse: ContactResponse): Contact => {
            return Contact.createFromContactResponse(contactResponse);
        });

        property._images = propertyResponse.images.map((imageResponse: ImageResponse): Image => {
            return Image.createFromImageResponse(imageResponse);
        });

        property._documents = propertyResponse.documents.map((documentResponse: DocumentResponse): Document => {
            return Document.createFromDocumentResponse(documentResponse);
        });

        return property;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get active(): boolean {
        return this._active;
    }

    set active(value: boolean) {
        this._active = value;
    }

    get marketingStatus(): MarketingStatus {
        return this._marketingStatus;
    }

    set marketingStatus(value: MarketingStatus) {
        this._marketingStatus = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get locationDetail(): LocationDetail {
        return this._locationDetail;
    }

    set locationDetail(value: LocationDetail) {
        this._locationDetail = value;
    }

    get detail(): Detail {
        return this._detail;
    }

    set detail(value: Detail) {
        this._detail = value;
    }

    get offerDetail(): OfferDetail {
        return this._offerDetail;
    }

    set offerDetail(value: OfferDetail) {
        this._offerDetail = value;
    }

    get additionalDetail(): AdditionalDetail {
        return this._additionalDetail;
    }

    set additionalDetail(value: AdditionalDetail) {
        this._additionalDetail = value;
    }

    get energyCertificate(): EnergyCertificate | null {
        return this._energyCertificate;
    }

    set energyCertificate(value: EnergyCertificate | null) {
        this._energyCertificate = value;
    }

    // @deprecated
    get propertyAcquisitionTypes(): PropertyAcquisitionType[] {
        return this._propertyAcquisitionTypes;
    }

    // @deprecated
    set propertyAcquisitionTypes(value: PropertyAcquisitionType[]) {
        this._propertyAcquisitionTypes = value;
    }

    // @deprecated
    get purchasePrice(): number | null {
        return this._purchasePrice;
    }

    // @deprecated
    set purchasePrice(value: number | null) {
        this._purchasePrice = value;
    }

    // @deprecated
    get netRentalPrice(): number | null {
        return this._netRentalPrice;
    }

    // @deprecated
    set netRentalPrice(value: number | null) {
        this._netRentalPrice = value;
    }

    // @deprecated
    get commissionAmount(): string | null {
        return this._commissionAmount;
    }

    // @deprecated
    set commissionAmount(value: string | null) {
        this._commissionAmount = value;
    }

    // @deprecated
    get commissionNote(): string | null {
        return this._commissionNote;
    }

    // @deprecated
    set commissionNote(value: string | null) {
        this._commissionNote = value;
    }

    // @deprecated
    get deposit(): string | null {
        return this._deposit;
    }

    // @deprecated
    set deposit(value: string | null) {
        this._deposit = value;
    }

    // @deprecated
    get areaSize(): number {
        return this._areaSize;
    }

    // @deprecated
    set areaSize(value: number) {
        this._areaSize = value;
    }

    // @deprecated
    get secondaryAreaSize(): number | null {
        return this._secondaryAreaSize;
    }

    // @deprecated
    set secondaryAreaSize(value: number | null) {
        this._secondaryAreaSize = value;
    }

    // @deprecated
    get outdoorSalesAreaSize(): number | null {
        return this._outdoorSalesAreaSize;
    }

    // @deprecated
    set outdoorSalesAreaSize(value: number | null) {
        this._outdoorSalesAreaSize = value;
    }

    // @deprecated
    get storeWidth(): number | null {
        return this._storeWidth;
    }

    // @deprecated
    set storeWidth(value: number | null) {
        this._storeWidth = value;
    }

    // @deprecated
    get shopWindowFrontLength(): number | null {
        return this._shopWindowFrontLength;
    }

    // @deprecated
    set shopWindowFrontLength(value: number | null) {
        this._shopWindowFrontLength = value;
    }

    // @deprecated
    get storeSpaceAvailableFrom(): Date | null {
        return this._storeSpaceAvailableFrom;
    }

    // @deprecated
    set storeSpaceAvailableFrom(value: Date | null) {
        this._storeSpaceAvailableFrom = value;
    }

    // @deprecated
    get accessibilityType(): AccessibilityType {
        return this._accessibilityType;
    }

    // @deprecated
    set accessibilityType(value: AccessibilityType) {
        this._accessibilityType = value;
    }

    // @deprecated
    get groundLevelSalesArea(): boolean {
        return this._groundLevelSalesArea;
    }

    // @deprecated
    set groundLevelSalesArea(value: boolean) {
        this._groundLevelSalesArea = value;
    }

    // @deprecated
    get parkingLotAvailable(): boolean {
        return this._parkingLotAvailable;
    }

    // @deprecated
    set parkingLotAvailable(value: boolean) {
        this._parkingLotAvailable = value;
    }

    // @deprecated
    get usageDescription(): string | null {
        return this._usageDescription;
    }

    // @deprecated
    set usageDescription(value: string | null) {
        this._usageDescription = value;
    }

    // @deprecated
    get floors(): string[] {
        return this._floors;
    }

    // @deprecated
    set floors(value: string[]) {
        this._floors = value;
    }

    get matchingParameter(): MatchingParameter | null {
        return this._matchingParameter;
    }

    set matchingParameter(value: MatchingParameter | null) {
        this._matchingParameter = value;
    }

    get matchingTask(): MatchingTask | null {
        return this._matchingTask;
    }

    set matchingTask(value: MatchingTask | null) {
        this._matchingTask = value;
    }

    get providerSeekerConversation(): ProviderSeekerConversation | null {
        return this._providerSeekerConversation;
    }

    set providerSeekerConversation(value: ProviderSeekerConversation | null) {
        this._providerSeekerConversation = value;
    }

    get propertyInterest(): PropertyInterest | null {
        return this._propertyInterest;
    }

    set propertyInterest(value: PropertyInterest | null) {
        this._propertyInterest = value;
    }

    get hasContactIntentions(): boolean {
        return this._hasContactIntentions;
    }

    set hasContactIntentions(value: boolean) {
        this._hasContactIntentions = value;
    }

    get contacts(): Contact[] {
        return this._contacts;
    }

    set contacts(value: Contact[]) {
        this._contacts = value;
    }

    get images(): Image[] {
        return this._images;
    }

    set images(value: Image[]) {
        this._images = value;
    }

    get documents(): Document[] {
        return this._documents;
    }

    set documents(value: Document[]) {
        this._documents = value;
    }
}

export default Property;
