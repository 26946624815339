import MatchingService from '../MatchingService';
import SettlementConcept from '../../Entity/SettlementConcept/SettlementConcept';
import Property from '../../Entity/Property/Property';
import InformationScope from '../../Entity/InformationScope';
import MatchingItem from './MatchingItem';
import DropdownMenu from './DropdownMenu';
import PropertyDetailInformation from '../../Property/PropertyDetailInformation';
import SettlementConceptDetailInformation from '../../SettlementConcept/SettlementConceptDetailInformation';
import SettlementConceptLockedInformation from '../../SettlementConcept/SettlementConceptLockedInformation';
import Slider from '../../Component/Slider/Slider';
import Overlay from '../../Component/Overlay/Overlay';
import matchingOverviewStyle from './MatchingOverview.module.scss';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import clover from '../../../../img/logo-bildmarke.svg';
import React, {useEffect, useState} from 'react';

interface MatchingOverviewProps {
    readonly matchingService: MatchingService;
    readonly settlementConcepts: SettlementConcept[];
    readonly properties: Property[];
}

const minMediumDeviceWidth: number = 768;
let windowWidth: number = window.innerWidth;

const MatchingOverview = (props: MatchingOverviewProps): React.JSX.Element => {
    const [settlementConcepts, setSettlementConcepts] = useState<SettlementConcept[]>([]);

    const [properties, setProperties] = useState<Property[]>([]);

    const [selectedItem, setSelectedItem] = useState<SettlementConcept | Property | undefined>(undefined);

    const [matchingItems, setMatchingItems] = useState<SettlementConcept[] | Property[] | undefined>(undefined);

    const [showDetailInformationOverlay, setShowDetailInformationOverlay] = useState<boolean>(false);

    const [detailInformationItem, setDetailInformationItem] = useState<SettlementConcept | Property | undefined>();

    const [showArrows, setShowArrows] = useState<boolean>(true);

    useEffect((): void => {
        const showArrows: boolean = props.properties.length > 0 && window.innerWidth > minMediumDeviceWidth;

        setShowArrows(showArrows);

        window.addEventListener('resize', handleWindowResize);
    }, []);

    useEffect((): void => {
        if (selectedItem === undefined) {
            return;
        }

        setMatchingItems(undefined);

        fetchMatchingItems();
    }, [selectedItem]);

    useEffect((): void => {
        setSettlementConcepts(
            props.settlementConcepts.filter((settlementConcept: SettlementConcept): boolean => {
                return settlementConcept.active === true && settlementConcept.hasContactIntentions === true;
            })
        );
    }, [props.settlementConcepts]);

    useEffect((): void => {
        setProperties(
            props.properties.filter((property: Property): boolean => {
                return property.active === true && property.hasContactIntentions === true;
            })
        );
    }, [props.properties]);

    const fetchMatchingItems = async (): Promise<void> => {
        if (selectedItem instanceof SettlementConcept) {
            setMatchingItems(await props.matchingService.fetchMatchedPropertiesBySettlementConceptFromApi(selectedItem));
        }

        if (selectedItem instanceof Property) {
            setMatchingItems(await props.matchingService.fetchMatchedSettlementConceptsByPropertyFromApi(selectedItem));
        }
    };

    const handleWindowResize = (): void => {
        if (
            (window.innerWidth < minMediumDeviceWidth && windowWidth >= minMediumDeviceWidth)
            || (window.innerWidth >= minMediumDeviceWidth && windowWidth < minMediumDeviceWidth)
        ) {
            const showArrows: boolean = props.properties.length > 0 && window.innerWidth > minMediumDeviceWidth;

            setShowArrows(showArrows);
        }

        windowWidth = window.innerWidth;
    };

    return (
        <>
            <h2 className="text-primary">
                IT'S A MATCH
                <img src={clover} className={['ms-3', matchingOverviewStyle.matchIcon].join(' ')} alt="Match-Icon" />
            </h2>

            <div className="mw-100">
                <div className={matchingOverviewStyle.divider}></div>
                <DropdownMenu
                    settlementConcepts={settlementConcepts}
                    properties={properties}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                />
            </div>

            {(selectedItem !== undefined && matchingItems === undefined) &&
                <div className="mt-4">
                    <LoadingIndicator />
                </div>
            }

            {(selectedItem !== undefined && matchingItems !== undefined && matchingItems.length === 0) &&
                <div className="mt-4 alert alert-warning text-center">
                    {(selectedItem instanceof SettlementConcept) &&
                        <p className="mb-0">Gerade gibt es keine Objektanbieter, die Dein Suchprofil interessant finden.</p>
                    }
                    {(selectedItem instanceof Property) &&
                        <p className="mb-0">Gerade gibt es keine Anbieter, deren Suchprofil mit Deinem Objekt matcht und das Dir gefällt.</p>
                    }
                </div>
            }

            {(selectedItem !== undefined && matchingItems !== undefined && matchingItems.length > 0) &&
                <Slider
                    config={{
                        type: 'slider',
                        showArrows: showArrows,
                        arrowsPosition: 'outside',
                        perView: 3,
                        breakpoints: {
                            1999: {
                                perView: 2
                            },
                            1199: {
                                perView: 1
                            },
                        }
                    }}
                >
                    {matchingItems.map((matchingItem: SettlementConcept | Property, index: number): React.JSX.Element => (
                        <MatchingItem
                            key={'matchingItem-' + index}
                            selectedItem={selectedItem}
                            matchingItem={matchingItem}
                            setShowDetailInformationOverlay={setShowDetailInformationOverlay}
                            setDetailInformationItem={setDetailInformationItem}
                        />
                    ))}
                </Slider>
            }

            {detailInformationItem !== undefined &&
                <>
                    {detailInformationItem instanceof SettlementConcept &&
                        <Overlay title={detailInformationItem.reference + ' ' + detailInformationItem.title} show={showDetailInformationOverlay} setShow={setShowDetailInformationOverlay}>
                            <div className="container">
                                {(detailInformationItem.informationScope === InformationScope.Complete || detailInformationItem.informationScope === InformationScope.Unlocked) ? (
                                    <SettlementConceptDetailInformation settlementConcept={detailInformationItem} />
                                ) : (
                                    <SettlementConceptLockedInformation settlementConcept={detailInformationItem} />
                                )}
                            </div>
                        </Overlay>
                    }

                    {detailInformationItem instanceof Property && selectedItem instanceof SettlementConcept &&
                        <Overlay title={'#' +  detailInformationItem.id + ' ' + detailInformationItem.title} show={showDetailInformationOverlay} setShow={setShowDetailInformationOverlay}>
                            <div className="container">
                                <PropertyDetailInformation property={detailInformationItem} settlementConcept={selectedItem} />
                            </div>
                        </Overlay>
                    }
                </>
            }

            {(detailInformationItem === undefined && selectedItem === undefined) &&
                <div className={['mt-4', 'text-center', 'alert', matchingOverviewStyle.informationBox].join(' ')}>
                    Bitte wähle eine Deiner Flächen/Ladenlokale oder Suchprofile aus, um zu sehen, mit was es matcht.
                </div>
            }
        </>
    );
};

export default MatchingOverview;
