import AccessibilityType from '../AccessibilityType';
import DetailResponse from '../../../../api/Llasm/Property/DetailResponse';

class Detail {
    private _floors: string[] = [];

    private _areaSize: number;

    private _secondaryAreaSize: number | null = null;

    private _outdoorSalesAreaSize: number | null = null;

    private _storeWidth: number | null = null;

    private _shopWindowFrontLength: number | null = null;

    private _accessibilityType: AccessibilityType;

    private _groundLevelSalesArea: boolean;

    private _parkingLotAvailable: boolean;

    private _objectDescription: string | null = null;   // TODO - WiP

    public static createFromDetailResponse(detailResponse: DetailResponse): Detail {
        const detail: Detail = new this();

        detail._floors = detailResponse.floors;
        detail._areaSize = detailResponse.areaSize;
        detail._secondaryAreaSize = detailResponse.secondaryAreaSize;
        detail._outdoorSalesAreaSize = detailResponse.outdoorSalesAreaSize;
        detail._storeWidth = detailResponse.storeWidth;
        detail._shopWindowFrontLength = detailResponse.shopWindowFrontLength;
        detail._accessibilityType = detailResponse.accessibilityType;
        detail._groundLevelSalesArea = detailResponse.groundLevelSalesArea;
        detail._parkingLotAvailable = detailResponse.parkingLotAvailable;
        detail._objectDescription = detailResponse.usageDescription;   // TODO - WiP

        return detail;
    }

    get floors(): string[] {
        return this._floors;
    }

    set floors(value: string[]) {
        this._floors = value;
    }

    get areaSize(): number {
        return this._areaSize;
    }

    set areaSize(value: number) {
        this._areaSize = value;
    }

    get secondaryAreaSize(): number | null {
        return this._secondaryAreaSize;
    }

    set secondaryAreaSize(value: number | null) {
        this._secondaryAreaSize = value;
    }

    get outdoorSalesAreaSize(): number | null {
        return this._outdoorSalesAreaSize;
    }

    set outdoorSalesAreaSize(value: number | null) {
        this._outdoorSalesAreaSize = value;
    }

    get storeWidth(): number | null {
        return this._storeWidth;
    }

    set storeWidth(value: number | null) {
        this._storeWidth = value;
    }

    get shopWindowFrontLength(): number | null {
        return this._shopWindowFrontLength;
    }

    set shopWindowFrontLength(value: number | null) {
        this._shopWindowFrontLength = value;
    }

    get accessibilityType(): AccessibilityType {
        return this._accessibilityType;
    }

    set accessibilityType(value: AccessibilityType) {
        this._accessibilityType = value;
    }

    get groundLevelSalesArea(): boolean {
        return this._groundLevelSalesArea;
    }

    set groundLevelSalesArea(value: boolean) {
        this._groundLevelSalesArea = value;
    }

    get parkingLotAvailable(): boolean {
        return this._parkingLotAvailable;
    }

    set parkingLotAvailable(value: boolean) {
        this._parkingLotAvailable = value;
    }

    get objectDescription(): string | null {   // TODO - WiP
        return this._objectDescription;
    }

    set objectDescription(value: string | null) {   // TODO - WiP
        this._objectDescription = value;
    }
}

export default Detail;
