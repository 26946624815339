import SettlementConceptService from './SettlementConceptService';
import IndustryClassificationService from '../IndustryClassification/IndustryClassificationService';
import LocationService from '../Location/LocationService';
import AuthenticationState from '../Entity/Authentication/AuthenticationState';
import Alert from '../Entity/Alert/Alert';
import AlertType from '../Entity/Alert/AlertType';
import Image from '../Entity/Image/Image';
import Document from '../Entity/Document/Document';
import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import IndustryClassification from '../Entity/IndustryClassification/IndustryClassification';
import FormData from '../Entity/Form/FormData';
import PlaceCriteria from '../Entity/SettlementConcept/PlaceCriteria';
import PropertyCriteria from '../Entity/SettlementConcept/PropertyCriteria';
import Country from '../Entity/Location/Country';
import Place from '../Entity/Location/Place';
import DocumentUploader from '../Document/DocumentUploader';
import SettlementConceptForm from './SettlementConceptForm';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import MinMaxLengthValidationDefinition from '../FormValidationHandler/MinMaxLengthValidationDefinition';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import ImageUploader from '../Image/ImageUploader';
import InfoButtonOverlay from '../Component/InfoButtonOverlay/InfoButtonOverlay';
import CollapseCard from '../Component/CollapseCard/CollapseCard';
import AlertBox from '../../../components/AlertBox';
import Spinner from '../../../components/Spinner';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams, useNavigate, NavigateFunction} from 'react-router-dom';

const settlementConceptService: SettlementConceptService = new SettlementConceptService(process.env.REACT_APP_LLASM_API_URL!);

const industryClassificationService: IndustryClassificationService = new IndustryClassificationService(process.env.REACT_APP_LLASM_API_URL!);

const locationService: LocationService = new LocationService(process.env.REACT_APP_LLASM_API_URL!);

const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.');

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Du hast nicht alle Pflichtfelder gefüllt. Bitte kontrolliere die mit einem roten Ausrufezeichen markierten Felder.');

const successAlert: Alert = new Alert(AlertType.Success, 'Das Suchprofil wurde erfolgreich aktualisiert.');

const fieldValidationDefinitions: FieldValidationDefinition<SettlementConcept>[] = [
    new RequiredValidationDefinition<SettlementConcept>('brand', 'Es muss eine Marke angegeben werden.'),
    new RequiredValidationDefinition<SettlementConcept>('title', 'Es muss eine Bezeichnung angegeben werden.'),
    new RequiredValidationDefinition<SettlementConcept>('description', 'Es muss eine Beschreibung angegeben werden.'),
    new RequiredValidationDefinition<SettlementConcept>('contacts', 'Es muss mindestens ein Ansprechpartner angegeben werden.'),
];

const formValidationHandler: FormValidationHandler<SettlementConcept> = new FormValidationHandler<SettlementConcept>(fieldValidationDefinitions);

const propertyCriteriaFieldValidationDefinitions: FieldValidationDefinition<PropertyCriteria>[] = [
    new RequiredValidationDefinition<PropertyCriteria>('locationTypes', 'Standort-Typen müssen ausgewählt sein.'),
    new RequiredValidationDefinition<PropertyCriteria>('locationCategories', 'Lagekategorien müssen ausgewählt sein.'),
    new RequiredValidationDefinition<PropertyCriteria>('areaSizeMinimum', 'Es muss die Gesamtfläche von angegeben werden.'),
    new RequiredValidationDefinition<PropertyCriteria>('areaSizeMaximum', 'Es muss die Gesamtfläche bis angegeben werden.'),
    new RequiredValidationDefinition<PropertyCriteria>('propertyAcquisitionTypes', 'Eine Angebotsart muss ausgewählt sein.'),
    new RequiredValidationDefinition<PropertyCriteria>('parkingLotRequirementType', 'Parkplätze am Objekt müssen ausgewählt sein.'),
    new RequiredValidationDefinition<PropertyCriteria>('locationFactors', 'Standort-Faktoren müssen ausgewählt sein.'),
    new MinMaxLengthValidationDefinition<PropertyCriteria>('locationFactors', 2, 5, 'Es müssen 2 bis 5 Standort-Faktoren ausgewählt sein.'),
];

const propertyCriteriaFormValidationHandler: FormValidationHandler<PropertyCriteria> = new FormValidationHandler<PropertyCriteria>(propertyCriteriaFieldValidationDefinitions);

const SettlementConceptPage = (): React.JSX.Element => {
    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const {settlementConceptId} = useParams<string>();

    const [settlementConcept, setSettlementConcept] = useState<SettlementConcept>();

    const [countryPlaces, setCountryPlaces] = useState<Place[]>();

    const [industryClassifications, setIndustryClassifications] = useState<IndustryClassification[]>();

    const [formData, setFormData] = useState<FormData<SettlementConcept>>({data: settlementConcept!});

    const [placeCriteriaFormDatas, setPlaceCriteriaFormDatas] = useState<FormData<PlaceCriteria>[]>([]);

    const [propertyCriteriaFormData, setPropertyCriteriaFormData] = useState<FormData<PropertyCriteria>>({data: new PropertyCriteria()});

    const [hasLocationCategoryErrors, setHasLocationCategoryErrors] = useState<boolean>(false);

    const [images, setImages] = useState<Image[]>([]);

    const [documents, setDocuments] = useState<Document[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isProcessingImages, setIsProcessingImages] = useState<boolean>(false);

    const [isProcessingDocuments, setIsProcessingDocuments] = useState<boolean>(false);

    const [alert, setAlert] = useState<Alert>();

    const [numberOfImageUploads, setNumberOfImageUploads] = useState<number>(0);

    const imageUploaderRef: React.Ref<any> = useRef<React.ForwardedRef<any>>();

    const [numberOfDocumentUploads, setNumberOfDocumentUploads] = useState<number>(0);

    const documentUploaderRef: React.Ref<any> = useRef<React.ForwardedRef<any>>();

    const dispatch = useAppDispatch();

    const navigate: NavigateFunction = useNavigate();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Meine Suchprofile', link: '/suchprofile'},
                {name: 'Suchprofil bearbeiten'},
            ]
        });
    }, [dispatch]);

    useEffect((): void => {
        fetchData();
        fetchCountryPlaces();
    }, []);

    useEffect((): void => {
        if (settlementConcept === undefined) {
            return;
        }

        if (settlementConcept.owner === false) {
            navigate('/suchprofile');
        }
    }, [settlementConcept]);

    useEffect((): void => {
        if (settlementConcept === undefined) {
            return;
        }

        settlementConcept.placeCriterias = [];

        placeCriteriaFormDatas.forEach((placeCriteriaFormData: FormData<PlaceCriteria>): void => {
            settlementConcept.placeCriterias.push(placeCriteriaFormData.data);
        });

        setFormData({...formData, data: settlementConcept});
    }, [placeCriteriaFormDatas]);

    const fetchData = async (): Promise<void> => {
        // TODO implement error handling for 404 (This needs to be done everywhere else aswell.)
        const settlementConcept: SettlementConcept = await settlementConceptService.fetchSettlementConceptFromApiById(Number(settlementConceptId));

        const industryClassifications: IndustryClassification[] = await industryClassificationService.fetchIndustryClassificationsFromApi();

        setIndustryClassifications(industryClassifications);
        setSettlementConcept(settlementConcept);
        setImages(settlementConcept.images);
        setDocuments(settlementConcept.documents);

        const placeCriteriaFormDatas: FormData<PlaceCriteria>[] = [];

        settlementConcept.placeCriterias.forEach((placeCriteria: PlaceCriteria): void => {
            placeCriteriaFormDatas.push({data: placeCriteria});
        });

        setPlaceCriteriaFormDatas(placeCriteriaFormDatas);
        setPropertyCriteriaFormData({...propertyCriteriaFormData, data: settlementConcept.propertyCriteria});
        setFormData({...formData, data: settlementConcept});
    };

    const fetchImages = async (): Promise<void> => {
        if (settlementConcept === undefined) {
            return ;
        }

        setIsProcessingImages(true);

        settlementConcept.images = await settlementConceptService.fetchImagesFromSettlementConcept(settlementConcept);

        setIsProcessingImages(false);
        setImages(settlementConcept.images);
    };

    const fetchDocuments = async (): Promise<void> => {
        if (settlementConcept === undefined) {
            return ;
        }

        setIsProcessingDocuments(true);

        settlementConcept.documents = await settlementConceptService.fetchDocumentsFromSettlementConcept(settlementConcept);

        setIsProcessingDocuments(false);
        setDocuments(settlementConcept.documents);
    };

    const deleteImage = async (image: Image): Promise<void> => {
        if (settlementConcept === undefined) {
            return ;
        }

        setIsProcessingImages(true);

        await settlementConceptService.deleteImageFromSettlementConcept(settlementConcept, image);

        await fetchImages();

        setIsProcessingImages(false);
    };

    const updateSettlementConcept = async (): Promise<void> => {
        if (settlementConcept === undefined) {
            return;
        }

        propertyCriteriaFormValidationHandler.validate(propertyCriteriaFormData);

        formValidationHandler.validate(formData);

        placeCriteriaFormDatas.forEach((placeCriteriaFormData: FormData<PlaceCriteria>): void => {
            if (placeCriteriaFormData.formValidationHandler === undefined) {
                return;
            }

            placeCriteriaFormData.formValidationHandler.validate(placeCriteriaFormData);
        });

        let hasPlaceCriteriaFormDataErrors: boolean = false;

        placeCriteriaFormDatas.forEach((placeCriteriaFormData: FormData<PlaceCriteria>): void => {
            if (placeCriteriaFormData.formValidationHandler === undefined) {
                return;
            }

            if (placeCriteriaFormData.formValidationHandler.hasErrors(placeCriteriaFormData) === true) {
                hasPlaceCriteriaFormDataErrors = true;
            }
        });

        if (hasPlaceCriteriaFormDataErrors as boolean === true) {
            setAlert(formErrorAlert);

            return;
        }

        if (propertyCriteriaFormValidationHandler.hasErrors(propertyCriteriaFormData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        if (hasLocationCategoryErrors === true) {
            setAlert(formErrorAlert);

            return;
        }

        if (formValidationHandler.hasErrors(formData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        setAlert(undefined);

        setIsLoading(true);

        try {
            await settlementConceptService.updateSettlementConcept(settlementConcept);

            setAlert(successAlert);
        } catch (error) {
            setAlert(errorAlert);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCountryPlaces = async (): Promise<void> => {
        const countries: Country[] =  await locationService.fetchCountries(['DE', 'AT']);

        const countryPlaces: Place[] = countries.map((country: Country): Place => {
            if (country.countryPlace === null) {
                throw new Error('Country must have a country place!');
            }

            return country.countryPlace;
        });

        setCountryPlaces(countryPlaces);
    };

    const buildImageGetPath = (image: Image): string => {
        if (settlementConcept === undefined) {
            throw new Error();
        }

        return SettlementConceptService.buildImageApiPath(settlementConcept, image);
    };

    const buildImageDeletePath = (image: Image): string => {
        if (settlementConcept === undefined) {
            throw new Error();
        }

        return SettlementConceptService.buildImageApiPath(settlementConcept, image);
    };

    const buildDocumentGetPath = (document: Document): string => {
        if (settlementConcept === undefined) {
            throw new Error();
        }

        return SettlementConceptService.buildDocumentApiPath(settlementConcept, document);
    };

    const buildDocumentPatchPath = (document: Document): string => {
        if (settlementConcept === undefined) {
            throw new Error();
        }

        return SettlementConceptService.buildDocumentApiPath(settlementConcept, document);
    };

    const buildDocumentDeletePath = (document: Document): string => {
        if (settlementConcept === undefined) {
            throw new Error();
        }

        return SettlementConceptService.buildDocumentApiPath(settlementConcept, document);
    };

    if (isLoading === true || settlementConcept === undefined || industryClassifications === undefined || countryPlaces === undefined) {
        return <Spinner />;
    }

    return (
        <>
            <div className="container-fluid">
                <div className="d-flex align-items-center">
                    <Link to="/suchprofile"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                    <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Suchprofil »{settlementConcept.title} ({settlementConcept.reference})« bearbeiten</h1>
                    <InfoButtonOverlay title="Suchprofil bearbeiten" className="fs-5 me-3 ms-auto">
                        <div className="mb-3">
                            Hier kannst Du Dein Suchprofil bearbeiten. Bitte fülle dazu die untenstehenden
                            Angaben zu Deiner Suche nach einer neuen (Laden-)Fläche aus. Pflichtfelder sind
                            dabei mit einem * gekennzeichnet. Über die Info-Symbole erhältst Du weitere
                            Informationen.
                        </div>
                        <div className="mb-3">
                            Bitte stelle sicher, dass Du Dein Suchprofil mit möglichst vielen Angaben füllst.
                            So ist ein passgenaues Matching mit einer (Laden-)Fläche möglich.
                        </div>
                    </InfoButtonOverlay>
                </div>
                {alert !== undefined &&
                    <AlertBox alert={alert} autoDismiss={false} />
                }
                <div className="py-3 px-0 px-md-3 mb-3">
                    <SettlementConceptForm
                        formData={formData}
                        setFormData={setFormData}
                        propertyCriteriaFormData={propertyCriteriaFormData}
                        setPropertyCriteriaFormData={setPropertyCriteriaFormData}
                        placeCriteriaFormDatas={placeCriteriaFormDatas}
                        setPlaceCriteriaFormDatas={setPlaceCriteriaFormDatas}
                        industryClassifications={industryClassifications}
                        countryPlaces={countryPlaces}
                        setHasLocationCategoryErrors={setHasLocationCategoryErrors}
                        formValidationHandler={formValidationHandler}
                        propertyCriteriaFormValidationHandler={propertyCriteriaFormValidationHandler}
                    />

                    <CollapseCard cardType="shadow" title="Bilder" className="mb-3">
                        <ImageUploader
                            ref={imageUploaderRef}
                            images={images}
                            isProcessingImages={isProcessingImages}
                            token={authenticatedUser!.token}
                            autoProcessQueue={true}
                            setNumberOfImageUploads={setNumberOfImageUploads}
                            buildImageGetPath={buildImageGetPath}
                            buildImageDeletePath={buildImageDeletePath}
                            setIsProcessingImages={setIsProcessingImages}
                            uploadUrl={process.env.REACT_APP_LLASM_API_URL! + '/v1/settlement-concepts' + '/' + settlementConcept!.id + '/images/upload'}
                            deleteImage={deleteImage}
                            onQueueComplete={fetchImages}
                            uploadText="Zieh hier schöne Bilder Deines Konzepts rein"
                            onDeleted={fetchImages}
                        />
                    </CollapseCard>
                    <CollapseCard cardType="shadow" title="Dokumente" className="mb-3">
                        <DocumentUploader
                            ref={documentUploaderRef}
                            documents={documents}
                            isProcessingDocuments={isProcessingDocuments}
                            token={authenticatedUser!.token}
                            autoProcessQueue={true}
                            setNumberOfDocumentUploads={setNumberOfDocumentUploads}
                            buildDocumentGetPath={buildDocumentGetPath}
                            buildDocumentPatchPath={buildDocumentPatchPath}
                            buildDocumentDeletePath={buildDocumentDeletePath}
                            setIsProcessingDocuments={setIsProcessingDocuments}
                            uploadUrl={process.env.REACT_APP_LLASM_API_URL! + '/v1/settlement-concepts' + '/' + settlementConcept!.id + '/documents/upload'}
                            onQueueComplete={fetchDocuments}
                            uploadText="Zieh hier Dokumente zu Deinem Suchprofil rein"
                            onDeleted={fetchDocuments}
                        />
                    </CollapseCard>
                    <button type="submit" className="btn btn-primary mt-4" onClick={updateSettlementConcept}>Speichern</button>
                </div>
            </div>
        </>
    );
};

export default SettlementConceptPage;
