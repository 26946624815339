import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import CollapseCard from '../Component/CollapseCard/CollapseCard';
import LabelValuePair from '../../../components/LabelValuePair';
import LabelValuesPair from '../../../components/LabelValuesPair';
import React from 'react';

interface BaseInformationCollapseCardProps {
    readonly settlementConcept: SettlementConcept;
}

const BaseInformationCollapseCard = (props: BaseInformationCollapseCardProps): React.JSX.Element => {
    const industryClassificationValues: string[] = props.settlementConcept.industryClassifications.map(
        industryClassification => industryClassification.name
    );

    return (
        <CollapseCard cardType="shadow" title="Grundinformationen" className="mb-3">
            <div className="row">
                <div className="col-12 col-lg-6 mb-3">
                    <LabelValuePair label="Suchprofiltitel" value={props.settlementConcept.title} />
                </div>
                <div className="col-12 col-lg-6 mb-3">
                    <LabelValuePair label="Suchprofilnummer" value={props.settlementConcept.reference} />
                </div>
                <div className="col-12 mb-3">
                    <LabelValuePair label="Beschreibung" value={props.settlementConcept.description} />
                </div>
                {industryClassificationValues.length > 0 &&
                    <div className="col-12 mb-3">
                        <LabelValuesPair label="Branche(n)" values={industryClassificationValues} delimiter=" · " />
                    </div>
                }
            </div>
        </CollapseCard>
    );
};

export default BaseInformationCollapseCard;
