import React from 'react';

const CardFooter = (props: React.PropsWithChildren): React.JSX.Element => {
    return (
        <div className="m-4 mt-auto">
            {props.children}
        </div>
    );
};

export default CardFooter;
