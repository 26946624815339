import BrandService from './BrandService';
import Alert from '../Entity/Alert/Alert';
import FormData from '../Entity/Form/FormData';
import Brand from '../Entity/Brand/Brand';
import AuthenticationState from '../Entity/Authentication/AuthenticationState';
import AlertType from '../Entity/Alert/AlertType';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import BrandForm from './BrandForm';
import AlertBox from '../../../components/AlertBox';
import LoadingIndicator from '../../../components/LoadingIndicator';
import {useAppSelector} from '../../../app/hooks';
import React, {useEffect, useRef, useState} from 'react';

interface BrandCreateProps {
    readonly onBrandCreated?: (brand: Brand) => void;
    readonly onCloseButtonClick?: () => void;
}

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Du hast nicht alle Pflichtfelder gefüllt. Bitte kontrolliere die mit einem roten Ausrufezeichen markierten Felder.');

const brandFieldValidationDefinitions: FieldValidationDefinition<Brand>[] = [
    new RequiredValidationDefinition<Brand>('brandName', 'Markenname muss ausgefüllt sein.'),
    new RequiredValidationDefinition<Brand>('ageStructures', 'Angesprochene Altersgruppe muss ausgefüllt sein.'),
    new RequiredValidationDefinition<Brand>('targetGroups', 'Angesprochene Zielgruppe muss ausgefüllt sein.'),
    new RequiredValidationDefinition<Brand>('priceSegments', 'Preissegmente muss ausgefüllt sein.'),
    /* TODO: to be added later, requires a process for approval by an administrator
     new RequiredValidationDefinition<Brand>('companyValues', 'Unternehmenswerte muss ausgefüllt sein.'), */
    new RequiredValidationDefinition<Brand>('branchingDegree', 'Filialisierungsgrad muss ausgefüllt sein.'),
];

const formValidationHandler: FormValidationHandler<Brand> = new FormValidationHandler<Brand>(brandFieldValidationDefinitions);

const brandService: BrandService = new BrandService(process.env.REACT_APP_LLASM_API_URL!);

const createFormData = (): FormData<Brand> => {
    return {data: new Brand()};
};

const BrandCreate = (props: BrandCreateProps): React.JSX.Element => {
    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [formData, setFormData] = useState<FormData<Brand>>(createFormData());

    const [brand, setBrand] = useState<Brand | undefined>(undefined);

    const [amountLogosInUploadQueue, setAmountLogosInUploadQueue] = useState<number>(0);

    const [alert, setAlert] = useState<Alert>();

    const logoUploaderRef: React.Ref<any> = useRef<React.ForwardedRef<any>>();

    useEffect((): void => {
        if (brand === undefined) {
            return;
        }

        if (amountLogosInUploadQueue > 0) {
            logoUploaderRef!.current.uploadFiles(process.env.REACT_APP_LLASM_API_URL! + '/v1/brands' + '/' + brand.id + '/logo');
        } else {
            onBrandCreated();
        }
    }, [amountLogosInUploadQueue, brand]);

    const persistBrand = async (): Promise<void> => {
        formValidationHandler.validate(formData);

        if (formValidationHandler.hasErrors(formData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        setIsLoading(true);

        try {
            setBrand(await brandService.persistBrand(formData.data));
        } catch (error) {
            throw error;
        } finally {
        }
    };

    const onBrandCreated = (): void => {
        if (brand === undefined) {
            return;
        }

        if (props.onBrandCreated !== undefined) {
            props.onBrandCreated(brand);
        }

        setFormData(createFormData());

        setIsLoading(false);
    };

    const onAddedFile = (): void => {
        setAmountLogosInUploadQueue((prevState:  number): number => prevState + 1);
    };

    const onRemovedFile = (): void => {
        setAmountLogosInUploadQueue((prevState:  number): number => prevState - 1);
    };

    const onQueueComplete = (): void => {
        if (brand === undefined) {
            return;
        }
    };

    return (
        <>
            {isLoading === true &&
                <div className="image-uploader-loading-indicator-container">
                    <LoadingIndicator />
                </div>
            }

            {alert !== undefined &&
                <AlertBox alert={alert} autoDismiss={false} />
            }
            <BrandForm
                formData={formData}
                setFormData={setFormData}
                logoUploaderRef={logoUploaderRef}
                token={authenticatedUser!.token}
                autoProcessQueue={false}
                maxFileSize={2}
                logo={null}
                onAddedFile={onAddedFile}
                onRemovedFile={onRemovedFile}
                onQueueComplete={onQueueComplete}
                isProcessingLogo={false}
                formValidationHandler={formValidationHandler}
            />
            <button type="submit" className="btn btn-primary align-items-center mt-3" onClick={persistBrand}>
                MARKE/GESCHÄFT ANLEGEN
            </button>
            {props.onCloseButtonClick !== undefined &&
                <button className="btn align-items-center mt-3" onClick={props.onCloseButtonClick}>
                    SCHLIESSEN
                </button>
            }
        </>
    );
};

export default BrandCreate;
