import FormData from './Entity/Form/FormData';
import SelectOption from './Entity/Form/SelectOption';
import LocationType from './Entity/LocationType';
import LocationCategory, {getLocationCategoryLabel} from './Entity/LocationCategory';
import FormValidationHandler from './FormValidationHandler/FormValidationHandler';
import LocationTypeSelectField from './LocationTypeSelectField';
import LocationCategorySelectField from './LocationCategorySelectField';
import React, {useEffect, useState} from 'react';

interface LocationTypeToLocationCategoriesMapping {
    locationType: LocationType;
    locationCategories: LocationCategory[];
}

interface LocationTypeAndLocationCategorySelectProps {
    readonly formData: FormData<any>;
    readonly setFormData: (formData: FormData<any>) => void;
    readonly locationTypeIsMulti: boolean;
    readonly locationCategoryIsMulti: boolean;
    readonly locationTypeLabel: string;
    readonly locationCategoryLabel: string;
    readonly locationTypeDescription?: string;
    readonly locationCategoryDescription?: string;
    readonly formValidationHandler?: FormValidationHandler<any>;
}

const locationTypeToLocationCategoriesMappings: LocationTypeToLocationCategoriesMapping[] = [
    {
        locationType: LocationType.DowntownLocation,
        locationCategories: [
            LocationCategory.OneALocation,
            LocationCategory.OneBLocation,
            LocationCategory.OneCLocation,
        ]
    },
    {
        locationType: LocationType.CityDistrictLocation,
        locationCategories: [
            LocationCategory.TwoALocation,
            LocationCategory.TwoBLocation,
            LocationCategory.TwoCLocation,
        ]
    },
    {
        locationType: LocationType.PeripheralLocation,
        locationCategories: [LocationCategory.Other]
    },
    {
        locationType: LocationType.HighFrequencySpecialLocation,
        locationCategories: [
            LocationCategory.TrainStation,
            LocationCategory.ShoppingCenter,
            LocationCategory.RetailPark,
            LocationCategory.Airport,
            LocationCategory.OutletCenter,
            LocationCategory.HighwayRestStop,
            LocationCategory.Other,
        ]
    },
    {
        locationType: LocationType.ScatteredAndSolitaryLayers,
        locationCategories: [LocationCategory.Other]
    },
];

const LocationTypeAndLocationCategorySelect = (props: LocationTypeAndLocationCategorySelectProps): React.JSX.Element => {
    const [locationCategorySelectOptions, setLocationCategorySelectOptions] = useState<SelectOption<LocationCategory>[]>();

    const [locationTypeSelectedValue, setLocationTypeSelectedValue] = useState<LocationType | LocationType[] | null>();

    useEffect((): void => {
        setLocationTypeSelectedValue(fetchLocationTypeValueFromFormData());
    }, []);

    useEffect((): void => {
        if (locationTypeSelectedValue === undefined) {
            return;
        }

        setLocationCategorySelectOptions(fetchLocationCategorySelectOptionsBySelectedLocationTypes());
    }, [locationTypeSelectedValue]);

    useEffect((): void => {
        if (locationCategorySelectOptions === undefined) {
            return;
        }

        updateLocationCategoryValueInFormData();
    }, [locationCategorySelectOptions]);

    const updateLocationCategoryValueInFormData = (): void => {
        if (locationCategorySelectOptions === undefined) {
            return;
        }

        const locationCategoryValue: LocationCategory[] | LocationCategory | null = fetchLocationCategoryValueFromFormData();

        const locationCategoryFromLocationCategorySelectOptions: LocationCategory[] = [];

        locationCategorySelectOptions.map((locationCategorySelectOption: SelectOption<LocationCategory>): void => {
            locationCategoryFromLocationCategorySelectOptions.push(locationCategorySelectOption.value);
        });

        if (Array.isArray(locationCategoryValue) === true) {
            const locationCategories: LocationCategory[] = locationCategoryValue.filter((locationCategory: LocationCategory): boolean => {
                return locationCategoryFromLocationCategorySelectOptions.includes(locationCategory);
            });

            handleLocationCategoriesChange(locationCategories);
        } else {
            if (locationCategoryFromLocationCategorySelectOptions.includes(locationCategoryValue as LocationCategory) === false) {
                handleLocationCategoriesChange(null);
            }
        }
    };

    const fetchLocationCategorySelectOptionsBySelectedLocationTypes = (): SelectOption<LocationCategory>[] => {
        if (locationTypeSelectedValue === undefined) {
            return [];
        }

        let locationCategories: LocationCategory[] = [];

        if (Array.isArray(locationTypeSelectedValue) === true) {
            if (locationTypeSelectedValue.length === 0) {
                return [];
            }

            locationTypeSelectedValue.forEach((locationType: LocationType): void => {
                const locationTypeToLocationCategoriesMapping: LocationTypeToLocationCategoriesMapping | undefined = locationTypeToLocationCategoriesMappings.find((locationTypeToLocationCategoriesMapping: LocationTypeToLocationCategoriesMapping): boolean => {
                    return locationTypeToLocationCategoriesMapping.locationType === locationType;
                });

                if (locationTypeToLocationCategoriesMapping !== undefined) {
                    locationTypeToLocationCategoriesMapping.locationCategories.forEach((locationCategory: LocationCategory): void => {
                        if (locationCategories.includes(locationCategory) === false) {
                            locationCategories.push(locationCategory);
                        }
                    });
                }
            });
        } else {
            const locationTypeToLocationCategoriesMapping: LocationTypeToLocationCategoriesMapping | undefined = locationTypeToLocationCategoriesMappings.find((locationTypeToLocationCategoriesMapping: LocationTypeToLocationCategoriesMapping): boolean => {
                return locationTypeToLocationCategoriesMapping.locationType === locationTypeSelectedValue;
            });

            if (locationTypeToLocationCategoriesMapping !== undefined) {
                locationCategories = locationTypeToLocationCategoriesMapping.locationCategories;
            }
        }

        return locationCategories.map((locationCategory: LocationCategory): SelectOption<LocationCategory> => {
            return {label: getLocationCategoryLabel(locationCategory), value: locationCategory};
        });
    };

    const handleLocationTypesChange = (newValue: LocationType | LocationType[] | null): void => {
        setLocationTypeSelectedValue(newValue);

        if (props.locationCategoryIsMulti === true) {
            props.formData.data.locationTypes = newValue;
        } else {
            props.formData.data.locationType = newValue;
        }

        validateField(getLocationTypeFieldName());

        updateFormData();
    };

    const handleLocationCategoriesChange = (newValue: LocationCategory | LocationCategory[] | null): void => {
        if (props.locationCategoryIsMulti === true) {
            props.formData.data.locationCategories = newValue;
        } else {
            props.formData.data.locationCategory = newValue;
        }

        validateField(getLocationCategoryFieldName());

        updateFormData();
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: props.formData.data});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    const getLocationTypeFieldName = (): string => {
        return props.locationTypeIsMulti === true ? 'locationTypes' : 'locationType';
    };

    const getLocationCategoryFieldName = (): string => {
        return props.locationCategoryIsMulti === true ? 'locationCategories' : 'locationCategory';
    };

    const fetchLocationTypeValueFromFormData = (): LocationType | LocationType[] | null => {
        if (props.locationCategoryIsMulti === true) {
            return props.formData.data.locationTypes;
        } else {
            return props.formData.data.locationType;
        }
    };

    const fetchLocationCategoryValueFromFormData = (): LocationCategory | LocationCategory[] | null => {
        if (props.locationCategoryIsMulti === true) {
            return props.formData.data.locationCategories;
        } else {
            return props.formData.data.locationCategory;
        }
    };

    const locationTypeHasSelectedValue = (): boolean => {
        if (locationTypeSelectedValue === undefined) {
            return false;
        }

        if (Array.isArray(locationTypeSelectedValue) === true && locationTypeSelectedValue.length === 0) {
            return false;
        } else if (locationTypeSelectedValue === null) {
            return false;
        }

        return true;
    };

    return (
        <div className="row">
            <div className="col-lg-6 mb-3">
                <LocationTypeSelectField
                    label={props.locationTypeLabel}
                    title={props.locationTypeDescription!}
                    name={getLocationTypeFieldName()}
                    isMulti={props.locationTypeIsMulti}
                    isClearable={props.locationTypeIsMulti ? true : false}
                    required={true}
                    defaultValue={fetchLocationTypeValueFromFormData()}
                    onChange={handleLocationTypesChange}
                    hasErrors={FormValidationHandler.hasFieldError(props.formData, getLocationTypeFieldName())}
                />
            </div>
            {locationTypeHasSelectedValue() === true &&
                <div className="col-lg-6 mb-3">
                    <LocationCategorySelectField
                        selectOptions={locationCategorySelectOptions}
                        label={props.locationCategoryLabel}
                        title={props.locationCategoryDescription!}
                        name={getLocationCategoryFieldName()}
                        isMulti={props.locationCategoryIsMulti}
                        isClearable={props.locationCategoryIsMulti ? true : false}
                        required={true}
                        value={fetchLocationCategoryValueFromFormData()}
                        defaultValue={fetchLocationCategoryValueFromFormData()}
                        onChange={handleLocationCategoriesChange}
                        hasErrors={FormValidationHandler.hasFieldError(props.formData, getLocationCategoryFieldName())}
                    />
                </div>
            }
        </div>
    );
};

export default LocationTypeAndLocationCategorySelect;
