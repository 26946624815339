import LocationService from '../Location/LocationService';
import FormData from '../Entity/Form/FormData';
import LocationDetail from '../Entity/Property/LocationDetail';
import Place from '../Entity/Location/Place';
import LocationFactor from '../Entity/LocationFactor';
import SelectOption from '../Entity/Form/SelectOption';
import AutocompletePlace from '../Entity/Location/AutocompletePlace';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import LocationTypeAndLocationCategorySelect from '../LocationTypeAndLocationCategorySelect';
import LocationFactorSelectField, {FieldType} from '../LocationFactorSelectField';
import SelectField from '../Component/Form/Field/SelectField';
import AsyncSelectField from '../Component/Form/Field/AsyncSelectField';
import InputField from '../Component/Form/Field/InputField';
import TextAreaField from '../Component/Form/Field/TextAreaField';
import React, {useEffect, useState} from 'react';

interface LocationDetailFormProps {
    readonly locationService: LocationService;
    readonly formData: FormData<LocationDetail>;
    readonly setFormData: (formData: FormData<LocationDetail>) => void;
    readonly validationDefinitions: FieldValidationDefinition<LocationDetail>[];
    readonly countryPlaces: Place[];
    readonly defaultCountryPlace?: Place;
}

const LocationDetailForm = (props: LocationDetailFormProps): React.JSX.Element => {
    const locationDetail: LocationDetail = props.formData.data;

    const [countryPlaceSelectOptions, setCountryPlaceSelectOptions] = useState<SelectOption<Place>[]>();

    const [countryPlaceSelectedOption, setCountryPlaceSelectedOption] = useState<SelectOption<Place>>();

    useEffect((): void => {
        props.setFormData({...props.formData, formValidationHandler: new FormValidationHandler<LocationDetail>(props.validationDefinitions)});
    }, [props.validationDefinitions]);

    useEffect((): void => {
        const countryPlaceSelectOptions: SelectOption<Place>[] = props.countryPlaces.map((place: Place): SelectOption<Place> => {
            return {
                label: place.placeName,
                value: place
            };
        });

        setCountryPlaceSelectOptions(countryPlaceSelectOptions);

        if (props.defaultCountryPlace !== undefined) {
            countryPlaceSelectOptions.forEach((countryPlaceSelectOption: SelectOption<Place>): void => {
                if (countryPlaceSelectOption.value.id === props.defaultCountryPlace!.id) {
                    setCountryPlaceSelectedOption(countryPlaceSelectOption);
                }
            });
        }

        if (locationDetail.countryPlace !== undefined) {
            countryPlaceSelectOptions.forEach((countryPlaceSelectOption: SelectOption<Place>): void => {
                if (countryPlaceSelectOption.value.id === locationDetail.countryPlace.id) {
                    setCountryPlaceSelectedOption(countryPlaceSelectOption);
                }
            });
        }
    }, [props.countryPlaces]);

    const fetchCitySelectOptions = async (searchTerm: string): Promise<SelectOption<AutocompletePlace>[]> => {
        if (countryPlaceSelectedOption === undefined) {
            return [];
        }

        if (searchTerm === '' || searchTerm.length < 3) {
            return [];
        }

        return (await (props.locationService.fetchPlacesBySearchTerm(searchTerm, undefined, countryPlaceSelectedOption.value.uuid))).map((autocompletePlace: AutocompletePlace): SelectOption<AutocompletePlace> => {
            return {
                label: autocompletePlace.autoCompleteDisplay,
                value: autocompletePlace
            };
        });
    };

    const fetchCitySelectedOption = (): SelectOption<AutocompletePlace> | undefined => {
        if (locationDetail.city === undefined) {
            return undefined;
        }

        const autocompletePlace: AutocompletePlace = new AutocompletePlace();

        autocompletePlace.autoCompleteDisplay = locationDetail.city.placeName;
        autocompletePlace.place = locationDetail.city;

        return {
            label: autocompletePlace.autoCompleteDisplay,
            value: autocompletePlace
        };
    };

    const handleCountryPlacesChange = (selectedCountry: SelectOption<Place> | null): void => {
        if (selectedCountry === null) {
            return;
        }

        const selectedCountryPlace: Place = selectedCountry.value;

        const countryPlaceSelectedOption: SelectOption<Place> = {
            label: selectedCountryPlace.placeName,
            value: selectedCountryPlace
        };

        setCountryPlaceSelectedOption(countryPlaceSelectedOption);

        locationDetail.city = undefined;

        updateFormData();
    };

    const handleCityChange = (selectedCity: SelectOption<AutocompletePlace> | null): void => {
        if (selectedCity === null) {
            return;
        }

        locationDetail.city = selectedCity.value.place;

        updateFormData();

        validateField('city');
    };

    const handleLocationFactorsChange = (locationFactors: LocationFactor[]): void => {
        locationDetail.locationFactors = locationFactors;

        updateFormData();
        validateField('locationFactors');
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
        (locationDetail as any)[event.target.name] = event.target.value;

        updateFormData();

        validateField(event.target.name);
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: locationDetail});
    };

    const validateField = (fieldName: string): void => {
        if (props.formData.formValidationHandler === undefined) {
            return;
        }

        props.formData.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <div className="row">
            <div className="col-lg-6">
                {countryPlaceSelectOptions !== undefined &&
                    <div className="row mb-3">
                        <div className="col">
                            <SelectField
                                label="Land"
                                name="countryPlaces"
                                options={countryPlaceSelectOptions}
                                required={true}
                                value={countryPlaceSelectedOption}
                                placeholder="Bitte wählen"
                                onChange={handleCountryPlacesChange}
                                formErrors={FormValidationHandler.getFieldErrors(props.formData, 'countryPlaces')}
                            />
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-6 col-lg-12 col-xl-6 mb-3">
                        <AsyncSelectField
                            label="Ort"
                            name="city"
                            required={true}
                            loadOptions={fetchCitySelectOptions}
                            defaultOptions
                            closeMenuOnSelect={false}
                            placeholder="Ort suchen"
                            loadingMessage={(): string => 'Passende Orte werden gesucht...'}
                            onChange={handleCityChange}
                            value={fetchCitySelectedOption()}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'city')}
                        />
                    </div>
                    <div className="col-md-6 col-lg-12 col-xl-6 mb-3">
                        <InputField
                            name="postalCode"
                            label="PLZ"
                            type="text"
                            required={true}
                            value={locationDetail.postalCode}
                            onChange={handleChange}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'postalCode')}
                        />
                    </div>
                    <div className="col-md-6 col-lg-12 col-xl-6 mb-3">
                        <InputField
                            name="streetName"
                            label="Straße"
                            type="text"
                            required={false}
                            value={locationDetail.streetName ?? undefined}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 col-lg-12 col-xl-6 mb-3">
                        <InputField
                            name="houseNumber"
                            label="Hausnummer"
                            type="text"
                            required={false}
                            value={locationDetail.houseNumber ?? undefined}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                        <InputField
                            name="addressAffix"
                            label="Adresszusatz"
                            type="text"
                            required={false}
                            value={locationDetail.addressAffix ?? undefined}
                            description={'Hier kannst Du optional einen Adresszusatz (z. B. "Im Hinterhof") eingeben.'}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-3">
                        <TextAreaField
                            name="locationDescription"
                            label="Lagebeschreibung"
                            rows={9}
                            required={false}
                            value={locationDetail.locationDescription ?? undefined}
                            description="Hier kannst Du die Lage Deiner Fläche detailliert beschreiben."
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="mb-3">
                    <LocationTypeAndLocationCategorySelect
                        formData={props.formData}
                        setFormData={props.setFormData}
                        formValidationHandler={props.formData.formValidationHandler}
                        locationTypeIsMulti={false}
                        locationCategoryIsMulti={false}
                        locationTypeLabel="Standorttyp"
                        locationCategoryLabel="Lagekategorie"
                        locationTypeDescription="Welcher Standorttyp beschreibt die grobe Lage Deiner Fläche."
                        locationCategoryDescription="Liegt Deine Fläche in einer Innenstadt- oder Stadtteillage, solltest Du hier angeben in welcher Lagekategorie sich Deine Fläche befindet."
                    />
                </div>
                <div className="row">
                    <div className="col">
                        <LocationFactorSelectField
                            fieldType={FieldType.CHECKBOX}
                            label="Mikrolage"
                            title="Welche Faktoren beschreiben die Mikrolage Deiner Fläche?"
                            name="locationFactors"
                            isMulti={true}
                            isClearable={true}
                            required={true}
                            defaultValue={locationDetail.locationFactors}
                            onChange={handleLocationFactorsChange}
                            hasErrors={FormValidationHandler.hasFieldError(props.formData, 'locationFactors')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocationDetailForm;
