import IndustryClassification from '../Entity/IndustryClassification/IndustryClassification';
import FormData from '../Entity/Form/FormData';
import FieldLabel from '../Component/Form/Field/FieldLabel';
import React, {useEffect, useState} from 'react';
import Select from 'react-select';

interface IndustryClassificationSelectFormProps {
    readonly industryClassifications: IndustryClassification[];
    readonly formData: FormData<IndustryClassification[]>;
    readonly setFormData: (industryClassification: FormData<IndustryClassification[]>) => void;
    readonly index: number;
}

const IndustryClassificationSelectForm = (props: IndustryClassificationSelectFormProps): React.JSX.Element => {
    const [levelOneIndustryClassification, setLevelOneIndustryClassification] = useState<IndustryClassification | null>(null);

    const [levelTwoIndustryClassification, setLevelTwoIndustryClassification] = useState<IndustryClassification | null>(null);

    const [levelThreeIndustryClassification, setLevelThreeIndustryClassification] = useState<IndustryClassification | null>(null);

    useEffect((): void => {
        const selectedIndustryClassification: IndustryClassification | null | undefined = props.formData.data[props.index];

        if (selectedIndustryClassification === undefined) {
            return;
        }

        if (selectedIndustryClassification === null) {
            return;
        }

        if (selectedIndustryClassification.id === undefined) {
            return;
        }

        if (selectedIndustryClassification.levelThree !== null) {
            const selectedLevelOneIndustryClassification: IndustryClassification | undefined = props.industryClassifications.find((category: IndustryClassification) => category.levelOne === selectedIndustryClassification.levelOne && category.levelTwo === null);
            const selectedLevelTwoIndustryClassification: IndustryClassification | undefined = props.industryClassifications.find((category: IndustryClassification) => category.levelOne === selectedIndustryClassification.levelOne && category.levelTwo === selectedIndustryClassification.levelTwo && category.levelThree === null);

            if (selectedLevelOneIndustryClassification === undefined) {
                throw new Error();
            }

            if (selectedLevelTwoIndustryClassification === undefined) {
                throw new Error();
            }

            setLevelOneIndustryClassification(selectedLevelOneIndustryClassification);
            setLevelTwoIndustryClassification(selectedLevelTwoIndustryClassification);
            setLevelThreeIndustryClassification(selectedIndustryClassification);
        } else if (selectedIndustryClassification.levelTwo !== null) {
            const selectedLevelOneIndustryClassification: IndustryClassification | undefined = props.industryClassifications.find((category: IndustryClassification) => category.levelOne === selectedIndustryClassification.levelOne && category.levelTwo === null);

            if (selectedLevelOneIndustryClassification === undefined) {
                throw new Error();
            }

            setLevelOneIndustryClassification(selectedLevelOneIndustryClassification);
            setLevelTwoIndustryClassification(selectedIndustryClassification);
        } else {
            setLevelOneIndustryClassification(selectedIndustryClassification);
        }
    }, []);

    useEffect((): void => {
        if (
            levelOneIndustryClassification !== null
            && levelTwoIndustryClassification === null
            && levelThreeIndustryClassification === null
        ) {
            handleIndustryClassificationChange(levelOneIndustryClassification);
            return;
        }

        if (
            levelOneIndustryClassification !== null
            && levelTwoIndustryClassification !== null
            && levelThreeIndustryClassification === null
        ) {
            handleIndustryClassificationChange(levelTwoIndustryClassification);
            return;
        }

        if (
            levelOneIndustryClassification !== null
            && levelTwoIndustryClassification !== null
            && levelThreeIndustryClassification !== null
        ) {
            handleIndustryClassificationChange(levelThreeIndustryClassification);
            return;
        }
    }, [levelOneIndustryClassification, levelTwoIndustryClassification, levelThreeIndustryClassification]);

    const handleIndustryClassificationChange = (industryClassification: IndustryClassification): void => {
        const industryClassifications: IndustryClassification[] = [...props.formData.data];

        industryClassifications[props.index] = industryClassification;

        props.setFormData({...props.formData, data: industryClassifications});
    };

    const onChangeLevelOne = (industryClassification: IndustryClassification | null): void => {
        setLevelOneIndustryClassification(industryClassification);
        setLevelTwoIndustryClassification(null);
        setLevelThreeIndustryClassification(null);
    };

    const onChangeLevelTwo = (industryClassification: IndustryClassification | null): void => {
        setLevelTwoIndustryClassification(industryClassification);
        setLevelThreeIndustryClassification(null);
    };

    const onChangeLevelThree = (industryClassification: IndustryClassification | null): void => {
        setLevelThreeIndustryClassification(industryClassification);
    };

    const getLevelOneOptions = (): IndustryClassification[] => {
        return props.industryClassifications.filter((industryClassification: IndustryClassification): boolean => industryClassification.levelTwo === null);
    };

    const getLevelTwoOptions = (): IndustryClassification[] => {
        if (levelOneIndustryClassification === null) {
            return [];
        }

        return props.industryClassifications.filter((industryClassification: IndustryClassification): boolean => {
            return industryClassification.levelOne === levelOneIndustryClassification.levelOne
                && industryClassification.levelTwo !== null
                && industryClassification.levelThree === null;
        });
    };

    const getLevelThreeOptions = (): IndustryClassification[] => {
        if (levelOneIndustryClassification === null) {
            return [];
        }

        if (levelTwoIndustryClassification === null) {
            return [];
        }

        return props.industryClassifications.filter((industryClassification: IndustryClassification): boolean => {
            return industryClassification.levelOne === levelOneIndustryClassification.levelOne
                && industryClassification.levelTwo === levelTwoIndustryClassification.levelTwo
                && industryClassification.levelThree !== null;
        });
    };

    const getOptionValue = (industryClassification: IndustryClassification): string => {
        return industryClassification.id!.toString();
    };

    const getOptionLabel = (industryClassification: IndustryClassification): string => {
        return industryClassification.name;
    };

    return (
        <>
            <div>
                <FieldLabel label="Oberkategorie" htmlFor="levelOneIndustryClassification"  required={true} className="mb-2 ps-2 mt-2" />
                <Select
                    name="levelOneIndustryClassification"
                    value={levelOneIndustryClassification}
                    className="inset-shadow"
                    options={getLevelOneOptions()}
                    getOptionLabel={getOptionLabel}
                    required={true}
                    placeholder="Bitte wählen"
                    getOptionValue={getOptionValue}
                    isClearable={false}
                    onChange={onChangeLevelOne}
                />
            </div>

            {levelOneIndustryClassification !== null &&
                <div>
                    <FieldLabel label="Mittelkategorie" htmlFor="LevelTwoIndustryClassification" className="mb-2 ps-2 mt-2" />
                    <Select
                        name="LevelTwoIndustryClassification"
                        value={levelTwoIndustryClassification}
                        className="inset-shadow"
                        options={getLevelTwoOptions()}
                        getOptionLabel={getOptionLabel}
                        getOptionValue={getOptionValue}
                        isClearable={true}
                        placeholder="Bitte wählen"
                        onChange={onChangeLevelTwo}
                    />
                </div>
            }
            {(
                levelOneIndustryClassification !== null
                && levelTwoIndustryClassification !== null
                && props.industryClassifications.filter((industryClassification: IndustryClassification): boolean => {
                    return industryClassification.levelThree !== null
                        && industryClassification.levelOne === levelOneIndustryClassification.levelOne
                        && industryClassification.levelTwo === levelTwoIndustryClassification.levelTwo;
                    }).length > 0
                ) &&
                <div>
                    <FieldLabel label="Unterkategorie" htmlFor="levelThreeIndustryClassification" className="mb-2 ps-2 mt-2" />
                    <Select
                        name="levelThreeIndustryClassification"
                        value={levelThreeIndustryClassification}
                        className="inset-shadow"
                        options={getLevelThreeOptions()}
                        getOptionLabel={getOptionLabel}
                        getOptionValue={getOptionValue}
                        isClearable={true}
                        placeholder="Bitte wählen"
                        onChange={onChangeLevelThree}
                    />
                </div>
            }
        </>
    );
};

export default IndustryClassificationSelectForm;
