enum LocationFactor {
    MedicalCenter = 0,
    NearFreeway = 1,
    Station = 2,
    HomeImprovementsStore = 3,
    CornerLocations = 4,
    EntranceExitRoadsRingRoads = 5,
    ShoppingStreets = 6,
    ShoppingCenterMall = 7,
    RetailPark = 8,
    AirPort = 9,
    PedestrianZone = 10,
    IndustrialPark = 11,
    MetropolitanLocation = 12,
    BusStops = 13,
    MainStreet = 14,
    HighTrafficLocation = 15,
    Downtown = 16,

    TRUCK_DELIVERY_POSSIBILITY = 17,   // TODO - deprecated

    Marketplace = 18,
    LocalSupplyCenter = 19,
    SideStreet = 20,
    PublicTransportConnection = 21,
    SelfServiceDepartmentStores = 22,
    SchoolProximity = 23,
    InCityDistrict = 24,
    TouristPlaces = 25,
    PreCheckoutZones = 26,
    ResidentialAreaProximity = 27,
    SuburbanLocation = 28,
    RetailAgglomeration = 29
}

const getLocationFactorLabel = (locationFactor: LocationFactor): string => {
    switch (locationFactor) {
        case LocationFactor.MedicalCenter:
            return 'Ärztehaus';
        case LocationFactor.NearFreeway:
            return 'Autobahnnähe';
        case LocationFactor.Station:
            return 'Bahnhofsnähe';
        case LocationFactor.HomeImprovementsStore:
            return 'Baumärkte';
        case LocationFactor.CornerLocations:
            return 'Ecklage';
        case LocationFactor.EntranceExitRoadsRingRoads:
            return 'Ein-/Ausfallstraßen; Ringstraßen';
        case LocationFactor.ShoppingStreets:
            return 'Einkaufsstraßen';
        case LocationFactor.ShoppingCenterMall:
            return 'Einkaufszentrum/Mall';
        case LocationFactor.RetailPark:
            return 'Fachmarktzentrum';
        case LocationFactor.AirPort:
            return 'Flughafennähe';
        case LocationFactor.PedestrianZone:
            return 'Fußgängerzone';
        case LocationFactor.IndustrialPark:
            return 'Gewerbegebiet';
        case LocationFactor.MetropolitanLocation:
            return 'Großstadtlage';
        case LocationFactor.BusStops:
            return 'Haltestellen';
        case LocationFactor.MainStreet:
            return 'Hauptstraße';
        case LocationFactor.HighTrafficLocation:
            return 'Hochfrequentierte Verkehrslage';
        case LocationFactor.Downtown:
            return 'Innenstadt';

        case LocationFactor.TRUCK_DELIVERY_POSSIBILITY:    // TODO - deprecated
            return 'LKW-Anliefermöglichkeit';              // TODO - deprecated

        case LocationFactor.Marketplace:
            return 'Marktplatz';
        case LocationFactor.LocalSupplyCenter:
            return 'Nahversorgungszentrum';
        case LocationFactor.SideStreet:
            return 'Nebenstraße';
        case LocationFactor.PublicTransportConnection:
            return 'ÖPNV-Anschluss';
        case LocationFactor.SelfServiceDepartmentStores:
            return 'SB-Warenhäuser';
        case LocationFactor.SchoolProximity:
            return 'Schulnähe';
        case LocationFactor.InCityDistrict:
            return 'Stadtteillage';
        case LocationFactor.TouristPlaces:
            return 'Touristische Plätze';
        case LocationFactor.PreCheckoutZones:
            return 'Vorkassenzonen';
        case LocationFactor.ResidentialAreaProximity:
            return 'Wohngebietsnähe';
        case LocationFactor.SuburbanLocation:
            return 'Stadtrandlage';
        case LocationFactor.RetailAgglomeration:
            return 'Fachmarktagglomeration';
        default:
            throw new Error();
    }
};

export default LocationFactor;
export {getLocationFactorLabel};
