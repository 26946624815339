import SettlementConceptService from './SettlementConceptService';
import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import Image from '../Entity/Image/Image';
import ImageComponent from '../Image/Image';
import CollapseCard from '../Component/CollapseCard/CollapseCard';
import React from 'react';

interface ImageCollapseCardProps {
    readonly settlementConcept: SettlementConcept;
}

const ImageCollapseCard = (props: ImageCollapseCardProps): React.JSX.Element => {
    const buildImageGetPath = (image: Image): string => {
        return SettlementConceptService.buildImageApiPath(props.settlementConcept, image);
    };


    return (
        <CollapseCard cardType="shadow" title="Bilder" className="mb-3">
            <div className="row">
                {props.settlementConcept.images.map((image: Image): React.JSX.Element => (
                    <div className="col-12 col-md-6 mb-3">
                        <ImageComponent buildImageGetPath={buildImageGetPath} image={image} className="img-fluid" />
                    </div>
                ))}
            </div>
        </CollapseCard>
    );
};

export default ImageCollapseCard;
