import AuthenticationService from '../../domain/shared/Authentication/AuthenticationService';
import AuthenticationState from '../../domain/shared/Entity/Authentication/AuthenticationState';
import AuthenticatedUser from '../../domain/shared/Entity/Authentication/AuthenticatedUser';
import Login from '../../domain/shared/Entity/Authentication/Login';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const authenticationService: AuthenticationService = new AuthenticationService(process.env.REACT_APP_LLASM_API_URL!);

const initialState: AuthenticationState = {
    authenticatedUser: authenticationService.fetchAuthenticatedUser(),
    isLoading: false,
    error: null,
    isError: false,
    message: ''
};

const authenticate = createAsyncThunk<AuthenticatedUser | null, Login>('auth/login', async (login: Login, thunkAPI) => {
    try {
        await authenticationService.authenticate(login);

        return authenticationService.fetchAuthenticatedUser();
    } catch (error) {
         return thunkAPI.rejectWithValue(error);
    }
});

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder): void => {
        builder
            .addCase(authenticate.pending, (state): void => {
                state.isLoading = true;
            })
            .addCase(authenticate.fulfilled, (state, action): void => {
                state.isLoading = false;
                state.authenticatedUser = action.payload;
                state.isError = false;
                state.message = '';
            })
            .addCase(authenticate.rejected, (state, action): void => {
                state.isLoading = false;
                state.authenticatedUser = null;
                state.error = action.payload;
                state.isError = true;
                state.message = String(action.payload);
            });
    }
});

export default authenticationSlice.reducer;
export {authenticate};
