import Header from './Header';
import SubHeader from './SubHeader';
import React from 'react';
import {Link} from 'react-router-dom';

interface BaseProps extends React.PropsWithChildren {
}

const BaseLayout = (props: BaseProps): React.JSX.Element => {
    return (
        <>
            <Header />
            <div style={{padding: '0 41px'}}>
                <SubHeader />
            </div>
            <div className="main-content-padding">
                <div className="bg-white border-0 rounded-3 flex-grow-1 mb-3">
                    <div className="p-0 main-content-background">
                        <main>
                            {props.children}
                        </main>
                    </div>
                </div>
                <div className="footer mb-3 d-md-flex">
                    <ul className="nav">
                        <li className="nav-item">
                            <Link to="/impressum" target="_blank" className="nav-link text-muted">Impressum</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/agb" target="_blank" className="nav-link text-muted">AGB</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/datenschutz" target="_blank" className="nav-link text-muted">Datenschutz</Link>
                        </li>
                        <li>
                            <Link to="https://www.llasm.de/newsletteranmeldung" target="_blank" className="nav-link text-muted">Newsletter</Link>
                        </li>
                    </ul>
                    <div className="ms-3 me-3 ms-md-auto pt-2 text-secondary">
                        Nominiert für den <a href="https://realproptech.de/" target="_blank">PropTech Germany Award</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BaseLayout;
