import LocationFactor, { getLocationFactorLabel } from './Entity/LocationFactor';
import SelectOption from './Entity/Form/SelectOption';
import Tooltip from './Component/Tooltip/Tooltip';
import FieldLabel from './Component/Form/Field/FieldLabel';
import React, {useState} from 'react';
import Select, {ActionMeta, OnChangeValue, PropsValue} from 'react-select';
import Product from "./Entity/Product/Product";

interface LocationFactorSelectFieldProps {
    readonly fieldType: FieldType;
    readonly label: string;
    readonly title: string;
    readonly name: string;
    readonly isMulti: boolean;
    readonly isClearable: boolean;
    readonly required: boolean;
    readonly defaultValue?: LocationFactor | LocationFactor[];
    readonly onChange?: Function;
    readonly hasErrors?: boolean | undefined; // TODO - undefined entfernen
}

interface SelectFieldTypeProps {
    readonly label: string;
    readonly title: string;
    readonly name: string;
    readonly selectOptions: SelectOption<LocationFactor>[];
    readonly isMulti: boolean;
    readonly isClearable: boolean;
    readonly defaultValue?: PropsValue<SelectOption<LocationFactor>>;
    readonly onChange?: (newValue: OnChangeValue<SelectOption<LocationFactor>, boolean>, actionMeta: ActionMeta<SelectOption<LocationFactor>>) => void;
}

interface CheckboxFieldTypeProps {
    readonly label: string;
    readonly title: string;
    readonly selectOptions: SelectOption<LocationFactor>[];
    readonly selectedOptions: SelectOption<LocationFactor>[];
    readonly required: boolean;
    readonly onChange?: Function;
    readonly hasErrors?: boolean | undefined; // TODO - undefined entfernen
}

interface CheckboxProps {
    readonly selectOption: SelectOption<LocationFactor>;
    readonly checked: boolean;
    readonly onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

enum FieldType {
    SELECT = 'select',
    CHECKBOX = 'checkbox',
}

const selectOptions: SelectOption<LocationFactor>[] = [
    {label: getLocationFactorLabel(LocationFactor.MedicalCenter), value: LocationFactor.MedicalCenter},
    {label: getLocationFactorLabel(LocationFactor.NearFreeway), value: LocationFactor.NearFreeway},
    {label: getLocationFactorLabel(LocationFactor.Station), value: LocationFactor.Station},
    {label: getLocationFactorLabel(LocationFactor.HomeImprovementsStore), value: LocationFactor.HomeImprovementsStore},
    {label: getLocationFactorLabel(LocationFactor.CornerLocations), value: LocationFactor.CornerLocations},
    {label: getLocationFactorLabel(LocationFactor.EntranceExitRoadsRingRoads), value: LocationFactor.EntranceExitRoadsRingRoads},
    {label: getLocationFactorLabel(LocationFactor.ShoppingStreets), value: LocationFactor.ShoppingStreets},
    {label: getLocationFactorLabel(LocationFactor.ShoppingCenterMall), value: LocationFactor.ShoppingCenterMall},
    {label: getLocationFactorLabel(LocationFactor.RetailPark), value: LocationFactor.RetailPark},
    {label: getLocationFactorLabel(LocationFactor.AirPort), value: LocationFactor.AirPort},
    {label: getLocationFactorLabel(LocationFactor.PedestrianZone), value: LocationFactor.PedestrianZone},
    {label: getLocationFactorLabel(LocationFactor.IndustrialPark), value: LocationFactor.IndustrialPark},
    {label: getLocationFactorLabel(LocationFactor.MetropolitanLocation), value: LocationFactor.MetropolitanLocation},
    {label: getLocationFactorLabel(LocationFactor.BusStops), value: LocationFactor.BusStops},
    {label: getLocationFactorLabel(LocationFactor.MainStreet), value: LocationFactor.MainStreet},
    {label: getLocationFactorLabel(LocationFactor.HighTrafficLocation), value: LocationFactor.HighTrafficLocation},
    {label: getLocationFactorLabel(LocationFactor.Downtown), value: LocationFactor.Downtown},
    {label: getLocationFactorLabel(LocationFactor.Marketplace), value: LocationFactor.Marketplace},
    {label: getLocationFactorLabel(LocationFactor.LocalSupplyCenter), value: LocationFactor.LocalSupplyCenter},
    {label: getLocationFactorLabel(LocationFactor.SideStreet), value: LocationFactor.SideStreet},
    {label: getLocationFactorLabel(LocationFactor.PublicTransportConnection), value: LocationFactor.PublicTransportConnection},
    {label: getLocationFactorLabel(LocationFactor.SelfServiceDepartmentStores), value: LocationFactor.SelfServiceDepartmentStores},
    {label: getLocationFactorLabel(LocationFactor.SchoolProximity), value: LocationFactor.SchoolProximity},
    {label: getLocationFactorLabel(LocationFactor.InCityDistrict), value: LocationFactor.InCityDistrict},
    {label: getLocationFactorLabel(LocationFactor.TouristPlaces), value: LocationFactor.TouristPlaces},
    {label: getLocationFactorLabel(LocationFactor.PreCheckoutZones), value: LocationFactor.PreCheckoutZones},
    {label: getLocationFactorLabel(LocationFactor.ResidentialAreaProximity), value: LocationFactor.ResidentialAreaProximity},
    {label: getLocationFactorLabel(LocationFactor.SuburbanLocation), value: LocationFactor.SuburbanLocation},
    {label: getLocationFactorLabel(LocationFactor.RetailAgglomeration), value: LocationFactor.RetailAgglomeration},
];

const sortSelectOptions = (selectOptionA: SelectOption<LocationFactor>, selectOptionB: SelectOption<LocationFactor>): number => {
    if (selectOptionA.label < selectOptionB.label) {
        return -1;
    }

    if (selectOptionA.label > selectOptionB.label) {
        return 1;
    }

    return 0;
};

selectOptions.sort(sortSelectOptions);


const LocationFactorSelectField = (props: LocationFactorSelectFieldProps): React.JSX.Element => {
    const fetchSelectedOptions = (): SelectOption<LocationFactor>[] => {
        return selectOptions.filter((selectOption: SelectOption<LocationFactor>): boolean => {
            if (Array.isArray(props.defaultValue) === true) {
                return (props.defaultValue as LocationFactor[]).includes(selectOption.value);
            } else {
                return selectOption.value === props.defaultValue;
            }
        });
    };

    const fetchDefaultValue = (): PropsValue<SelectOption<LocationFactor>> | undefined => {
        const selectOptions: SelectOption<LocationFactor>[] = fetchSelectedOptions();

        if (props.isMulti === true) {
            return selectOptions;
        } else {
            if (selectOptions.length > 0) {
                return selectOptions[0];
            }
        }

        return undefined;
    };

    const formField = (): React.JSX.Element => {
        if (props.fieldType === FieldType.SELECT) {
            return <SelectFieldType
                title={props.title}
                label={props.label}
                name={props.name}
                selectOptions={selectOptions}
                isMulti={props.isMulti}
                isClearable={props.isClearable}
                defaultValue={fetchDefaultValue()}
                onChange={(newValue: OnChangeValue<SelectOption<LocationFactor>, boolean>): void => {
                    if (props.onChange !== undefined) {
                        if (Array.isArray(newValue) === true) {
                            props.onChange((newValue as Array<SelectOption<LocationFactor>>).map((selectOption: SelectOption<LocationFactor>): LocationFactor => selectOption.value));
                        } else {
                            props.onChange((newValue as SelectOption<LocationFactor>).value);
                        }
                    }
                }}
            />
        }

        if (props.fieldType === FieldType.CHECKBOX) {
            return <CheckboxFieldType
                title={props.title}
                label={props.label}
                selectOptions={selectOptions}
                selectedOptions={fetchSelectedOptions()}
                required={props.required}
                hasErrors={props.hasErrors}
                onChange={(selectedOptions: SelectOption<LocationFactor>[]): void => {
                    if (props.onChange !== undefined) {
                        props.onChange(selectedOptions.map((selectOption: SelectOption<LocationFactor>): LocationFactor => selectOption.value));
                    }
                }}
            />
        }

        return <></>;
    };

    return formField();
};

const SelectFieldType = (props: SelectFieldTypeProps): React.JSX.Element => {
    return (
        <div className="d-flex justify-content-between align-items-center flex-wrap flex-lg-nowrap">
            <div className="w-100">
                <FieldLabel label={props.label} htmlFor={props.name} description={props.title} className="pt-2" />
            </div>
            <div className="w-100">
                {/* TODO - umbau auf SelectField / MultiSelectField */}
                <Select
                    name={props.name}
                    options={props.selectOptions}
                    className="inset-shadow"
                    isClearable={props.isClearable}
                    isMulti={props.isMulti}
                    placeholder="Bitte wählen"
                    defaultValue={props.defaultValue}
                    onChange={props.onChange}
                />
            </div>
        </div>
    );
};

const CheckboxFieldType = (props: CheckboxFieldTypeProps): React.JSX.Element => {
    const [selectedOptions, setSelectedOptions] = useState<SelectOption<LocationFactor>[]>(props.selectedOptions);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const selectOption: SelectOption<LocationFactor> | undefined = props.selectOptions.find(selectOption => selectOption.value === Number(event.target.value));

        if (selectOption === undefined) {
            return;
        }

        let updatedSelectedOptions: SelectOption<LocationFactor>[] = selectedOptions;

        if (event.target.checked === true) {
            if (selectedOptions.includes(selectOption) === false) {
                updatedSelectedOptions = [...updatedSelectedOptions, selectOption];
            }
        } else {
            updatedSelectedOptions = selectedOptions.filter((selectOption: SelectOption<LocationFactor>): boolean => selectOption.value !== Number(event.target.value));
        }

        if (props.onChange !== undefined) {
            props.onChange(updatedSelectedOptions);
        }

        setSelectedOptions(updatedSelectedOptions);
    };

    return (
        <div style={{gridRowEnd: 'span 20'}} className="row">
            <div className={`${props.hasErrors === true ? ' form-control is-invalid' : ''}`}>
                <div className="mb-2">
                    {props.label}
                    {props.required &&
                        <sup>*</sup>
                    }
                    <Tooltip
                        title={props.title}
                        icon={<i className="bi bi-info-circle"></i>}
                        className="text-info ms-1"
                    />
                </div>
                <div className="text-muted mb-3">Bitte wähle mindestens 2 und maximal 5 relevante Faktoren.</div>
            </div>
            {props.selectOptions.map((selectOption: SelectOption<LocationFactor>, index: number): React.JSX.Element => (
                <div key={selectOption.value} className="col-12 col-xl-6 ps-3 form-check">
                    <Checkbox
                        selectOption={selectOption}
                        onChange={onChange}
                        checked={selectedOptions.some(option => option.value === selectOption.value)}
                    />
                </div>
            ))}
        </div>
    );
};

const Checkbox = (props: CheckboxProps): React.JSX.Element => {
    return (
        <>
            <input
                type="checkbox"
                value={props.selectOption.value}
                id={'locationFactor-' + props.selectOption.value}
                className="form-check-input m-2 p-2"
                onChange={props.onChange}
                checked={props.checked}
            />
            <label htmlFor={'locationFactor-' + props.selectOption.value} className="form-check-label ms-2 mt-1">{props.selectOption.label}</label>
        </>
    );
};

export default LocationFactorSelectField;
export {FieldType};
