import BrandService from '../../Brand/BrandService';
import MatchingResult from '../../Entity/Matching/MatchingResult';
import MatchingResultGroup from '../../Entity/Matching/MatchingResultGroup';
import IndustryClassification from '../../Entity/IndustryClassification/IndustryClassification';
import {getBranchingDegreeLabel} from '../../Entity/BranchingDegree';
import Property from '../../Entity/Property/Property';
import SettlementConceptActivationContingent from '../../Entity/SettlementConcept/SettlementConceptActivationContingent';
import ImageEntity from '../../Entity/Image/Image';
import MatchingResultDetail from './MatchingResultDetail';
import CompanyValueIconList from '../../CompanyValueIconList';
import MatchingResultScore from './MatchingResultScore';
import Image from '../../Image/Image';
import PopupSpaceInformation from '../../SettlementConcept/PopupSpaceInformation';
import MixedUseSpaceInformation from '../../SettlementConcept/MixedUseSpaceInformation';
import Overlay from '../../Component/Overlay/Overlay';
import Card from '../../Component/Card/Card';
import Tooltip from '../../Component/Tooltip/Tooltip';
import clover from '../../../../img/logo-bildmarke.svg';
import matchingResultGroupCard from './MatchingResultGroupCard.module.scss';
import React, {useState} from 'react';

interface MatchingResultGroupCardProps {
    readonly matchingResultGroup: MatchingResultGroup;
    readonly property: Property;
    readonly settlementConceptActivationContingent: SettlementConceptActivationContingent;
    readonly openSingleActivationUnlockMatchingResultGroupsOverlay: (matchingResultGroup: MatchingResultGroup) => void;
    readonly onUnlockMatchingResultsOverlayClose: () => void;
}

const MatchingResultGroupCard = (props: MatchingResultGroupCardProps): React.JSX.Element => {
    const [showMatchingResultDetailOverlay , setShowMatchingResultDetailOverlay] = useState<boolean>(false);

    const [isDetailExpanded, setIsDetailExpanded] = useState<boolean>(false);

    const [matchingResultForDetailOverlay, setMatchingResultForDetailOverlay] = useState<MatchingResult>();

    const openMatchingResultDetailOverlay = (matchingResult: MatchingResult): void => {
        setShowMatchingResultDetailOverlay(true);

        setMatchingResultForDetailOverlay(matchingResult);
    };

    const buildBrandLogoGetPath = (image: ImageEntity): string => {
        return BrandService.buildImageGetPath(props.matchingResultGroup.matchingResults[0].settlementConcept.brand);
    };

    return (
        <div className={'bg-light mb-3 ms-0 me-0 ' + matchingResultGroupCard.borderStyle}>
            <Card cardType="outline" className="m-0 mb-0 p-3 bg-white position-relative">
                {props.matchingResultGroup.locked === false &&
                    <div className="d-md-none position-absolute top-15 start-100 translate-middle badge bg-primary">
                        <i className={'bi bi-unlock-fill text-white fs-5 ' + matchingResultGroupCard.matchLockSign}></i>
                    </div>
                }
                {props.matchingResultGroup.locked === true &&
                    <div className="d-md-none position-absolute top-15 start-100 translate-middle badge bg-secondary">
                        <i className={'bi bi-lock-fill text-white fs-5 ' + matchingResultGroupCard.matchLockSign}></i>
                    </div>
                }
                <div className="row">
                    <div className="d-none d-md-block col-2 col-lg-1">
                        {props.matchingResultGroup.locked === false &&
                            <i className={'bi bi-unlock-fill text-primary ' + matchingResultGroupCard.matchLockSign}></i>
                        }
                        {props.matchingResultGroup.locked === true &&
                            <i className={'bi bi-lock-fill text-secondary ' + matchingResultGroupCard.matchLockSign}></i>
                        }
                    </div>
                    <div className="col-12 col-md-10 col-lg-7 col-xl-8">
                        <div className="pe-2 mb-2">
                            <div className="row mb-2">
                                <div className="col-12 col-lg-9">
                                    {props.matchingResultGroup.locked === false &&
                                        <>
                                            <div className="fs-3 mb-3">
                                                <strong>{props.matchingResultGroup.matchingResults[0].settlementConcept.title}</strong>
                                            </div>
                                            <div className="fs-5 mb-3">
                                                <strong>{props.matchingResultGroup.matchingResults[0].settlementConcept.brand.brandName}</strong>
                                            </div>
                                            {props.matchingResultGroup.matchingResults[0].settlementConcept.brand.logo !== null &&
                                                <div className="row mb-3">
                                                    <div className="col-9 col-md-6">
                                                        <Image
                                                            image={props.matchingResultGroup.matchingResults[0].settlementConcept.brand.logo}
                                                            buildImageGetPath={buildBrandLogoGetPath}
                                                            alt={'Markenlogo ' + props.matchingResultGroup.matchingResults[0].settlementConcept.brand.brandName}
                                                            className="d-xl-none img-fluid"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                    <div>
                                        {props.matchingResultGroup.matchingResults.length === 1 &&
                                            <>Suchprofil aus dem Bereich</>
                                        }
                                        {props.matchingResultGroup.matchingResults.length > 1 &&
                                            <>Suchprofilgruppe aus den Bereichen</>
                                        }

                                        {props.matchingResultGroup.matchingResults[0].settlementConcept.industryClassifications.map((industryClassification: IndustryClassification, index: number): React.JSX.Element => (
                                            <span key={'industryClassification-' + index}>
                                                {index > 0 &&
                                                    <span className="ms-1">&middot; </span>
                                                }
                                                <i>
                                                    <span className="fw-semibold ms-1 me-1">{industryClassification.name}</span>
                                                </i>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-3 d-none d-xl-block">
                                    {props.matchingResultGroup.matchingResults[0].settlementConcept.brand.logo !== null &&
                                        <Image
                                            image={props.matchingResultGroup.matchingResults[0].settlementConcept.brand.logo}
                                            buildImageGetPath={buildBrandLogoGetPath}
                                            alt={'Markenlogo ' + props.matchingResultGroup.matchingResults[0].settlementConcept.brand.brandName}
                                            className="img-fluid"
                                        />
                                    }
                                </div>
                            </div>
                            <div className="mb-2">
                                <span className="border-1 p-1 bg-light text-nowrap me-1">
                                    {getBranchingDegreeLabel(props.matchingResultGroup.matchingResults[0].settlementConcept.brand.branchingDegree)}
                                </span>
                            </div>
                            {props.matchingResultGroup.matchingResults[0].settlementConcept.brand.companyValues.length > 0 &&
                                <div className="mb-2">
                                    <CompanyValueIconList companyValues={props.matchingResultGroup.matchingResults[0].settlementConcept.brand.companyValues} />
                                </div>
                            }
                            <div className="mb-3">
                                <span className="me-2">Flächenbedarf:</span>
                                <span className="me-2">von {new Intl.NumberFormat('de-DE').format(props.matchingResultGroup.matchingResults[0].settlementConcept.propertyCriteria.areaSizeMinimum)} m²</span>
                                <span className="me-2">bis {new Intl.NumberFormat('de-DE').format(props.matchingResultGroup.matchingResults[0].settlementConcept.propertyCriteria.areaSizeMaximum)} m²</span>
                            </div>

                            {(props.matchingResultGroup.matchingResults[0].settlementConcept.interestPopupSpace === true || props.matchingResultGroup.matchingResults[0].settlementConcept.interestMixedUseSpace === true) &&
                                <div className="row">
                                    {props.matchingResultGroup.matchingResults[0].settlementConcept.interestPopupSpace === true &&
                                        <div className="col-12 mb-3">
                                            <PopupSpaceInformation settlementConcept={props.matchingResultGroup.matchingResults[0].settlementConcept} fontSize="small" />
                                        </div>
                                    }
                                    {(props.matchingResultGroup.matchingResults[0].settlementConcept.interestMixedUseSpace === true) &&
                                        <div className="col-12 mb-3">
                                            <MixedUseSpaceInformation settlementConcept={props.matchingResultGroup.matchingResults[0].settlementConcept} fontSize="small" />
                                        </div>
                                    }
                                </div>
                            }

                            {(props.matchingResultGroup.matchingResults[0].targetedCitySearchInPropertyCity === true && props.property.locationDetail.city !== undefined) &&
                                <div className="mb-3">
                                    <i className="bi bi-bullseye fs-4 me-1"></i> sucht explizit in {props.property.locationDetail.city.placeName}
                                </div>
                            }

                            <div className="d-lg-none w-sm-100 w-50 mb-3">
                                <MatchingResultScore matchingResult={props.matchingResultGroup.matchingResults[0]} />
                            </div>

                            {props.matchingResultGroup.locked === true &&
                                <div className="mb-3 text-primary">
                                    {props.matchingResultGroup.matchingResults.length > 1 ? <>Diese Suchprofilgruppe freischalten für:</> : <>Dieses Suchprofil freischalten für:</>}
                                </div>
                            }

                            {props.matchingResultGroup.locked === true &&
                                <button className="btn btn-outline-primary text-dark d-flex ps-2" onClick={() => props.openSingleActivationUnlockMatchingResultGroupsOverlay(props.matchingResultGroup)}>
                                    <img src={clover} className="match-point-icon me-2 ps-0" alt="MatchPoints Icon" />
                                    <small className="text-nowrap pt-1">{props.settlementConceptActivationContingent.matchPointCost} MatchPoints</small>
                                </button>
                            }
                            {props.matchingResultGroup.locked === false &&
                                <button className="btn btn-outline-secondary" onClick={() => openMatchingResultDetailOverlay(props.matchingResultGroup.matchingResults[0])}>
                                    Details zeigen
                                </button>
                            }
                        </div>

                        {props.matchingResultGroup.matchingResults.length > 1 &&
                            <button
                                type="button"
                                className="btn btn-default border-0 text-secondary fs-6 m-0 p-0 text-start text-md-center lh-lg text-normal"
                                onClick={() => setIsDetailExpanded((prevState:  boolean): boolean => prevState === false)}
                            >
                                <i className={`bi ${isDetailExpanded === true ? 'bi-chevron-down' : 'bi-chevron-right'}` + ' pe-2 pe-sm-2 pe-md-4'}></i>
                                <span>
                                    {props.matchingResultGroup.matchingResults.length - 1}
                                    {props.matchingResultGroup.matchingResults.length - 1 === 1 ? <> weiteres Suchprofil desselben Anbieters</> : <> weitere Suchprofile desselben Anbieters</>}
                                </span>
                            </button>
                        }
                    </div>
                    <div className="d-none d-lg-block col-lg-4 col-xl-3">
                        <div className="ms-auto">
                            <div className="clearfix"></div>
                            <MatchingResultScore matchingResult={props.matchingResultGroup.matchingResults[0]} />
                        </div>
                    </div>
                </div>
            </Card>
            {isDetailExpanded === true &&
                <div className="p-4">
                    {props.matchingResultGroup.matchingResults.slice(1).map((matchingResult: MatchingResult, index: number): React.JSX.Element => (
                        <div key={'matchingResult-' + index}>
                            <div className="row">
                                <div className="col-12 col-sm-4 col-xxl-4">
                                    {props.matchingResultGroup.locked === true &&
                                        <strong>{index + 2}. Suchprofil</strong>
                                    }
                                    {props.matchingResultGroup.locked === false &&
                                        <strong>{matchingResult.settlementConcept.title}</strong>
                                    }
                                </div>
                                <div className="col-12 col-sm-4 col-xxl-6">
                                    <span className="me-2">Flächenbedarf:</span>
                                    <span className="me-2">von {new Intl.NumberFormat('de-DE').format(matchingResult.settlementConcept.propertyCriteria.areaSizeMinimum)} m²</span>
                                    <span className="me-2">bis {new Intl.NumberFormat('de-DE').format(matchingResult.settlementConcept.propertyCriteria.areaSizeMaximum)} m²</span>
                                </div>
                                {props.matchingResultGroup.locked === false &&
                                    <div className="col-12 col-sm-4 col-xxl-2">
                                        <div className="d-flex">
                                            {(matchingResult.targetedCitySearchInPropertyCity === true && props.property.locationDetail.city !== undefined) &&
                                                <Tooltip className="align-items-center" title={'sucht explizit in ' + props.property.locationDetail.city.placeName}>
                                                    <i className="bi bi-bullseye fs-4 me-3"></i>
                                                </Tooltip>
                                            }
                                            <button className="btn btn-outline-secondary ps-2 pe-2 pt-0 pb-0" onClick={() => openMatchingResultDetailOverlay(matchingResult)}>
                                                Details
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                            {index < props.matchingResultGroup.matchingResults.length - 2 &&
                                <hr className="bg-dark mt-3 mb-3" />
                            }
                        </div>
                    ))}
                </div>
            }
            {matchingResultForDetailOverlay !== undefined &&
                <Overlay
                    show={showMatchingResultDetailOverlay}
                    setShow={setShowMatchingResultDetailOverlay}
                    title="Suchprofil-Details"
                >
                    <div className="container">
                        <MatchingResultDetail matchingResult={matchingResultForDetailOverlay} property={props.property} />
                    </div>
                </Overlay>
            }
        </div>
    );
};

export default MatchingResultGroupCard;
