import WelcomeInfoBox from './WelcomeInfoBox';
import LogoSlogan from '../../../img/logo_slogan.png';
import LogoLeerstandslotsenWeiss from '../../../img/logo_leerstandslotsen_weiss.svg';
import '../../../public_page.scss';
import React, {useEffect} from 'react';
import { Outlet } from 'react-router-dom';

interface PublicPageProps {
    readonly extended?: boolean;
    readonly infobox?: boolean;
}

const PublicPageLayout = (props: PublicPageProps): React.JSX.Element => {
    useEffect((): () => void => {
        document.body.classList.add('public-background');

        return (): void => {
            document.body.classList.remove('public-background');
        };
    });

    return (
        <div className="container-fluid">
            {!props.extended &&
                <img src={LogoLeerstandslotsenWeiss} alt='Leerstandslotsen' className='llasm-logo col-lg-4 d-none d-lg-block' />
            }
            {props.infobox &&
                <WelcomeInfoBox absolutePosition={true} />
            }
            <div className="row justify-content-end vh-100">
                <div className={props.extended ? 'bg-white bg-opacity-85 box-shadow mw-460 col-xs-10 col-sm-10 col-md-8 col-lg-8 col-xl-8' : 'bg-white bg-opacity-85 box-shadow mw-460 col-xs-10 col-sm-10 col-md-6 col-lg-4 col-xl-3'}>
                    <div className="mh-80vh">
                        <div className="row g-3 mb-4">
                            <div className="col-12 pt-1">
                                <img src={LogoSlogan} className={props.extended ? 'd-none' : 'd-block d-lg-none col-8'} alt="Leerstandslotsen-Logo" />
                                <Outlet />
                            </div>
                        </div>
                    </div>
                    <div className="mh-20vh position-relative">
                        <div className="row mt-2 justify-content-center position-absolute w-100 bottom-0">
                            <div className={props.extended ? 'col-10 col-md-8 col-lg-6 col-xl-5' : 'col-12'}>
                                <img src={LogoSlogan} className={props.extended ? 'w-100 d-block' : 'w-100 d-block d-none'} alt="Leerstandslotsen-Logo" />
                                <nav className="navbar navbar-expand-lg navbar-light navigation">
                                    <div className="d-flex align-items-center text-muted justify-content-between w-100 ps-3">
                                        <a className="text-black-50 d-inline-block" href="/impressum">
                                            Impressum
                                        </a>
                                        <a className="text-black-50 d-inline-block" href="/agb">
                                            AGB
                                        </a>
                                        <a className="text-black-50 d-inline-block" href="/datenschutz">
                                            Datenschutz
                                        </a>
                                        <a className="text-black-50 d-inline-block" href="https://www.llasm.de">
                                            LLASM.de
                                        </a>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-1"></div>
            </div>
        </div>
    );
};

export default PublicPageLayout;
