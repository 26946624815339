import SettlementConceptService from './SettlementConceptService';
import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import Document from '../Entity/Document/Document';
import Disclaimer from '../Disclaimer/Disclaimer';
import BaseInformationCollapseCard from './BaseInformationCollapseCard';
import BrandCollapseCard from './BrandCollapseCard';
import PropertyRequirementCollapseCard from './PropertyRequirementCollapseCard';
import LocationRequirementCollapseCard from './LocationRequirementCollapseCard';
import ContactCollapseCard from './ContactCollapseCard';
import ImageCollapseCard from './ImageCollapseCard';
import DocumentCollapseCard from '../Document/DocumentCollapseCard';
import React from 'react';

interface SettlementConceptDetailInformationProps {
    readonly settlementConcept: SettlementConcept;
}

const SettlementConceptDetailInformation = (props: SettlementConceptDetailInformationProps): React.JSX.Element => {
    const buildDocumentGetPath = (document: Document): string => {
        if (props.settlementConcept === undefined) {
            throw new Error();
        }

        return SettlementConceptService.buildDocumentApiPath(props.settlementConcept, document);
    };

    return (
        <>
            <BaseInformationCollapseCard settlementConcept={props.settlementConcept} />
            <BrandCollapseCard settlementConcept={props.settlementConcept} />
            <PropertyRequirementCollapseCard settlementConcept={props.settlementConcept} />
            <LocationRequirementCollapseCard settlementConcept={props.settlementConcept} />
            <ContactCollapseCard settlementConcept={props.settlementConcept} />

            {props.settlementConcept.images.length > 0 &&
                <ImageCollapseCard settlementConcept={props.settlementConcept} />
            }
            {props.settlementConcept.documents.length > 0 &&
                <DocumentCollapseCard documents={props.settlementConcept.documents} buildDocumentGetPath={buildDocumentGetPath} />
            }

            <Disclaimer title="Rechtliche Hinweise und Haftungsausschluss" className="mt-4 pb-5" />
        </>
    );
};

export default SettlementConceptDetailInformation;
