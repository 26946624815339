import BrandService from './BrandService';
import Brand from '../Entity/Brand/Brand';
import Alert from '../Entity/Alert/Alert';
import AlertType from '../Entity/Alert/AlertType';
import FormData from '../Entity/Form/FormData';
import Image from '../Entity/Image/Image';
import AuthenticationState from '../Entity/Authentication/AuthenticationState';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import BrandForm from './BrandForm';
import AlertBox from '../../../components/AlertBox';
import Spinner from '../../../components/Spinner';
import {useAppSelector} from '../../../app/hooks';
import React, {useEffect, useRef, useState} from 'react';
import {AxiosError} from 'axios';

interface BrandEditProps {
    readonly brandId: number;
    readonly onBrandFetched?: (brand: Brand) => void;
    readonly onBrandUpdated?: (brand: Brand) => void;
    readonly onCloseButtonClick?: () => void;
}

const successAlert: Alert = new Alert(AlertType.Success, 'Die Marke wurde erfolgreich aktualisiert.');

const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.');

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Du hast nicht alle Pflichtfelder gefüllt. Bitte kontrolliere die mit einem roten Ausrufezeichen markierten Felder.');

const brandService: BrandService = new BrandService(process.env.REACT_APP_LLASM_API_URL!);

const fieldValidationDefinitions: FieldValidationDefinition<Brand>[] = [
    new RequiredValidationDefinition<Brand>('brandName', 'Markenname muss ausgefüllt sein.'),
    new RequiredValidationDefinition<Brand>('ageStructures', 'Angesprochene Altersgruppe muss ausgefüllt sein.'),
    new RequiredValidationDefinition<Brand>('targetGroups', 'Angesprochene Zielgruppe muss ausgefüllt sein.'),
    new RequiredValidationDefinition<Brand>('priceSegments', 'Preissegmente muss ausgefüllt sein.'),
    /* TODO: to be added later, requires a process for approval by an administrator
    new RequiredValidationDefinition<Brand>('companyValues', 'Unternehmenswerte muss ausgefüllt sein.'),
     */
    new RequiredValidationDefinition<Brand>('branchingDegree', 'Filialisierungsgrad muss ausgefüllt sein.'),
];

const formValidationHandler: FormValidationHandler<Brand> = new FormValidationHandler<Brand>(fieldValidationDefinitions);

const BrandEdit = (props: BrandEditProps): React.JSX.Element => {
    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [brand, setBrand] = useState<Brand>();
    // TODO
    const [formData, setFormData] = useState<FormData<Brand>>({data: brand!});

    const [amountLogosInUploadQueue, setAmountLogosInUploadQueue] = useState<number>(0);

    const [logo, setLogo] = useState<Image | null>(null);

    const [isProcessingLogo, setIsProcessingLogo] = useState<boolean>(false);

    const [alert, setAlert] = useState<Alert>();

    const logoUploaderRef: React.Ref<any> = useRef<React.ForwardedRef<any>>();

    useEffect((): void => {
        fetchBrand();
    }, []);

    const fetchBrand = async (): Promise<void> => {
        try {
            setIsLoading(true);

            const brand: Brand = await brandService.fetchBrandFromApiById(props.brandId);

            setBrand(brand);
            setLogo(brand.logo);

            setFormData({...formData, 'data': brand});

            if (props.onBrandFetched !== undefined) {
                props.onBrandFetched(brand);
            }
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    const fetchLogo = async (): Promise<void> => {
        if (brand === undefined) {
            return;
        }

        setIsProcessingLogo(true);

        try {
            brand.logo = await brandService.fetchLogoFromBrand(brand);
        } catch (error) {
            if ((error as AxiosError).response!.status !== 404) {
                throw new Error();
            }

            if ((error as AxiosError).response!.status === 404) {
                brand.logo = null;
            }
        }

        setIsProcessingLogo(false);

        setLogo(brand.logo);
    };

    const buildImageGetPath = (image: Image): string => {
        if (brand === undefined) {
            throw new Error();
        }

        return BrandService.buildImageGetPath(brand);
    };

    const buildImageDeletePath = (image: Image): string => {
        if (brand === undefined) {
            throw new Error();
        }

        return BrandService.buildImageDeletePath(brand, image);
    };

    const updateBrand = async (): Promise<void> => {
        formValidationHandler.validate(formData);

        if (formValidationHandler.hasErrors(formData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        setAlert(undefined);

        setIsLoading(true);

        try {
            await brandService.updateBrand(formData.data);

            setAlert(successAlert);

            const brand: Brand = await brandService.fetchBrandFromApiById(formData.data.id!);

            setBrand(brand);
            setLogo(brand.logo);

            setFormData({...formData, 'data': brand});

            if (props.onBrandUpdated !== undefined) {
                props.onBrandUpdated(brand);
            }
        } catch (error) {
            setAlert(errorAlert);

            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    const onAddedFile = (): void => {
        setAmountLogosInUploadQueue((prevState:  number): number => prevState + 1);
    };

    const onRemovedFile = (): void => {
        setAmountLogosInUploadQueue((prevState:  number): number => prevState - 1);
    };

    if (isLoading === true) {
        return <Spinner />;
    }

    return (
        <>
            {alert !== undefined &&
                <AlertBox alert={alert} autoDismiss={false} />
            }

            <BrandForm
                formData={formData}
                setFormData={setFormData}
                logoUploaderRef={logoUploaderRef}
                token={authenticatedUser!.token}
                autoProcessQueue={true}
                maxFileSize={2}
                logo={logo}
                onAddedFile={onAddedFile}
                onRemovedFile={onRemovedFile}
                onQueueComplete={fetchLogo}
                isProcessingLogo={isProcessingLogo}
                setIsProcessingLogo={setIsProcessingLogo}
                uploadUrl={process.env.REACT_APP_LLASM_API_URL! + '/v1/brands' + '/' + brand!.id + '/logo'}
                buildLogoGetPath={buildImageGetPath}
                buildLogoDeletePath={buildImageDeletePath}
                onLogoDeleted={fetchLogo}
                formValidationHandler={formValidationHandler}
            />
            <button type="submit" className="btn btn-primary d-flex align-items-center mt-3" onClick={updateBrand}>Speichern</button>
            {props.onCloseButtonClick !== undefined &&
                <button className="btn align-items-center mt-3" onClick={props.onCloseButtonClick}>
                    SCHLIESSEN
                </button>
            }
        </>
    );
};

export default BrandEdit;
