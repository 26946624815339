import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import {getPropertyAcquisitionTypeLabel} from '../Entity/PropertyAcquisitionType';
import {getParkingLotRequirementTypeLabel} from '../Entity/ParkingLotRequirementType';
import PopupSpaceInformation from './PopupSpaceInformation';
import MixedUseSpaceInformation from './MixedUseSpaceInformation';
import CollapseCard from '../Component/CollapseCard/CollapseCard';
import LabelValuesPair from '../../../components/LabelValuesPair';
import LabelValuePair from '../../../components/LabelValuePair';
import React from 'react';

interface PropertyRequirementCollapseCardProps {
    readonly settlementConcept: SettlementConcept;
}

const PropertyRequirementCollapseCard = (props: PropertyRequirementCollapseCardProps): React.JSX.Element => {
    const propertyAcquisitionTypeValues: string[] = props.settlementConcept.propertyCriteria.propertyAcquisitionTypes.map(
        propertyAcquisitionType => getPropertyAcquisitionTypeLabel(propertyAcquisitionType)
    );

    const parkingLotRequirementValue: string = getParkingLotRequirementTypeLabel(props.settlementConcept.propertyCriteria.parkingLotRequirementType);

    return (
        <CollapseCard cardType="shadow" title="Expansionsanforderungen/Flächenanforderungen" className="mb-3">
            <div className="row">
                {propertyAcquisitionTypeValues.length > 0 &&
                    <div className="col-12 col-md-4 mb-3">
                        <LabelValuesPair label="Angebotsart(en)" values={propertyAcquisitionTypeValues} delimiter=", " />
                    </div>
                }
                {(props.settlementConcept.desiredMinimumLeaseTerm !== null && props.settlementConcept.desiredMinimumLeaseTerm !== 0) &&
                    <div className="col-12 col-md-8 mb-3">
                        <LabelValuePair label="gewünschte Mietvertragslaufzeit" value={props.settlementConcept.desiredMinimumLeaseTerm + ' Monate'} />
                    </div>
                }
                <div className="col-12 col-md-4 mb-3">
                    <LabelValuePair label="Gesamtfläche von" value={new Intl.NumberFormat('de-DE').format(props.settlementConcept.propertyCriteria.areaSizeMinimum) + ' m²'} />
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <LabelValuePair label="Gesamtfläche bis" value={new Intl.NumberFormat('de-DE').format(props.settlementConcept.propertyCriteria.areaSizeMaximum) + ' m²'} />
                </div>
                {(props.settlementConcept.propertyCriteria.secondaryAreaSize !== null && props.settlementConcept.propertyCriteria.secondaryAreaSize !== 0) &&
                    <div className="col-12 col-md-4 mb-3">
                        <LabelValuePair label="Nebenfläche" value={'ca. ' + new Intl.NumberFormat('de-DE').format(props.settlementConcept.propertyCriteria.secondaryAreaSize) + ' m²'} />
                    </div>
                }
                {props.settlementConcept.propertyCriteria.outdoorSalesAreaRequired === true &&
                    <div className="col-12 col-md-4 mb-3">
                        {(props.settlementConcept.propertyCriteria.outdoorSalesAreaSize !== null && props.settlementConcept.propertyCriteria.outdoorSalesAreaSize !== 0) &&
                            <LabelValuePair label="Außenverkaufsfläche" value={'ca. ' + new Intl.NumberFormat('de-DE').format(props.settlementConcept.propertyCriteria.outdoorSalesAreaSize) + ' m²'} />
                        }
                        {props.settlementConcept.propertyCriteria.outdoorSalesAreaSize === null &&
                            <LabelValuePair label="Außenverkaufsfläche" value="wird benötigt" />
                        }
                    </div>
                }
                {(props.settlementConcept.propertyCriteria.storeWidth !== null && props.settlementConcept.propertyCriteria.storeWidth !== 0) &&
                    <div className="col-12 col-md-4 mb-3">
                        <LabelValuePair label="Ladenbreite" value={'ca. ' + props.settlementConcept.propertyCriteria.storeWidth + ' m'} />
                    </div>
                }
                {(props.settlementConcept.propertyCriteria.shopWindowFrontLength !== null && props.settlementConcept.propertyCriteria.shopWindowFrontLength !== 0) &&
                    <div className="col-12 col-md-4 mb-3">
                        <LabelValuePair label="Schaufensterbreite" value={'ca. ' + props.settlementConcept.propertyCriteria.shopWindowFrontLength + ' m'} />
                    </div>
                }
                <div className="col-12 col-md-4 mb-3">
                    <LabelValuePair label="Parkplätze am Objekt" value={parkingLotRequirementValue} />
                </div>
            </div>
            <div className="row">
                {props.settlementConcept.propertyCriteria.groundLevelSalesAreaRequired === true &&
                    <div className="col-12 col-md-6 mb-3">
                        <LabelValuePair label="ebenerdige Verkaufsfläche" value="wird benötigt" />
                    </div>
                }
                {props.settlementConcept.propertyCriteria.groundLevelSalesAreaRequired === true &&
                    <div className="col-12 col-md-6 mb-3">
                        <LabelValuePair label="Barrierefreier Zugang" value="wird benötigt" />
                    </div>
                }
                {(props.settlementConcept.propertyCriteria.otherRequirements !== null && props.settlementConcept.propertyCriteria.otherRequirements !== '') &&
                    <div className="col-12 mb-3">
                        <LabelValuePair label="Sonstige Anforderungen" value={props.settlementConcept.propertyCriteria.otherRequirements} />
                    </div>
                }
            </div>
            {(props.settlementConcept.interestPopupSpace === true || props.settlementConcept.interestMixedUseSpace === true) &&
                <div className="row">
                    {props.settlementConcept.interestPopupSpace === true &&
                        <div className="col-12 mb-3">
                            <PopupSpaceInformation settlementConcept={props.settlementConcept} fontSize="big" />
                        </div>
                    }
                    {props.settlementConcept.interestMixedUseSpace === true &&
                        <div className="col-12 mb-3">
                            <MixedUseSpaceInformation settlementConcept={props.settlementConcept} fontSize="big" />
                        </div>
                    }
                </div>
            }
        </CollapseCard>
    );
};

export default PropertyRequirementCollapseCard;
