import ConversationService from '../ConversationService';
import MessageWrapper from '../../Entity/Messaging/MessageWrapper';
import MessageRead from '../../Entity/Messaging/MessageRead';
import ProviderSeekerMessageRead from '../../Entity/Messaging/ProviderSeekerMessageRead';
import Conversation from '../../Entity/Messaging/Conversation';
import AuthenticationState from '../../Entity/Authentication/AuthenticationState';
import Card from '../../Component/Card/Card';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import {fetchUser} from '../../../../features/user/userSlice';
import {useAppSelector} from '../../../../app/hooks';
import messageStyle from '../Message.module.scss';
import messagingStyle from '../Messaging.module.scss';
import {Dispatch} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import React, {Fragment, useEffect, useRef} from 'react';

interface StandardMessageProps {
    readonly conversation: Conversation;
    readonly messageWrapper: MessageWrapper;
    readonly updateMessageList?: () => void;
    readonly deleteMessage?: (messageWrapper: MessageWrapper) => void;
    readonly updateConversationList?: () => void;
}

const conversationService: ConversationService = new ConversationService(process.env.REACT_APP_LLASM_API_URL!);

const StandardMessage = (props: StandardMessageProps): React.JSX.Element => {
    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const dispatch: Dispatch<any> = useDispatch();

    const messageRef: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

    let timer: NodeJS.Timeout | number | undefined = undefined;

    useEffect((): void => {
        if (messageRef.current === null || props.messageWrapper.message.isSender === true || props.messageWrapper.message.unread === false) {
            return;
        }

        const intersectionObserver: IntersectionObserver = new IntersectionObserver((intersectionObserverEntries: IntersectionObserverEntry[]): void => {
            const intersectionObserverEntry: IntersectionObserverEntry = intersectionObserverEntries[0];

            clearTimeout(timer);

            if (intersectionObserverEntry.isIntersecting === true) {
                timer = setTimeout(async (): Promise<void> => {
                    await markAsRead();

                    if (authenticatedUser !== undefined && authenticatedUser !== null) {
                        dispatch(fetchUser(authenticatedUser));
                    }
                }, 2000);
            }
        });

        intersectionObserver.observe(messageRef.current!);
    }, [messageRef]);

    const markAsRead = async (): Promise<void> => {
        if (props.messageWrapper.message.unread === false) {
            return;
        }

        try {
            const messageRead: MessageRead = await conversationService.persistMessageRead(
                props.conversation,
                props.messageWrapper.message,
                new ProviderSeekerMessageRead()
            );

            props.messageWrapper.message.unread = false;

            if (props.updateMessageList !== undefined) {
                props.updateMessageList();
            }

            if (props.updateConversationList !== undefined) {
                props.updateConversationList();
            }
        } catch (error) {
            // TODO throw error
        }
    };

    const deleteMessage = (): void => {
        if (props.deleteMessage !== undefined) {
            props.deleteMessage(props.messageWrapper);
        }
    };

    if (typeof props.messageWrapper.message.text !== 'string') {
        throw new Error();
    }

    return (
        <Card cardType="shadow" className={`mb-3 p-3 ${(props.messageWrapper.message.unread === true) ? messageStyle.highlight : ''} ${(props.messageWrapper.message.isSender === true) ? messageStyle.senderBackground : messageStyle.recipientBackground} `}>
            {props.messageWrapper.isLoading === true ? (
                <div className={messagingStyle.loadingIndicatorContainer}>
                    <LoadingIndicator />
                </div>
            ) : (
                <div ref={messageRef}>
                    <div className="d-md-flex">
                        <span className={`mb-3 me-4 ${props.messageWrapper.message.isSender === true ? 'text-primary' : ''} ` + messageStyle.sender}>
                            {props.messageWrapper.message.isSender === false &&
                                <span>Von: </span>
                            }
                            {props.messageWrapper.message.senderUser.name}
                        </span>
                        <div className="clearfix d-md-none"></div>
                        <span className={['mb-3', 'ms-auto', messageStyle.date].join(' ')}>
                            {props.messageWrapper.message.createdAt.toLocaleDateString('de-DE', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}
                        </span>
                        <div className="mb-3 d-md-none"></div>
                    </div>
                    <div className={['mb-3', messageStyle.message].join(' ')}>
                        {typeof props.messageWrapper.message.text === 'string' &&
                            <>
                                {props.messageWrapper.message.text.split('\n').map((item: string, index: number) => {
                                    return <Fragment key={index}>{item}<br /></Fragment>
                                })}
                            </>
                        }
                    </div>
                    {props.messageWrapper.message.isSender === true &&
                        <div className="d-flex justify-content-end">
                            <span className={messageStyle.deleteButton} onClick={() => deleteMessage()}>
                                <i className="text-primary bi bi-trash"></i>
                            </span>
                        </div>
                    }
                </div>
            )}
        </Card>
    );
};

export default StandardMessage;
